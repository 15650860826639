import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { Grid, Row, Col, Typography, Space, Image } from 'antd'
import LoginLogo from '../../assets/img/login.png';
import RegisterLogo from '../../assets/img/register.png';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

const { Title, Text } = Typography

export default function Bridging(props) {
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(props.path, [], 'Register');
  }

  const { xs, md, lg } = GetBreakPoint()

  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - Login or Register</title>
      </Helmet>
      <Row style={{ paddingTop: 32, background: '#fff' }}>
        <Col className="full-width" xs={{ span: 24 }} md={{ span: 18, offset: 3 }}>
          <Row className="mb-32">
            <Col span={24}>
              <Space className="txt-algn-center full-width" direction="vertical" size={0}>
                <Title className="drk-color" level={2}>You’re on your way</Title>
                <Text className="fs-20 drk-color">Login or Register to continue access pages</Text>
              </Space>
            </Col>
          </Row>
          {
            xs ?
              <React.Fragment>
                <Link to="/login">
                  <Row className="bridge full-width txt-algn-center">
                    <Col className="txt-algn-center" span={24}>
                      <Image
                        src={LoginLogo}
                        preview={false}
                        height={300}
                        width={300}
                        style={{ objectFit: 'contain' }}
                      />
                    </Col>
                    <Col span={24} style={{ marginTop: -45 }}>
                      <Text className="fs-16 drk-color">Login</Text>
                    </Col>
                  </Row>
                </Link>
                <Link to="/register">
                  <Row className="bridge full-width txt-algn-center">
                    <Col className="txt-algn-center" span={24}>
                      <Image
                        src={RegisterLogo}
                        preview={false}
                        height={300}
                        width={300}
                        style={{ objectFit: 'contain', }}
                      />
                    </Col>
                    <Col span={24} style={{ marginTop: -45 }}>
                      <Text className="fs-16 drk-color">Register</Text>
                    </Col>
                  </Row>
                </Link>
              </React.Fragment>
            :
              <React.Fragment>
                <Row className="full-width txt-algn-center" gutter={md ? 32 : 8}>
                  <Col className="bridge txt-algn-center" span={12}>
                    <Link to="/login">
                      <Row>
                        <Col span={24}>
                          <Image
                            src={LoginLogo}
                            preview={false}
                            height={lg ? 400 : 300}
                            width={'100%'}
                            style={{ objectFit: 'contain' }}
                          />
                        </Col>
                        <Col className="txt-algn-center" span={24} style={{ marginTop: lg ? -60 : -40 }}>
                          <Text className="fs-16 drk-color">Login</Text>
                        </Col>
                      </Row>
                    </Link>
                  </Col>
                  <Col className="bridge txt-algn-center" span={12}>
                    <Link to="/register">
                      <Row>
                        <Col className="full-width" span={24}>
                          <Image
                            src={RegisterLogo}
                            preview={false}
                            height={lg ? 400 : 300}
                            width={'100%'}
                            style={{ objectFit: 'contain', }}
                          />
                        </Col>
                        <Col className="full-width" span={24} style={{ marginTop: lg ? -60 : -40 }}>
                          <Text className="fs-16 drk-color">Register</Text>
                        </Col>
                      </Row>
                    </Link>
                  </Col>
                </Row>
              </React.Fragment>
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}