import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Layout, Menu, Grid, Dropdown, Space, Badge, Avatar, Typography, Button, Drawer, Image } from 'antd';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';

import Notification from '../../Notification'
import { MenuOutlined, BellOutlined, UserOutlined, CaretDownFilled, PoweroffOutlined, SettingOutlined } from '@ant-design/icons';
import './Header.css';

const { useBreakpoint } = Grid;

const { Header } = Layout;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetHistory() {
  const history = useHistory()
  return history;
}

export const index = (initialProps) => {
  const {
    getHeader,
    getCountNotification: { data },
    onClickSider,
    showDrawer,
    showNotification,
    onCloseNotification,
    visibleNotification
  } = initialProps
  
  const { md } = GetBreakPoint()
  const history = GetHistory()
  const { pathname } = window.location

  const onClickLogout = () => {
    Cookie.remove('user')
    Cookie.remove('userRefreshTkn')
    window.location.reload()
  }

  const onClickSettings = () => {
    history.push('/candidate/accountSettings')
  }

  const menu = (
    <Menu className="pad-16" style={{ width: 300 }}>
      <Menu.Item className="not-menu" key="avatar">
        <Space className="full-width mb-8" key="avatar">
          {
            getHeader?.data?.profilePicUrl ?
              <Avatar src={getHeader?.data?.profilePicUrl} /> 
            :
              <Avatar style={{ backgroundColor: '#32ab6d' }} icon={<UserOutlined />} /> 
          }
          <Text className="drk-color" ellipsis={true} style={{ width: 200 }}>{getHeader?.data?.fullName}</Text>
        </Space>
      </Menu.Item>
      <Menu.Item className="not-menu" key="/profile">
        <Link className="txt-color" to="/candidate/profile">
          <Button type="primary" className="br-20 full-width mb-8" size="small" ghost>View Profile</Button>
        </Link>
      </Menu.Item>
      <Menu.Item className="txt-color" key="/account" onClick={onClickSettings} icon={<SettingOutlined />}>
        Account Settings
      </Menu.Item>
      <Menu.Item className="txt-color" key="/logout" onClick={onClickLogout} icon={<PoweroffOutlined />}>
          Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <React.Fragment>
      {
        pathname === '/job-preferences/industries' || pathname === '/job-preferences/position' || pathname === '/job-preferences/destination' ?
          null
        :
          md ?
            <Header>
              <Space size={24}>
                <Link to="#" onClick={onClickSider}>
                  <MenuOutlined className="fs-18" />
                </Link>
                <Link to="/">
                  <Image
                    width={140}
                    src={JobshubLogo}
                    preview={false}
                    style={{ paddingTop: 25 }}
                  />
                </Link>
              </Space>
              <Space size={16}>
                <Badge className="drk-color c-pointer" size="small" count={data} onClick={showNotification} style={{ fontSize: 10 }}>
                  <BellOutlined className="fs-20" />
                </Badge>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <Link to="#" className="ant-dropdown-link drk-color" onClick={e => e.preventDefault()}>
                    {
                      getHeader?.data?.profilePicUrl ?
                        <React.Fragment>
                          <Avatar src={getHeader?.data?.profilePicUrl} /> <CaretDownFilled />
                        </React.Fragment>
                      :
                        <React.Fragment>
                          <Avatar style={{ backgroundColor: '#32ab6d' }} icon={<UserOutlined />} /> <CaretDownFilled />
                        </React.Fragment>
                    }
                  </Link>
                </Dropdown>
              </Space>
            </Header>
          :
            <Header className="header-mobile">
              <Space className="pl-16">
                <Link to="#" onClick={ md ? onClickSider : showDrawer }>
                  <MenuOutlined className="fs-18" />
                </Link>
                <Link to="/">
                  <Image
                    width={140}
                    src={JobshubLogo}
                    preview={false}
                    style={{ paddingTop: 25 }}
                  />
                </Link>
              </Space>
              <Space className="pr-16" size={8}>
                <Badge className="drk-color c-pointer" size="small" count={data} onClick={showNotification} style={{ fontSize: 10 }}>
                  <BellOutlined className="fs-20" />
                </Badge>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <Link to="#" className="ant-dropdown-link drk-color" onClick={e => e.preventDefault()}>
                    {
                      getHeader?.data?.profilePicUrl ?
                        <React.Fragment>
                          <Avatar src={getHeader?.data?.profilePicUrl} /> <CaretDownFilled />
                        </React.Fragment>
                      :
                        <React.Fragment>
                          <Avatar style={{ backgroundColor: '#32ab6d' }} icon={<UserOutlined />} /> <CaretDownFilled />
                        </React.Fragment>
                    }
                  </Link>
                </Dropdown>
              </Space>
            </Header>

      }
      <Drawer
        title={<Text strong style={{ color: '#32ab6d' }}>Notification</Text>}
        placement="right"
        width={md ? 400 : '75%'}
        onClose={onCloseNotification}
        visible={visibleNotification}
      >
        <Notification {...initialProps} />
      </Drawer>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(index)