import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexTimezone = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_TIMEZONE'})
}

export const indexTimezone = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_TIMEZONE' })
  return API.GET('/time_zone/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_TIMEZONE_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_TIMEZONE_FAILED' }), 
    ))
  })
}