import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card, Typography, Row, Col, Space, List, Modal, Form, Input, DatePicker, Button, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { addAchievement, updateAchievement, deleteAchievement } from '../../../../redux/actions/profile/achievementAction';


const { Text } = Typography;

export class Achievement extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      deleteLoading: false,
      year: null, 
      id: null,
      dataEdit: null
    }
  }

  showModal = (id) => {
    const { data } = this.props
    const dataEdit = data.listAchievement.filter(item => item.id === id).pop()

    this.setState({
      visible: true,
      id: id,
      year: id ? dataEdit?.year : null,
      dataEdit: dataEdit
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  onChangeYear = (date, dateString) => {
    this.setState({
      year: dateString
    })
  }

  handleDelete = () => {
    const { actionDeleteAchievement, actionGetProfile } = this.props
    const { id } = this.state
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteAchievement(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { actionAddAchievement, actionUpdateAchievement, actionGetProfile } = this.props
    const { year, id } = this.state
    values.year = year
    values.id = id
    
    if(id){
      return this.setState({ submitLoading: true }, () => {
        return actionUpdateAchievement(values, response => {
          if(response.code === '1000'){
            return this.setState({ submitLoading: false, visible: false }, () => {
              message.success(response.message)
              return actionGetProfile()
            })
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
        })
      })
    }else{
      return this.setState({ submitLoading: true }, () => {
        return actionAddAchievement(values, response => {
          if(response.code === '1000'){
            return this.setState({ submitLoading: false, visible: false }, () => {
              message.success(response.message)
              return actionGetProfile()
            })
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
        })
      })
    }
  }

  render() {
    const { visible, submitLoading, deleteLoading, id, dataEdit } = this.state
    const { data: { listAchievement } } = this.props
    
    return (
      <React.Fragment>
        <Card
          className="br-6 box-shadow" 
          title="Achievements"
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={listAchievement.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
          actions={[
            <Text className="primary-color" onClick={() => this.showModal(null)}>Add achievement</Text>
          ]}
        >
          {
            listAchievement.length > 0 ?
              <List
                dataSource={listAchievement}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <Row className="full-width">
                      <Col span={20}>
                        <Space direction="vertical" size={-4}>
                          <Text className="drk-color">{item.name}</Text>
                          <Text className="txt-color fs-11">{item.organization}</Text>
                          <Text className="txt-color fs-11">{item.year}</Text>
                        </Space>
                      </Col>
                      <Col span={4}>
                        <Text className="primary-color f-right c-pointer" onClick={() => this.showModal(item.id)}><EditFilled /> Edit</Text>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            :
              <Text className="txt-color">Show off your achievements here</Text>
          }
        </Card>

        <Modal 
          title="Achievement" 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              name: id ? dataEdit?.name : null,
              organization: id ? dataEdit?.organization : null,
              year: id && dataEdit?.year ? moment(dataEdit?.year) : null
            }}
          >
            <Row className="mb-min-8">
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Name</Text>
                </Space>
                <Form.Item 
                  name="name"
                  validateFirst
                  rules={[
                    { required: true, message: 'Name is required!' },
                    { min: 5, message: 'Name must be at least 5 characters' },
                    { max: 50, message: '50 characters only' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8">
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Issuing Organization</Text>
                </Space>
                <Form.Item 
                  name="organization"
                  validateFirst
                  rules={[
                    { required: true, message: 'Issuing Organization is required!' },
                    { min: 5, message: 'Organization must be at least 5 characters' },
                    { max: 50, message: '50 characters only' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={24}>
                <Text className="fs-12" type="secondary">Year</Text>
                <Form.Item name="year">
                  <DatePicker className="full-width" size="small" picker="year" onChange={this.onChangeYear} disabledDate={ d => !d || d.isBefore(moment().subtract(20, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {
                id ?
                  <React.Fragment>
                    <Button className="f-left" onClick={this.handleDelete} type="link" loading={deleteLoading} danger>Delete</Button>
                    <Button className="f-right" htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                  </React.Fragment>
                :
                  <Space className="f-right">
                    <Button onClick={this.handleCancel}>Cancel</Button>
                    <Button htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                  </Space>
              }
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
  actionAddAchievement: addAchievement,
  actionUpdateAchievement: updateAchievement,
  actionDeleteAchievement: deleteAchievement
}

export default connect(mapStateToProps, mapDispatchToProps)(Achievement)