import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Space, Modal, Button, Input, Form, Row, Col, List, message } from 'antd';
import { LinkOutlined, DeleteTwoTone } from '@ant-design/icons';
import { addPortfolio, deletePortfolio } from '../../../../redux/actions/profile/portfolioAction';
import './Portfolio.css'

const { Text, Link } = Typography;

export class Portfolio extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
       visible: false,
       submitLoading: false,
       deleteLoading: false,
       valueForm: null
    }
  }
  
  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  handleDelete = (id) => {
    const { actionDeletePortfolio, actionGetProfile } = this.props
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeletePortfolio(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { actionAddPortfolio, actionGetProfile } = this.props

    values.url = values.url.slice(0,8) === 'https://' || values.url.slice(0,7) === 'http://' ? values.url : `http://${values.url}`
    
    return this.setState({ submitLoading: true }, () => {
      return actionAddPortfolio(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  handleChange = (value) => {
    this.setState({ valueForm: value })
  }

  render() {
    const { visible, submitLoading, deleteLoading, valueForm } = this.state
    const { data: { listPortfolio } } = this.props
    
    return (
      <React.Fragment>
        <Card
          className="br-6 box-shadow" 
          title="Portfolios"
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={listPortfolio.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
          actions={[
            <Text className="primary-color" onClick={this.showModal}>Add / Edit Portfolio</Text>
          ]}
        >
          {
            listPortfolio.length > 0 ?
              <Space direction="vertical">
                {
                  listPortfolio.map((item, i) => 
                    <Space key={i} className="link-portfolio full-width">
                      <LinkOutlined  />
                      <Link className="link-sub-portfolio" href={item.url} target="_blank" ellipsis={true} style={{ width: 250 }}>{item.url}</Link>
                    </Space>
                  )
                }
              </Space>
            :
              <Text className="txt-color">Portfolio presents evidence of your relevant skills</Text>
          }
        </Card>

        <Modal 
          title="Portfolios" 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
          >
            <Row gutter={8}>
              <Col span={20}>
                <Form.Item 
                  name="url"
                  validateFirst
                  rules={[
                    { pattern: /^\S*$/, message: 'No spaces allowed' },
                    { max: 100, message: '100 characters only' },
                  ]}
                >
                  <Input size="small" placeholder="link portfolio" onChange={(e) => this.handleChange(e.target.value)} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button className="full-width mt-4" htmlType="submit" type="primary" size="small" loading={submitLoading} disabled={!valueForm}>Add</Button>
              </Col>
            </Row>
            <Row className="mb-16">
              <Col span={20}>
                <List
                  size="small"
                  bordered={false}
                  dataSource={listPortfolio}
                  loading={deleteLoading}
                  renderItem={(item, i) => (
                    <List.Item
                      key={i}
                      className="txt-color"
                      actions={[<DeleteTwoTone className="c-pointer" twoToneColor="#ff4646" onClick={() => this.handleDelete(item.id)} />]}
                    >
                      {`${i+1}. ${item.url}`}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
  actionAddPortfolio: addPortfolio,
  actionDeletePortfolio: deletePortfolio
}

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio)
