import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Avatar, Typography, Space, Divider, Skeleton, Button } from 'antd';
import '../../Detail.css';
import { UserOutlined, EditFilled } from '@ant-design/icons';
import Contact from './../contact'
import PersonalInfo from './../personalInfo'
import Education from './../education'
const { Text } = Typography;

export class index extends Component {
  render() {
    const { 
      getContact, 
      getPersonalInfo,
      getLastEducation,
      onPreviewAvatar, 
      currentCityName,
      educationLevelName,
      valuesContact, 
      valuesPersonal,
      valuesEducation,
      editContact,
      editPersonal,
      editEducation,
      handleEditContact,
      handleEditPersonal,
      handleEditEducation,
      callingCode
    } = this.props
    if(getContact.loading || getPersonalInfo.loading || getLastEducation.loading){
      return <Skeleton active avatar />
    }
    return (
      <React.Fragment>
        {
          editContact ?
            <Contact {...this.props} />
          : editPersonal ?
            <PersonalInfo {...this.props} />
          : editEducation ?
            <Education {...this.props} />
          :
            <React.Fragment>
              <Row className="mb-16">
                <Col span={24}>
                  <Text className="fs-16" type="secondary">Review your application</Text>
                </Col>
              </Row>
              <Row className="mb-8">
                <Col span={20}>
                  <Text className="fs-14 drk-color">Contact Detail</Text>
                </Col>
                <Col span={4}>
                  <Button className="f-right" onClick={handleEditContact} type="link" icon={<EditFilled />}>Edit</Button>
                </Col>
              </Row>
                <React.Fragment>
                  <Row className="mb-8">
                    <Space size={24}>
                      <Avatar 
                        size={80} 
                        src={
                          onPreviewAvatar ? 
                            onPreviewAvatar 
                          : getContact.data.profilePicUrl  ? 
                            getContact.data.profilePicUrl 
                          : 
                            null
                        } 
                        icon={
                          onPreviewAvatar ? 
                            null 
                          : getContact.data.profilePicUrl ? 
                            null 
                          : 
                          <UserOutlined />
                        } 
                      />
                      <Space direction="vertical" size={16}>
                        <Text className="fs-15 drk-color">{getContact.data.fullName}</Text>
                        <Text className="fs-15 drk-color">{getContact.data.email}</Text>
                      </Space>
                    </Space>
                  </Row>
                  <Row className="mb-8">
                    <Col span={24}>
                      <Space direction="vertical">
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">Mobile Number</Text>
                          <Text className="drk-color" strong>
                            { 
                              valuesContact ? 
                                `+${callingCode}${valuesContact.mobilePhone}`
                              : getContact.data.mobilePhone ?
                                `+${getContact.data.callingCode}${getContact.data.mobilePhone}`
                              :
                                '-'
                            }
                          </Text>
                        </Space>
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">Current Location</Text>
                          <Text className="drk-color" strong>
                            {
                              currentCityName ?
                                currentCityName
                              : getContact.data.currentCityName ?
                                `${getContact.data.currentCityName}, ${getContact.data.currentCountryName}`
                              :
                                null
                            }
                          </Text>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </React.Fragment>
              <Divider />
              <Row className="mb-16">
                <Col span={20}>
                  <Text className="fs-16" type="secondary">Personal Information</Text>
                </Col>
                <Col span={4}>
                  <Button className="f-right" onClick={handleEditPersonal} type="link" icon={<EditFilled />}>Edit</Button>
                </Col>
              </Row>
              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Place of Birth</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.placeOfBirth ? 
                            valuesPersonal.placeOfBirth 
                          : getPersonalInfo.data.placeOfBirth ?
                            getPersonalInfo.data.placeOfBirth
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Date of Birth</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.birthdate ? 
                            valuesPersonal.birthdate 
                          : getPersonalInfo.data.birthdate ?
                            getPersonalInfo.data.birthdate
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Marital Status</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.marital ? 
                            valuesPersonal.marital 
                          : getPersonalInfo.data.marital ?
                            getPersonalInfo.data.marital
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Gender</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.gender ? 
                            valuesPersonal.gender 
                          : getPersonalInfo.data.gender ?
                            getPersonalInfo.data.gender
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Religion</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.religion ? 
                            valuesPersonal.religion 
                          : getPersonalInfo.data.religion ?
                            getPersonalInfo.data.religion
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Nationality</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.nationality ? 
                            valuesPersonal.nationality 
                          : getPersonalInfo.data.nationality ?
                            getPersonalInfo.data.nationality
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Blood Type</Text>
                      <Text className="drk-color" strong>
                        {/* {
                          valuesPersonal && valuesPersonal.bloodType ? 
                            valuesPersonal.bloodType 
                          : getPersonalInfo.data.bloodType ?
                            getPersonalInfo.data.bloodType
                          :
                            '-'
                        } */}
                        {
                            (valuesPersonal && valuesPersonal.bloodType) || getPersonalInfo.data.bloodType || '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Height (cm)</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.height ? 
                            valuesPersonal.height 
                          : getPersonalInfo.data.height ?
                            getPersonalInfo.data.height
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Weight (kg)</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.weight ? 
                            valuesPersonal.weight 
                          : getPersonalInfo.data.weight ?
                            getPersonalInfo.data.weight
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">ID Card Number (KTP)</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.identificationId ? 
                            valuesPersonal.identificationId 
                          : getPersonalInfo.data.identificationId ?
                            getPersonalInfo.data.identificationId
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Text type="secondary">ID Card (KTP) Address</Text>
              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Country</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.country ? 
                            valuesPersonal.country 
                          : getPersonalInfo.data.countryName ?
                            getPersonalInfo.data.countryName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Province</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.province ? 
                            valuesPersonal.province 
                          : getPersonalInfo.data.provinceName ?
                            getPersonalInfo.data.provinceName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">City</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.city ? 
                            valuesPersonal.city 
                          : getPersonalInfo.data.cityName ?
                            getPersonalInfo.data.cityName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    {
                      getPersonalInfo.data.CountryId === 99 || (valuesPersonal && valuesPersonal.countryId === 99) ?
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">Sub-district</Text>
                          <Text className="drk-color" strong>
                          {
                            valuesPersonal && valuesPersonal.subDistrict ? 
                              valuesPersonal.subDistrict 
                            : getPersonalInfo.data.subDistrict ?
                              getPersonalInfo.data.subDistrict
                            :
                              '-'
                          }
                          </Text>
                        </Space>
                      :
                        null
                    }
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    {
                      getPersonalInfo.data.CountryId === 99 || (valuesPersonal && valuesPersonal.countryId === 99) ?
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">Village</Text>
                          <Text className="drk-color" strong>
                          {
                            valuesPersonal && valuesPersonal.village ? 
                              valuesPersonal.village 
                            : getPersonalInfo.data.village ?
                              getPersonalInfo.data.village
                            :
                              '-'
                          }
                          </Text>
                        </Space>
                      :
                        null
                    }
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Postal Code</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.zipCode ? 
                            valuesPersonal.zipCode 
                          : getPersonalInfo.data.zipCode ?
                            getPersonalInfo.data.zipCode
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Street Address</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesPersonal && valuesPersonal.address ? 
                            valuesPersonal.address 
                          : getPersonalInfo.data.address ?
                            getPersonalInfo.data.address
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Row className="mb-16">
                <Col span={20}>
                  <Text className="fs-16" type="secondary">Education</Text>
                </Col>
                <Col span={4}>
                  <Button className="f-right" onClick={handleEditEducation} type="link" icon={<EditFilled />}>Edit</Button>
                </Col>
              </Row>
              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">School/University</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesEducation && valuesEducation.educationName ? 
                            valuesEducation.educationName 
                          : getLastEducation.data.educationName ?
                            getLastEducation.data.educationName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Field of Study</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesEducation && valuesEducation.educationFieldOfStudy ? 
                            valuesEducation.educationFieldOfStudy 
                          : getLastEducation.data.educationFieldOfStudy ?
                            getLastEducation.data.educationFieldOfStudy
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Start Year</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesEducation && valuesEducation.educationStartYear ? 
                            valuesEducation.educationStartYear 
                          : getLastEducation.data.educationStartYear ?
                            getLastEducation.data.educationStartYear
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Activities and Organization Experiences</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesEducation && valuesEducation.educationExperience ? 
                            valuesEducation.educationExperience 
                          : getLastEducation.data.educationExperience ?
                            getLastEducation.data.educationExperience
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Education Level</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesEducation && valuesEducation.educationLevelId ? 
                            educationLevelName 
                          : getLastEducation.data.educationLevelName ?
                            getLastEducation.data.educationLevelName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Score/Grade/GPA</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesEducation && valuesEducation.educationGpa ? 
                            valuesEducation.educationGpa 
                          : getLastEducation.data.educationGpa ?
                            getLastEducation.data.educationGpa
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">End Year (or expected)</Text>
                      <Text className="drk-color" strong>
                        {
                          valuesEducation && valuesEducation.educationEndYear ? 
                            valuesEducation.educationEndYear 
                          : getLastEducation.data.educationEndYear ?
                            getLastEducation.data.educationEndYear
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(index)