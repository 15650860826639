import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card, Typography, Modal, Button, Input, Space, Form, Row, Col, DatePicker, List, Checkbox, message } from 'antd';
import { EditFilled, SwapRightOutlined } from '@ant-design/icons';
import { addTraining, updateTraining, deleteTraining } from '../../../../redux/actions/profile/trainingAction';

const { Text } = Typography;

export class Training extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      deleteLoading: false,
      isDate: false,
      issueDate: null,
      expDate: null,
      isExp: false,
      id: null,
      dataEdit: null
    }
  }
  
  showModal = (id) => {
    const { data } = this.props
    const dataEdit = data.listTraining.filter(item => item.id === id).pop()
    
    this.setState({
      visible: true,
      id: id,
      issueDate: id ? dataEdit?.issueDate : null,
      expDate: id ? dataEdit?.expDate : null,
      isExp: id ? dataEdit?.isExp : false,
      dataEdit: dataEdit
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      isDate: false
    })
  };

  onChangeIssueDate = (date, dateString) => {
    const { expDate } = this.state

    this.setState({
      issueDate: dateString
    })

    if(expDate){
      if(expDate < dateString){
        this.setState({
          isDate: true
        })
      }else{
        this.setState({
          isDate: false
        })
      }
    }
  }

  onChangeExpDate = (date, dateString) => {
    const { issueDate } = this.state

    this.setState({
      expDate: dateString
    })

    if(dateString < issueDate){
      this.setState({
        isDate: true
      })
    }else{
      this.setState({
        isDate: false
      })
    }
  }

  handleChecked = (e) => {
    this.setState({
      isExp: e.target.checked
    })
    
    if(e.target.checked){
      this.setState({
        expDate: null
      })

      this.formRef.current.setFieldsValue({
        expDate: null
      })
    }
  }

  handleDelete = () => {
    const { actionDeleteTraining, actionGetProfile } = this.props
    const { id } = this.state
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteTraining(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { actionAddTraining, actionUpdateTraining, actionGetProfile } = this.props
    const { issueDate, expDate, isExp, id, isDate } = this.state
    values.issueDate = issueDate
    values.expDate = expDate
    values.isExp = isExp
    values.credentialUrl = values.credentialUrl ? values.credentialUrl.slice(0,8) === 'https://' || values.credentialUrl.slice(0,7) === 'http://' ? values.credentialUrl : `http://${values.credentialUrl}` : null
    values.id = id

    if(isDate){
      message.error('Your expiration date can’t be earlier than your issue date')
    }else{
      if(id){
        return this.setState({ submitLoading: true }, () => {
          return actionUpdateTraining(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }else{
        return this.setState({ submitLoading: true }, () => {
          return actionAddTraining(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }
    }
  }

  render() {
    const { visible, submitLoading, deleteLoading, isDate, isExp, id, dataEdit } = this.state
    const { data: { listTraining } } = this.props
    
    return (
      <React.Fragment>
        <Card 
          className="br-6 box-shadow" 
          title="Training and Certification" 
          bordered={false}
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={listTraining.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
          actions={[
            <Text className="primary-color" onClick={() => this.showModal(null)}>Add training and certification</Text>
          ]}
        >
          {
            listTraining.length > 0 ?
              <List
                dataSource={listTraining}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <Row className="full-width">
                      <Col span={20}>
                        <Space direction="vertical" size={0}>
                          <Text className="primary-color">{item.name}</Text>
                          <Text className="txt-color">
                            { 
                              item.issueDate && item.expDate ?
                                `${moment(item.issueDate).format('MMM YYYY')} - ${moment(item.expDate).format('MMM YYYY')}`
                              : item.issueDate && item.isExp ?
                                `${moment(item.issueDate).format('MMM YYYY')} - No Expiration Date`
                              : item.issueDate ?
                                moment(item.issueDate).format('MMM YYYY')
                              : item.expDate ?
                                moment(item.expDate).format('MMM YYYY')
                              : item.isExp ?
                                'No Expiration Date'
                              :
                                null
                            }
                          </Text>
                          <Text className="txt-color">{item.credentialId}</Text>
                          {
                            item.credentialUrl ?
                              <a className="a-color" href={item.credentialUrl} target="_blank" rel="noreferrer">go to link <SwapRightOutlined /></a>
                            :
                              null
                          }
                        </Space>
                      </Col>
                      <Col span={4}>
                        <Text className="primary-color f-right c-pointer" onClick={() => this.showModal(item.id)}><EditFilled /> Edit</Text>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            :
              <Text className="txt-color">Training and certifications are credentials that prove you’ve acquired competencies, developed skills, and gained the knowledge.</Text>
          }
        </Card>

        <Modal 
          title="Training and Certifications" 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              name: id ? dataEdit?.name : null,
              organization: id ? dataEdit?.organization : null,
              issueDate: id && dataEdit?.issueDate ? moment(dataEdit?.issueDate) : null,
              expDate: id && dataEdit?.expDate ? moment(dataEdit?.expDate) : null,
              credentialId: id ? dataEdit?.credentialId : null,
              credentialUrl: id ? dataEdit?.credentialUrl : null
            }}
          >
            <Row className="mb-min-8">
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Name</Text>
                </Space>
                <Form.Item 
                  name="name"
                  validateFirst
                  rules={[
                    { required: true, message: 'Name is required!' },
                    { min: 5, message: 'Name must be at least 5 characters' },
                    { max: 50, message: '50 characters only' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8">
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Issuing Organization</Text>
                </Space>
                <Form.Item 
                  name="organization"
                  validateFirst
                  rules={[
                    { required: true, message: 'Issuing Organization is required!' },
                    { min: 5, message: 'Organization must be at least 5 characters' },
                    { max: 50, message: '50 characters only' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Issue Date</Text>
                <Form.Item name="issueDate">
                  <DatePicker className="full-width" size="small" picker="month" placeholder="YYYY-MM" onChange={this.onChangeIssueDate} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Expiration Date</Text>
                <Form.Item 
                  name="expDate"
                  validateStatus={isDate && !isExp ? "error" : null}
                  help={isDate && !isExp ? "Your expiration date can’t be earlier than your issue date" : null}
                >
                  {
                    isExp ?
                      <Text className="fs-12" type="secondary">No Expiration Date</Text>
                    :
                      <DatePicker className="full-width" size="small" picker="month" placeholder="YYYY-MM" onChange={this.onChangeExpDate} />
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-16">
              <Col span={24}>
                <Form.Item>
                  <Checkbox className="fs-12 txt-color" onChange={this.handleChecked} checked={isExp}>This credential does not expire</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-16">
              <Col span={24}>
                <Text className="fs-12" type="secondary">Credential ID</Text>
                <Form.Item 
                  name="credentialId"
                  rules={[
                    { max: 50, message: '50 characters only' }
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-16">
              <Col span={24}>
                <Text className="fs-12" type="secondary">Credential URL</Text>
                <Form.Item 
                  name="credentialUrl"
                  rules={[
                    { max: 100, message: '100 characters only' }
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {
                id ?
                  <React.Fragment>
                    <Button className="f-left" onClick={this.handleDelete} type="link" loading={deleteLoading} danger>Delete</Button>
                    <Button className="f-right" htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                  </React.Fragment>
                :
                  <Space className="f-right">
                    <Button onClick={this.handleCancel}>Cancel</Button>
                    <Button htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                  </Space>
              }
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionAddTraining: addTraining,
  actionUpdateTraining: updateTraining,
  actionDeleteTraining: deleteTraining
}

export default connect(mapStateToProps, mapDispatchToProps)(Training)
