import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountlistCityByProvince = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_CITY'})
}

export const unmountIndexCity = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_CITY'})
}

export const unmountListCity = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_CITY'})
}

export const listCityByProvince = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_CITY' })
  const params = { 
    id: value 
  }
  API.GET('/city/getByProvinceId', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_CITY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_CITY_FAILED' }), 
    ))
  })
}

export const indexCity = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_CITY' })
  API.GET('/city/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_CITY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_CITY_FAILED' }), 
    ))
  })
}

export const listCity = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_CITY' })
  return API.GET('/city/list', value).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_CITY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_CITY_FAILED' }), 
    ))
  })
}