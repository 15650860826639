import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'
import Cookie from 'js-cookie';

export const unmountListJob = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOB'})
}

export const unmountDetailJob = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_JOB'})
}

export const listJob = (params, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOB' })
  
  return API.GET('/job/list', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOB_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
    return successCB(params)
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_JOB_FAILED' }), 
    ))
  })
}

export const listJobCompany = (params) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOB' })
  
  return API.GET('/job/list', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOB_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_JOB_FAILED' }), 
    ))
  })
}

export const listCandidateJob = (params, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOB' })
  
  return API.GET('/job/listLogin', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOB_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
    return successCB(params)
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_JOB_FAILED' }), 
    ))
  })
}

export const listCandidateJobCompany = (params) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOB' })
  
  return API.GET('/job/listLogin', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOB_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_JOB_FAILED' }), 
    ))
  })
}

export const detailJob = (id) => async (dispatch) => {
  const getToken = Cookie.get('user')

  await dispatch({ type: 'LOAD_DETAIL_JOB' })
  const params = {
    'id': id
  }
  return API.GET('/job/getById', params).then((response) => {
    dispatch({
      type: 'LOAD_DETAIL_JOB_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    if(err.code === "5000"){
      window.location = getToken ? '/candidate/job' : '/job'
    }
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DETAIL_JOB_FAILED' }), 
    ))
  })
}