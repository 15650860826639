import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Button, Modal, Space, Form, Row, Col, Select, InputNumber, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { indexCountry } from '../../../../redux/actions/master/country/countryAction';
import { indexJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { listJobPositionByJobIndustry } from '../../../../redux/actions/master/jobPosition/jobPositionAction';
import { indexJobDestination } from '../../../../redux/actions/master/jobDestination/jobDestinationAction';
import { updateJobPreference } from '../../../../redux/actions/profile/jobPreferenceAction';


const { Text } = Typography;
const { Option, OptGroup } = Select;

export class JobPreferences extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: this.props.visible,
      submitLoading: false,
      industriesSelected: [],
      positionSelected: [],
      destinationSelected: [],
      currencyCodeId: null,
      salaryFrom: null,
      salaryTo: null,
      isSalary: false
    }
  }

  componentDidMount() {
    const { data: { listJobIndustry, listJobPosition, listJobDestination, currencyCodeId, salaryFrom, salaryTo }, actionGetCountry, actionGetJobIndustries, actionGetJobPosition, actionGetJobDestination } = this.props
    
    if(listJobIndustry.length > 0 || listJobPosition.length > 0 || listJobDestination.length > 0){
      const arrJobIndustry = listJobIndustry?.map(item => item.jobIndustryId)
      const arrJobPosition = listJobPosition?.map(item => item.jobPositionId)
      const arrJobDestination = listJobDestination?.map(item => item.jobDestinationId)
      const strJobIndustry = arrJobIndustry?.join()

      this.setState({
        industriesSelected: arrJobIndustry,
        positionSelected: arrJobPosition,
        destinationSelected: arrJobDestination,
        currencyCodeId: currencyCodeId,
        salaryFrom: salaryFrom,
        salaryTo
      })

      return (actionGetCountry(), actionGetJobIndustries(), actionGetJobPosition(strJobIndustry), actionGetJobDestination())
    }

    return (actionGetCountry(), actionGetJobIndustries(), actionGetJobDestination())
  }
  
  showModal = () => {
    this.setState({
      visible: true
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  handleIndustry = (value, option) => {
    const optionSelected = option.map(item => Number(item.key))
    const strSelected = optionSelected.toString()
    
    this.formRef.current.setFieldsValue({ position: [] })

    this.setState({
      industriesSelected: optionSelected,
    })
    
    const { actionGetJobPosition } = this.props
    return actionGetJobPosition(strSelected)
  }

  handlePosition = (value, option) => {
    const optionSelected = option.map(item => Number(item.key))
    
    this.setState({
      positionSelected: optionSelected
    })
  }

  handleDestination = (value, option) => {
    const optionSelected = option.map(item => Number(item.key))

    this.setState({
      destinationSelected: optionSelected
    })
  }

  handleCurrency = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      currencyCodeId: optionSelected
    })
  }

  handleSalaryFrom = (value) => {
    const { salaryTo } = this.state

    this.setState({
      salaryFrom: value
    })

    if(salaryTo <= value){
      this.setState({
        isSalary: true
      })
    }else{
      this.setState({
        isSalary: false
      })
    }
  }

  handleSalaryTo = (value) => {
    const { salaryFrom } = this.state

    this.setState({
      salaryTo: value
    })

    if(value <= salaryFrom){
      this.setState({
        isSalary: true
      })
    }else{
      this.setState({
        isSalary: false
      })
    }
  }

  onFinish = async (values) => {
    const { actionUpdateJobPreference, actionGetProfile } = this.props
    const { industriesSelected, positionSelected, destinationSelected, currencyCodeId, isSalary } = this.state
    values.listJobIndustry = industriesSelected
    values.listJobPosition = positionSelected 
    values.listJobDestination = destinationSelected 
    values.currencyCodeId = currencyCodeId
    
    return this.setState({ submitLoading: true }, () => {
      if(isSalary){
        message.error("Value ‘To’ must be bigger than ’Start From’")
        this.setState({ submitLoading: false })
      }else{
        return actionUpdateJobPreference(values, response => {
          if(response.code === '1000'){
            return this.setState({ submitLoading: false, visible: false }, () => {
              message.success(response.message)
              return actionGetProfile()
            })
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
        })
      }
    })
  }

  render() {
    const { visible, submitLoading, isSalary } = this.state
    const { getCountry, getJobIndustries, getJobPosition, getJobDestination, data: { listJobIndustry, listJobPosition, listJobDestination, currencyCode, salaryFrom, salaryTo } } = this.props
    const isFilled = listJobIndustry.length > 0 || listJobPosition.length > 0 || listJobDestination.length > 0 || currencyCode || salaryFrom || salaryTo
    const arrJobIndustry = listJobIndustry?.map(item => item.jobIndustryName)
    const strJobIndustry = arrJobIndustry?.join(', ')
    const arrJobPosition = listJobPosition?.map(item => item.jobPositionName)
    const strJobPosition = arrJobPosition?.join(', ')
    const arrJobDestination = listJobDestination?.map(item => item.jobDestinationName)
    const strJobDestination = arrJobDestination?.join(', ')
    
    return (
      <React.Fragment>
          <Card 
            className="br-6 box-shadow" 
            title="Job Preferences" 
            bordered={false} 
            extra={<Button type="link" icon={<EditFilled />} onClick={this.showModal}>Edit</Button>} 
            headStyle={{ color: '#32ab6d', fontSize: 17 }}
            bodyStyle={isFilled ? {} : { textAlign: 'center', padding: 60 }}
          >
            {
              isFilled ?
                <Row>
                  <Col md={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Job Industry</Text>
                        <Text className="drk-color" strong>{listJobIndustry.length > 0 ? strJobIndustry : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Job Position</Text>
                        <Text className="drk-color" strong>{listJobPosition.length > 0 ? strJobPosition : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Job Destination</Text>
                        <Text className="drk-color" strong>{listJobDestination.length > 0 ? strJobDestination : '-'}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col md={12}>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Salary Expectation</Text>
                      <Text className="drk-color" strong>{currencyCode && salaryFrom && salaryTo ? `${currencyCode} ${salaryFrom.toLocaleString()} - ${salaryTo.toLocaleString()}` : '-'}</Text>
                    </Space>
                  </Col>
                </Row>
              :
              <Text className="txt-color">Provide your job preferences for better job matches</Text>
            }
          </Card>
          <Modal 
            title="Job Preferences" 
            visible={visible} 
            onCancel={this.handleCancel} 
            footer={false}
            bodyStyle={{ paddingBottom: 4 }}
            centered
            width={600}
            destroyOnClose
          >
            <Form 
              ref={this.formRef}
              layout="vertical"
              onFinish={this.onFinish}
              initialValues={{
                industry: arrJobIndustry,
                position: arrJobPosition,
                destination: arrJobDestination,
                currency: currencyCode,
                salaryFrom: salaryFrom,
                salaryTo: salaryTo
              }}
            >
              <Row className="mb-min-8">
                <Col span={24}>
                  <Text className="fs-12" type="secondary">Job Industry Preferences</Text>
                  <Form.Item 
                    name="industry"
                    rules={[
                      { 
                        validator: (rule, value, callback) => {
                          if (value) {
                            if (value.length > 3) {
                              callback("You can only pick 3 job industries!");
                            } else if (value.length <= 3) {
                              callback();
                            }
                          }
                          return;
                        }
                      },
                    ]}
                  >
                    <Select 
                      className="full-width br-6"
                      mode="multiple"
                      allowClear 
                      size="small" 
                      showSearch
                      onChange={this.handleIndustry}
                    >
                      {
                        getJobIndustries?.data?.map((item) => 
                          <Option key={item.id} value={item.title}>{item.title}</Option>
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-min-8">
                <Col span={24}>
                  <Text className="fs-12" type="secondary">Job Position Preferences</Text>
                  <Form.Item 
                    name="position"
                    rules={[
                      { 
                        validator: (rule, value, callback) => {
                          if (value) {
                            if (value.length > 3) {
                              callback("You can only pick 3 job position!");
                            } else if (value.length <= 3) {
                              callback();
                            }
                          }
                          return;
                        }
                      },
                    ]}
                  >
                    <Select 
                      className="full-width br-6"
                      mode="multiple"
                      allowClear 
                      size="small" 
                      showSearch
                      onChange={this.handlePosition}
                    >
                      {
                        getJobPosition?.data?.map((item, i) => 
                          <OptGroup key={i} label={item.name}>
                            {
                              item.listJobPosition.map((res) => 
                                <Option key={res.id} value={res.title}>{res.title}</Option>
                              )
                            }
                          </OptGroup>
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-min-8">
                <Col span={24}>
                  <Text className="fs-12" type="secondary">Job Destination Preferences</Text>
                  <Form.Item 
                    name="destination"
                    rules={[
                      { 
                        validator: (rule, value, callback) => {
                          if (value) {
                            if (value.length > 5) {
                              callback("You can only pick 5 job destination!");
                            } else if (value.length <= 5) {
                              callback();
                            }
                          }
                          return;
                        }
                      },
                    ]}
                  >
                    <Select 
                      className="full-width br-6"
                      mode="multiple"
                      allowClear 
                      size="small" 
                      showSearch
                      onChange={this.handleDestination}
                    >
                      {
                        getJobDestination?.data?.map((item, i) => 
                          <OptGroup key={i} label={item.name}>
                            {
                              item.listDestination.map((res) => 
                                <Option key={res.id} value={res.name}>{res.name}</Option>
                              )
                            }
                          </OptGroup>
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Text className="txt-color">Salary Expectation</Text>
              <Row gutter={8}>
                <Col span={8}>
                  <Text className="fs-12" type="secondary">Currency</Text>
                  <Form.Item name="currency">
                    <Select 
                      className="full-width" 
                      size="small" 
                      showSearch
                      onChange={this.handleCurrency}
                    >
                      {
                        getCountry?.data?.map((item) => 
                          <Option key={item.id} value={item.currencyCode}>{item.currencyCode}</Option>
                          )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Text className="fs-12" type="secondary">Start From:</Text>
                  <Form.Item 
                    name="salaryFrom"
                  >
                    <InputNumber
                      className="full-width br-6"
                      size="small"
                      maxLength={12}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      onChange={this.handleSalaryFrom}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Text className="fs-12" type="secondary">To:</Text>
                  <Form.Item 
                    name="salaryTo"
                    validateStatus={isSalary ? "error" : null}
                    help={isSalary ? "Value ‘To’ must be bigger than ’Start From’" : null}
                  >
                    <InputNumber
                      className="full-width br-6"
                      size="small"
                      maxLength={12}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      onChange={this.handleSalaryTo}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space className="f-right">
                  <Button onClick={this.handleCancel}>Cancel</Button>
                  <Button htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                </Space>
              </Form.Item>
            </Form>
          </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getJobIndustries: state.jobIndustriesReducer,
  getJobPosition: state.jobPositionReducer,
  getJobDestination: state.jobDestinationReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  actionGetJobIndustries: indexJobIndustries,
  actionGetJobPosition: listJobPositionByJobIndustry,
  actionGetJobDestination: indexJobDestination,
  actionUpdateJobPreference: updateJobPreference
}

export default connect(mapStateToProps, mapDispatchToProps)(JobPreferences)
