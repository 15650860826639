import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Space, Progress } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';

const { Text } = Typography;

export class Completeness extends Component {
  render() {
    const { data: { profileCompletness: { percentage, listDetail } } } = this.props
    
    return (
      <React.Fragment>
        {
          percentage === 100 ?
            <Card className="br-6 box-shadow mt-16">
              <Space className="full-width fs-17" direction="vertical">
                <Text className="primary-color">Profile Completeness</Text>
                <Progress 
                  strokeColor={{
                    '0%': '#1877f2',
                    '100%': '#32ab6d',
                  }} 
                  percent={percentage} 
                  status="active"
                  style={{ marginLeft: 6 }}
                />
              </Space>
            </Card>
          :
            <Card
              className="br-6 box-shadow"
              title={
                <Space className="full-width" direction="vertical">
                  <Text className="primary-color">Profile Completeness</Text>
                  <Progress 
                    strokeColor={{
                      '0%': '#1877f2',
                      '100%': '#32ab6d',
                    }} 
                    percent={percentage} 
                    status="active"
                  />
              </Space>
              }
              headStyle={{ color: '#32ab6d', fontSize: 17 }}
            >
              <Space direction="vertical">
                <Text className="drk-color">Complete your data</Text>
                {
                  listDetail.filter(item => item.isCheck === false).map((item, i) => 
                    <Space key={i} size={8}>
                      <CloseCircleTwoTone twoToneColor="#f58181" /><Text className="txt-color">{item.name}</Text>
                    </Space>
                  )
                }
              </Space>
            </Card>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Completeness)
