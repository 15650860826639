import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Modal, Button, Input, Space, Form, Row, Col, Divider, DatePicker, Select, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { indexCountry, unmountIndexCountry } from '../../../../redux/actions/master/country/countryAction';
import { listProvinceByCountry, unmountlistProvinceByCountry } from '../../../../redux/actions/master/province/provinceAction';
import { listCityByProvince, unmountlistCityByProvince } from '../../../../redux/actions/master/city/cityAction';
import { subDistrictByCity, unmountSubDistrictByCity } from '../../../../redux/actions/master/subDistrict/subDistrictAction';
import { villageBySubDistrict, unmountVillageBySubDistrict } from '../../../../redux/actions/master/village/villageAction';
import { updatePersonalInfo } from '../../../../redux/actions/profile/personalInfoAction';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;

export class PersonalInfo extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      birthdate: null,
      nationalityId: null,
      countryId: null,
      provinceId: null,
      cityId: null,
      subDistrictId: null,
      villageId: null,
      selectedCountry: null
    }
  }
  
  componentDidMount() {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage, data: { countryId, provinceId, cityId, subDistrictId } } = this.props
    
    this.setState({
      selectedCountry: countryId
    })

    if(subDistrictId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId), actionGetVillage(subDistrictId))
    }else if(cityId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId))
    }else if(provinceId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId))
    }else if(countryId){
      return (actionGetCountry(), actionGetProvince(countryId))
    }

    return actionGetCountry()
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  };

  handleCancel = () => {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage, data: { countryId, provinceId, cityId, subDistrictId } } = this.props
    
    this.setState({
      visible: false,
      selectedCountry: countryId
    })

    if(subDistrictId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId), actionGetVillage(subDistrictId))
    }else if(cityId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId))
    }else if(provinceId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId))
    }else if(countryId){
      return (actionGetCountry(), actionGetProvince(countryId))
    }
  };

  onChangeDate = (date, dateString) => {
    this.setState({
      birthdate: dateString ? dateString : null
    })
  }

  handleNationality = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      nationalityId: optionSelected
    })
  }

  handleCountry = (value, option) => {
    const { provinceId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      countryId: optionSelected,
      selectedCountry: optionSelected,
    })

    if(provinceId !== 0){
      this.setState({
        provinceId: null,
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        province: null,
        city: null,
        subDistrict: null,
        village: null
      })
    }

    const { actionGetProvince } = this.props
    return actionGetProvince(option.key)
  }

  handleProvince = (value, option) => {
    const { cityId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      provinceId: optionSelected
    })

    if(cityId !== 0){
      this.setState({
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        city: null,
        subDistrict: null,
        village: null
      })
    }

    const { actionGetCity } = this.props
    return actionGetCity(option.key)
  }

  handleCity = (value, option) => {
    const { subDistrictId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      cityId: optionSelected
    })

    if(subDistrictId !== 0){
      this.setState({
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        subDistrict: null,
        village: null
      })
    }

    const { actionGetSubDistrict } = this.props
    return actionGetSubDistrict(option.key)
  }

  handleSubDistrict = (value, option) => {
    const { villageId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      subDistrictId: optionSelected
    })

    if(villageId !== 0){
      this.setState({
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        village: null
      })
    }

    const { actionGetVillage } = this.props
    return actionGetVillage(option.key)
  }

  handleVillage = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      villageId: optionSelected
    })
  }

  onFinish = async (values) => {
    const { actionUpdatePersonalInfo, actionGetProfile } = this.props
    const { birthdate, nationalityId, countryId, provinceId, cityId, subDistrictId, villageId } = this.state
    values.birthdate = birthdate ? birthdate : null
    values.nationalityId = nationalityId
    values.countryId = countryId
    values.provinceId = provinceId
    values.cityId = cityId
    values.subDistrictId = subDistrictId
    values.villageId = villageId
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdatePersonalInfo(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  render() {
    const { visible, submitLoading, selectedCountry } = this.state
    const { getCountry, getProvince, getCity, getSubDistrict, getVillage, data: { placeOfBirth, birthdate, marital, gender, religion, nationality, bloodType, height, weight, identificationId, countryId, countryName, provinceName, cityName, subDistrictName, villageName, zipCode, address } } = this.props
    const isFilled = placeOfBirth || birthdate || marital || gender || religion || nationality || bloodType || height || weight || identificationId || countryName || provinceName || cityName || subDistrictName || villageName || zipCode || address
    
    return (
      <React.Fragment>
        <Card 
          className="br-6 box-shadow" 
          title="Personal Information" 
          bordered={false} 
          extra={<Button type="link" icon={<EditFilled />} onClick={this.showModal}>Edit</Button>} 
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={isFilled ? {} : { textAlign: 'center', padding: 60 }}
        >
          {
            isFilled ?
              <React.Fragment>
                <Row className="mb-16">
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Place of Birth</Text>
                        <Text className="drk-color" strong>{placeOfBirth ? placeOfBirth : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Date of Birth</Text>
                        <Text className="drk-color" strong>{birthdate ? birthdate : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Marital Status</Text>
                        <Text className="drk-color" strong>{marital ? marital : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Gender</Text>
                        <Text className="drk-color" strong>{gender ? gender : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Religion</Text>
                        <Text className="drk-color" strong>{religion ? religion : '-'}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Nationality</Text>
                        <Text className="drk-color" strong>{nationality ? nationality : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Blood Type</Text>
                        <Text className="drk-color" strong>{bloodType ? bloodType : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Height (cm)</Text>
                        <Text className="drk-color" strong>{height ? height : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Weight (kg)</Text>
                        <Text className="drk-color" strong>{weight ? weight : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">ID Card Number (KTP)</Text>
                        <Text className="drk-color" strong>{identificationId ? identificationId : '-'}</Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
                <Text type="secondary">ID Card (KTP) Address</Text>
                <Divider style={{ margin: '8px 0' }} />
                <Row className="mb-16">
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Country</Text>
                        <Text className="drk-color" strong>{countryName ? countryName : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Province</Text>
                        <Text className="drk-color" strong>{provinceName ? provinceName : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">City</Text>
                        <Text className="drk-color" strong>{cityName ? cityName : '-'}</Text>
                      </Space>
                      {
                        countryId === 99 ?
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12" type="secondary">Sub-district</Text>
                            <Text className="drk-color" strong>{subDistrictName ? subDistrictName : '-'}</Text>
                          </Space>
                        :
                          null
                      }
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      {
                        countryId === 99 ?
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12" type="secondary">Village</Text>
                            <Text className="drk-color" strong>{villageName ? villageName : '-'}</Text>
                          </Space>
                        :
                          null
                      }
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Postal Code</Text>
                        <Text className="drk-color" strong>{zipCode ? zipCode : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Street Address</Text>
                        <Text className="drk-color" strong>{address ? address : '-'}</Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </React.Fragment>
            :
              <Text className="txt-color">Tell us some of your personal information data</Text>
          }
        </Card>

        <Modal 
          title="Personal Information" 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              placeOfBirth: placeOfBirth,
              birthdate: birthdate ? moment(birthdate, 'DD/MM/YYYY') : null,
              gender: gender,
              bloodType: bloodType,
              height: height ? height.toString() : null,
              weight: weight ? weight.toString(): null,
              marital: marital,
              religion: religion,
              nationality: nationality,
              identificationId: identificationId,
              country: countryName,
              province: provinceName,
              city: cityName,
              subDistrict: subDistrictName,
              village: villageName,
              zipCode: zipCode,
              address: address
            }}
          >
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Place of Birth</Text>
                <Form.Item 
                  name="placeOfBirth"
                  validateFirst
                  rules={[
                    { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                    { max: 50, message: '50 characters only' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Date of Birth</Text>
                <Form.Item name="birthdate">
                  <DatePicker className="full-width" size="small" format="DD/MM/YYYY" onChange={this.onChangeDate} defaultPickerValue={birthdate ? null : moment('2000-01-01')} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={6}>
                <Text className="fs-12" type="secondary">Gender</Text>
                <Form.Item name="gender">
                  <Select 
                    className="full-width br-6"
                    size="small" 
                  >
                    <Option key="1" value="MALE">Male</Option>
                    <Option key="2" value="FEMALE">Female</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="fs-12" type="secondary">Blood Type</Text>
                <Form.Item name="bloodType">
                  <Select 
                    className="full-width br-6"
                    size="small" 
                  >
                    <Option key="1" value="A">A</Option>
                    <Option key="2" value="AB">AB</Option>
                    <Option key="3" value="B">B</Option>
                    <Option key="4" value="O">O</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="fs-12" type="secondary">Height (cm)</Text>
                <Form.Item 
                  name="height"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Numeric only' },
                    { min: 2, message: 'At least 2 digits' },
                    { max: 3, message: '3 digits only' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="fs-12" type="secondary">Weight (kg)</Text>
                <Form.Item 
                  name="weight"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Numeric only' },
                    { min: 2, message: 'At least 2 digits' },
                    { max: 3, message: '3 digits only' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Marital Status</Text>
                <Form.Item name="marital">
                  <Select 
                    className="full-width br-6"
                    size="small" 
                  >
                    <Option key="1" value="SINGLE">Single</Option>
                    <Option key="2" value="MARRIED">Married</Option>
                    <Option key="3" value="DIVORCE">Divorce</Option>
                    <Option key="4" value="WIDOWED">Widowed</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Religion</Text>
                <Form.Item name="religion">
                  <Select 
                    className="full-width br-6"
                    size="small" 
                  >
                    <Option key="1" value="ISLAM">Islam</Option>
                    <Option key="2" value="CATHOLIC">Catholic</Option>
                    <Option key="3" value="PROTESTAN">Protestan</Option>
                    <Option key="4" value="BUDDHA">Buddha</Option>
                    <Option key="5" value="HINDU">Hindu</Option>
                    <Option key="6" value="OTHERS">Others</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Nationality</Text>
                <Form.Item name="nationality">
                  <Select 
                    className="full-width" 
                    size="small" 
                    showSearch
                    onChange={this.handleNationality}
                  >
                    {
                      getCountry?.data?.map((item) => 
                        <Option key={item.id} value={item.nationality}>{item.nationality}</Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">ID Card Number (KTP)</Text>
                <Form.Item 
                  name="identificationId"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Numeric only' },
                    { len: 16, message: 'KTP number must be 16 digits' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text className="fs-12" type="secondary">ID Card  (KTP) Address</Text>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Form.Item name="country">
                  <Select 
                    className="full-width" 
                    size="small" 
                    showSearch
                    onChange={this.handleCountry}
                    placeholder="Country"
                  >
                    {
                      getCountry?.data?.map((item) => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="province">
                  <Select 
                    className="full-width" 
                    size="small" 
                    showSearch
                    onChange={this.handleProvince}
                    placeholder="Province"
                  >
                    {
                      getProvince?.data?.map((item) => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Form.Item name="city">
                  <Select 
                    className="full-width" 
                    size="small" 
                    showSearch
                    onChange={this.handleCity}
                    placeholder="City"
                  >
                    {
                      getCity?.data?.map((item) => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              {
                selectedCountry === 99 ?
                  <Col span={12}>
                    <Form.Item name="subDistrict">
                      <Select 
                        className="full-width" 
                        size="small" 
                        showSearch
                        onChange={this.handleSubDistrict}
                        placeholder="Sub District (Kecamatan)"
                      >
                        {
                          getSubDistrict?.data?.map((item) => 
                            <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                :
                  <Col span={12}>
                    <Form.Item 
                      name="zipCode"
                      validateFirst
                      rules={[
                        { pattern: /^[0-9]*$/, message: 'Numeric only' },
                        { max: 10, message: '10 digits only' },
                      ]}
                    >
                      <Input size="small" placeholder="Postal Code" />
                    </Form.Item>
                  </Col>
              }
            </Row>
            {
              selectedCountry === 99 ?
                <Row className="mb-min-8" gutter={8}>
                  <Col span={12}>
                    <Form.Item name="village">
                      <Select 
                        className="full-width" 
                        size="small" 
                        showSearch
                        onChange={this.handleVillage}
                        placeholder="Village (Kelurahan)"
                      >
                        {
                          getVillage?.data?.map((item) => 
                            <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="zipCode"
                      validateFirst
                      rules={[
                        { pattern: /^[0-9]*$/, message: 'Numeric only' },
                        { max: 10, message: '10 digits only' },
                      ]}
                    >
                      <Input size="small" placeholder="Postal Code" />
                    </Form.Item>
                  </Col>
                </Row>
              :
                null
            }
            <Row className="mb-min-8">
              <Col span={24}>
                <Form.Item 
                  name="address"
                  validateFirst
                  rules={[
                    { max: 75, message: '75 characters only' },
                  ]}
                >
                  <Input size="small" placeholder="Street Address" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="f-right">
                <Button onClick={this.handleCancel}>Cancel</Button>
                <Button htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexCountry, unmountlistProvinceByCountry, unmountlistCityByProvince, unmountSubDistrictByCity, unmountVillageBySubDistrict } = this.props
    return (unmountIndexCountry(), unmountlistProvinceByCountry(), unmountlistCityByProvince(), unmountSubDistrictByCity(), unmountVillageBySubDistrict())
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getProvince: state.provinceReducer,
  getCity: state.cityReducer,
  getSubDistrict: state.subDistrictReducer,
  getVillage: state.villageReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  unmountIndexCountry: unmountIndexCountry,
  actionGetProvince: listProvinceByCountry,
  unmountlistProvinceByCountry: unmountlistProvinceByCountry,
  actionGetCity: listCityByProvince,
  unmountlistCityByProvince: unmountlistCityByProvince,
  actionGetSubDistrict: subDistrictByCity,
  unmountSubDistrictByCity: unmountSubDistrictByCity,
  actionGetVillage: villageBySubDistrict,
  unmountVillageBySubDistrict: unmountVillageBySubDistrict,
  actionUpdatePersonalInfo: updatePersonalInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)