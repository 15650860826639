import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import InfiniteScroll from 'react-infinite-scroller';
import EmptyLogo from '../../../assets/img/EmptyLogo.png';
import { Row, Col, Form, List, Input, Image, Typography, Space, Button, Collapse, Checkbox, Radio, Divider, Switch, Select, Spin, Modal, Card, Skeleton, Tooltip } from 'antd';
import { SafetyOutlined, EnvironmentOutlined, PlusOutlined, CheckOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import '../Job.css';

const { Option } = Select;
const { Search } = Input;
const { Title, Text } = Typography;
const { Panel } = Collapse;

function GetHistory() {
  const history = useHistory()
  return history;
}

export default function DesJobList(props) {
  const {
    onSearch, 
    callback, 
    getJob,
    handleSort,
    onChangePagination, 
    dataIndustry, 
    handleIndustry, 
    handleDestination,
    dataLocation, 
    handleLocation,
    handleType,
    handleRemote,
    handleLevel,
    handleDate,
    handleClearFilter,
    formRef,
    flagRemote,
    showModalIndustry,
    allJobIndustry,
    onSearchIndustry,
    handleCheckedIndustry,
    visibleIndustry,
    valueIndustry,
    handleOkIndustry,
    handleClearIndustry,
    showModalLocation,
    visibleLocation,
    allJobDestination,
    onSearchLocation,
    handleCheckedLocation,
    handleOkLocation,
    handleClearLocation,
    valueLocation,
    valueSearch,
    handleCancel,
    loadingList,
    hasMore,
    handleInfiniteOnLoad,
    handleFavorite
  } = props

  const history = GetHistory()
  
  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    localStorage.setItem('visible', true);
    history.push('/login-or-register')
  }

  const handleClearForm = () => {
    window.scrollTo(0, 0);
    formRef.current.setFieldsValue({
      search: null,
      industry: null,
      destination: null,
      location: null,
      jobType: null,
      level: null,
      postDate: null
    });
  }

  return (
    <React.Fragment>
      <Form ref={formRef}>
      <Row className="pad-16">
        <Col span={20} offset={2}>
          <Row className="mb-16" gutter={8}>
            <Col md={8} lg={6} xl={5} xxl={4}></Col>
            <Col md={16} lg={18} xl={19} xxl={20}>
              <Form.Item name="search" initialValue={valueSearch}>
                <Search
                  placeholder="Search for a job title, job position"
                  allowClear
                  enterButton="Search"
                  onSearch={onSearch}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="full-width bg-color" gutter={8}>
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Title className="fw-normal drk-color" level={5}>Filter</Title>
            </Col>
            
            <Col md={8} lg={9} xl={9} xxl={10}>
              {
                getJob.loading ?
                  <Skeleton.Input style={{ width: '100%' }} active />
                :
                  <Text className="txt-color fs-13">{`${getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : (getJob?.data?.meta?.page*getJob?.data?.meta?.perpage)-(getJob?.data?.meta?.perpage-1)}-${getJob?.data?.meta?.page === getJob?.data?.meta?.pages || getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : getJob?.data?.meta?.page*getJob?.data?.meta?.perpage} of ${getJob?.data?.meta?.total} job matches`}</Text>
              }
            </Col>
            <Col md={8} lg={9} xl={10} xxl={10}>
              <Space className="f-right">
                <Text className="fs-13 txt-color">Sort by: </Text>
                <Select defaultValue="postedDate" style={{ width: 140 }} size="small" onChange={handleSort}>
                  {/* <Option value="relevance">Relevance</Option> */}
                  <Option key="expiryDate" value="expiryDate">Expiry Date</Option>
                  <Option key="postedDate" value="postedDate">Posted Date</Option>
                </Select>
              </Space>
            </Col>
          </Row>
          <Row className="pt-8 pb-8" gutter={8}>
            <Col md={8} lg={6} xl={5} xxl={4}>
              {
                dataIndustry?.loading || dataLocation?.loading ?
                  <Card className="br-6 box-shadow mb-8" style={{ width: '100%' }}>
                    <Skeleton active />
                  </Card>
                :
                  <Collapse className="br-6 drk-color box-shadow" style={{ backgroundColor: '#fff', border: '1px solid #d9d9d9' }} defaultActiveKey={['1','2','3','4','5','6']} onChange={callback} expandIconPosition="right" ghost>
                    <Panel header="Industry" key="1">
                      <Space className="mb-16" direction="vertical" size={-16}>
                        <Form.Item name="industry" initialValue={valueIndustry}>
                          <Checkbox.Group onChange={(checkedValues) => handleIndustry(checkedValues)}>
                            {
                              dataIndustry?.data?.map((item, i) => 
                                <Checkbox key={i} className="fs-12 txt-color full-width" value={item.id}>{item.title}</Checkbox>
                              )
                            }
                          </Checkbox.Group>
                        </Form.Item>
                        <Text className="add-filter fs-13 c-pointer" onClick={showModalIndustry}><PlusOutlined /> Add an Industry</Text>
                        <Modal
                          visible={visibleIndustry}
                          title="Filter by Industry"
                          onOk={handleOkIndustry}
                          onCancel={handleCancel}
                          footer={[
                            <Button key="back" onClick={handleClearIndustry}>
                              Reset
                            </Button>,
                            <Button key="submit" type="primary" loading={getJob?.loading} onClick={handleOkIndustry}>
                              Submit
                            </Button>,
                          ]}
                        >
                          <Search className="mb-8" enterButton="Search" placeholder="Search Job Industry" allowClear onSearch={onSearchIndustry} style={{ width: '100%' }} />
                          <div className="demo-infinite-container">
                            <Form.Item name="modalIndustry" initialValue={valueIndustry}>
                                {
                                  allJobIndustry.loading ?
                                    <Spin size="small" />
                                  :
                                    <Checkbox.Group className="full-width" onChange={(checkedValues) => handleCheckedIndustry(checkedValues)}>
                                      { 
                                        allJobIndustry?.data?.map((item, i) => 
                                          <Checkbox key={i} className="fs-12 txt-color full-width mb-8" value={item.id}>{item.title}</Checkbox>
                                        )
                                      }
                                    </Checkbox.Group>
                                }
                            </Form.Item>
                          </div>
                        </Modal>
                      </Space>
                    </Panel>
                    {/* <Divider className="drk-color" /> */}
                    <Panel header="Destination" key="2">
                      <Space direction="vertical" size={-16}>
                        <Form.Item className="mb-min-8" name="destination">
                          <Checkbox.Group onChange={(checkedValues) => handleDestination(checkedValues)}>
                            <Checkbox key="INTERNATIONAL" className="fs-12 txt-color full-width" value="INTERNATIONAL">International</Checkbox>
                            <Checkbox key="DOMESTIC" className="fs-12 txt-color full-width" value="DOMESTIC">Domestic</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                      </Space>
                    </Panel>
                    {/* <Divider className="drk-color" /> */}
                    <Panel header="Location" key="3">
                      <Space className="mb-16" direction="vertical" size={-16}>
                        <Form.Item name="location" initialValue={valueLocation}>
                          {
                            dataLocation.loading?
                              <Spin size="small" />
                            :
                                <Checkbox.Group onChange={(checkedValues) => handleLocation(checkedValues)}>
                                  {
                                    dataLocation?.data?.map((item, i) => 
                                      <Checkbox key={i} className="fs-12 txt-color" value={item.id} style={{ width: '100%' }}>{item.name}</Checkbox>
                                    )
                                  }
                                </Checkbox.Group>
                          }
                        </Form.Item>
                        <Text className="add-filter fs-13 c-pointer" onClick={showModalLocation}><PlusOutlined /> Add an Location</Text>
                        <Modal
                          visible={visibleLocation}
                          title="Filter by Location"
                          onOk={handleOkLocation}
                          onCancel={handleCancel}
                          footer={[
                            <Button key="back" onClick={handleClearLocation}>
                              Reset
                            </Button>,
                            <Button key="submit" type="primary" loading={getJob?.loading} onClick={handleOkLocation}>
                              Submit
                            </Button>,
                          ]}
                        >
                          <Search className="mb-8" placeholder="Search Location" enterButton="Search" allowClear onSearch={onSearchLocation} style={{ width: '100%' }} />
                            <div className="demo-infinite-container">
                              <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={() => handleInfiniteOnLoad()}
                                hasMore={!loadingList && hasMore}
                                useWindow={false}
                              >
                                <List
                                  dataSource={allJobDestination?.data ? allJobDestination?.data : []}
                                  renderItem={(item, i) => (
                                    <List.Item key={i}>
                                      <Checkbox checked={valueLocation.includes(item.id)} onChange={() => handleCheckedLocation(item.id)} className="fs-12 txt-color full-width" value={item.id}>{item.name}</Checkbox>
                                    </List.Item>
                                  )}
                                  loading={allJobDestination.loading}
                                >
                                </List>
                              </InfiniteScroll>
                            </div>
                        </Modal>
                      </Space>
                    </Panel>
                    {/* <Divider className="drk-color" /> */}
                    <Panel header="Job Type" key="4">
                      <Space direction="vertical" size={0}>
                        <Form.Item className="mb-min-8" name="jobType">
                          <Checkbox.Group onChange={(checkedValues) => handleType(checkedValues)}>
                            <Checkbox key="FULL_TIME" className="fs-12 txt-color full-width" value="FULL_TIME">Full Time</Checkbox>
                            <Checkbox key="PART_TIME" className="fs-12 txt-color full-width" value="PART_TIME">Part Time</Checkbox>
                            <Checkbox key="FREELANCE" className="fs-12 txt-color full-width" value="FREELANCE">Freelance</Checkbox>
                            <Checkbox key="INTERNSHIP" className="fs-12 txt-color full-width" value="INTERNSHIP">Internship</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                        <Divider style={{ marginBottom: 8, marginTop: 16 }} />
                        <Space className="fs-12 txt-color full-width">
                          Possible to work from home <Switch size="small" checked={flagRemote} onChange={(checked) => handleRemote(checked)} />
                        </Space>
                      </Space>
                    </Panel>
                    {/* <Divider className="drk-color" /> */}
                    <Panel header="Experience Level" key="5">
                      <Space direction="vertical" size={0}>
                        <Form.Item className="mb-min-8" name="level">
                          <Checkbox.Group onChange={(checkedValues) => handleLevel(checkedValues)}>
                            <Checkbox key="ENTRY_LEVEL" className="fs-12 txt-color full-width" value="ENTRY_LEVEL">Entry Level</Checkbox>
                            <Checkbox key="ASSOCIATE" className="fs-12 txt-color full-width" value="ASSOCIATE">Associate</Checkbox>
                            <Checkbox key="MID_SENIOR" className="fs-12 txt-color full-width" value="MID_SENIOR">Mid-Senior Level</Checkbox>
                            <Checkbox key="DIRECTOR" className="fs-12 txt-color full-width" value="DIRECTOR">Director</Checkbox>
                            <Checkbox key="EXECUTIVE" className="fs-12 txt-color full-width" value="EXECUTIVE">Executive</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                      </Space>
                    </Panel>
                    {/* <Divider className="drk-color" /> */}
                    <Panel header="Date Posted" key="6">
                      <Space className="mb-16" direction="vertical" size={0}>
                        <Form.Item className="mb-min-8" name="postDate">
                          <Radio.Group optionType="button" onChange={(e) => handleDate(e)}>
                            <Space className="full-width" direction="vertical">
                              <Radio.Button key="anytime" className="full-width txt-algn-center br-6 drk-color" value="anytime">Any Time</Radio.Button>
                              <Radio.Button key="month" className="full-width txt-algn-center br-6 drk-color" value="month">Past Month</Radio.Button>
                              <Radio.Button key="week" className="full-width txt-algn-center br-6 drk-color" value="week">Past Week</Radio.Button>
                              <Radio.Button key="hours" className="full-width txt-algn-center br-6 drk-color" value="hours">Past 24 hours</Radio.Button>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Space>
                    </Panel>
                  </Collapse>
              }
              <Card className="br-6 box-shadow" bordered={false} style={{ marginTop: 8 }}>
                <Button className="full-width br-6" type="primary" onClick={() => {handleClearFilter(); handleClearForm()}}>Clear Filter</Button>
              </Card>
            </Col>
            <Col md={16} lg={18} xl={19} xxl={20}>
              {
                getJob?.loading ?
                  <Card className="br-6 box-shadow mb-8" style={{ width: '100%' }}>
                    <Skeleton active avatar />
                  </Card>
                :
                  <List
                    className="br-6 box-shadow"
                    size="large"
                    pagination={{
                      style: { textAlign: 'center' },
                      pageSize: getJob?.data?.meta?.perpage,
                      total: getJob?.data?.meta?.total,
                      current: getJob?.data?.meta?.page,
                      onChange: (pageNumber) => onChangePagination(pageNumber)
                    }}
                    bordered
                    dataSource={getJob?.data?.data}
                    renderItem={item => (
                      <List.Item key={item.id}>
                        <Row className="full-width" gutter={16}>
                          <Col className="col-img-job" xxl={3} xl={4} lg={4}>
                            <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                              <Image
                                src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                                preview={false}
                                width={'100%'}
                                height={100}
                                style={{ objectFit: 'contain' }}
                              />
                            </Link>
                          </Col>
                          <Col xxl={14} xl={13} lg={12}>
                            <Space direction="vertical" size={0}>
                              <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.id}` : `/job/detail/${item.id}`}>
                                <Text className="title-job drk-color fs-17">{item.title}</Text>
                              </Link>
                              <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                                <Text className="company-job fs-13 txt-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                              </Link>
                              <Text className="fs-13 txt-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                              <Text className="fs-13 primary-color">
                                {
                                  item.isDisplaySalary ?
                                    item.maxSalary ? 
                                      `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                    : 
                                      `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                                  :
                                    'Salary not disclosed'
                                }
                              </Text>
                              <Text className="txt-color fs-13">{item.agencyName ? `Recruited by ${item.agencyName}` : null}</Text>
                            </Space>
                          </Col>
                          <Col xxl={7} xl={7} lg={8}>
                            <Space className="f-right" direction="vertical" size={16}>
                              <Text className="f-right fs-11 txt-color">{`Posted on ${item.postDate}`}</Text>
                              {
                                Cookie.get('user') ?
                                  <Space className="f-right" size={8}>
                                    <Tooltip title={item.isFavorite ? 'Already added to favorites' : 'Add to favorites' }>
                                      <Button type="primary" className="br-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                    </Tooltip>
                                    {
                                      item.isApply ?
                                        <Text className="f-right fs-14 primary-color"><CheckOutlined /> Applied</Text>
                                      :
                                        <Link to={`/candidate/job/detail/${item.id}`}>
                                          <Button type="primary" className="f-right br-6">Apply</Button>
                                        </Link>
                                    }         
                                  </Space>
                                :
                                  <Button type="primary" className="f-right br-6" onClick={() => onClickApply(`/candidate/job/detail/${item.id}`)}>Apply</Button>
                              }
                            </Space>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
              }
            </Col>
          </Row>
        </Col>
      </Row>
      </Form>
    </React.Fragment>
  )
}