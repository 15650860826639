import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Cookie from 'js-cookie';
// import { Form, Input, Card, Button, Row, Col, Typography, Checkbox, Divider, message } from 'antd'
import { Form, Input, Card, Button, Row, Col, Typography, Checkbox, message } from 'antd'
// import { GoogleOutlined, FacebookFilled } from '@ant-design/icons';
import { setLogin } from '../../redux/actions/auth/authAction';
import './Login.css';

const { Text } = Typography

export class Login extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      username: Cookie.get('remember') ? Cookie.get('email') : null,
      password: Cookie.get('remember') ? Cookie.get('password') : null,
      remember: Cookie.get('remember') ? Cookie.get('remember') : false
    }
  }
  
  componentDidMount() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(this.props.path, [], 'Login');
    }
  }
  
  onFinish =  async (values) => {
    const { remember } = this.state
    const { actionSetLogin } = this.props
    
    if(remember){
      Cookie.set('email', values.username)
      Cookie.set('password', values.password)
      Cookie.set('remember', values.remember)
    }else{
      Cookie.remove('email')
      Cookie.remove('password')
      Cookie.remove('remember')
    }
    return actionSetLogin(values, response => {
      if(response.token){
        if(localStorage.getItem('link')){
          localStorage.removeItem('link')
        }
        if(localStorage.getItem('visible')){
          localStorage.removeItem('visible')
        }
      }
    }, (err) => {
      return message.error(err);
    })
  }
  
  render() {

    const { remember, username, password } = this.state

    // const handleClickSocialLogin = (e, name) => {
    //   e.preventDefault()
    //   let target = name === 'google' ? `${name}-login` : 'kep-login-facebook'
    //   document.getElementsByClassName(target)[0].click()
    // }
    
    const responseGoogle = (response) => {
      console.log(response);
      // if(response !== undefined) handleSocialMediaLogin('google', response.tokenId, response.profileObj.email, response.profileObj.name)
    }
    
    const responseFacebook = (response) => {
      console.log(response);
      // if(response !== undefined) handleSocialMediaLogin('facebook', response.accessToken, response.email, response.name)
    }

    const onChange = (e) => {
      this.setState({
        remember: e.target.checked
      })
    }
    
    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Login</title>
        </Helmet>
        <Row className="dis-flex" align="middle" justify="center">
          <Col>
            <Card className="card-login" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                name="normal_login" 
                layout="vertical" 
                onFinish={this.onFinish}
              >
                <Row gutter={16}>
                  <Col className="fs-28" xs={24} sm={24} md={24}>
                    <Text type="secondary">Login</Text>
                  </Col>
                  <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                    <Text type="secondary">Use Jobshub to create a meaningful journey in your life</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Text type="secondary">Email</Text>
                    <Form.Item 
                      className="mb-16"
                      name="username"
                      initialValue={username}
                      rules={[
                        { required: true, message: 'Email is required!' },
                        { type: 'email', message: 'invalid email!' }
                      ]}
                    >
                      <Input 
                        type="email"
                        placeholder="Type your email"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Text type="secondary">Password</Text>
                    <Form.Item 
                      className="mb-8" 
                      name="password"
                      initialValue={password}
                      rules={[
                        { required: true, message: 'Password is required!' },
                      ]}
                    >
                      <Input.Password
                        placeholder="Type your password"
                      />
                    </Form.Item>
                  </Col>
                  <Col className="mb-8" xs={24} sm={24} md={24}>
                    <Form.Item name="remember" noStyle>
                      <Checkbox className="txt-color fs-11" onChange={onChange} checked={remember}>Remember me</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <Button type="primary" htmlType="submit" block>
                        Login
                      </Button>
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={24}>
                    <Divider className="fs-11 txt-color mb-16" plain>or</Divider>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <Button
                        className="button-google txt-color" 
                        block 
                        onClick={ (e) => handleClickSocialLogin(e, "google") }
                      >
                        <GoogleOutlined /> Login with Google
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <Button 
                        className="button-facebook txt-color" 
                        block 
                        onClick={ (e) => handleClickSocialLogin(e, "facebook") }
                      >
                        <FacebookFilled /> Login with Facebook
                      </Button>
                    </Form.Item>
                  </Col> */}
                </Row>
                <Row>
                  <Col className="fs-13" xs={12} sm={12} md={12}>
                    <Text type="secondary">
                    Not registered yet? <Link className="a-color" to="/register">Create a new one</Link>
                    </Text>
                  </Col>
                  <Col className="fs-13" xs={12} sm={12} md={12}>
                    <Link className="a-color f-right" to="/forgot-password">Forgot password?</Link>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row className="dis-none">
          <Col span={12}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Sign In With Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
              className="google-login social-button"
            />
          </Col>
          <Col span={12}>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={responseFacebook}
              className="facebook-login social-button"
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionSetLogin: setLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)