import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Menu, Card, Skeleton, Avatar, Space, Pagination, Empty, Button, Modal, Form, Input, message } from 'antd'
import { menuApplications, unmountMenuApplications, listApplications, unmountListApplications, detailInterview, unmountInterview, confirmInterview, cancelInterview, rescheduleInterview } from '../../../../redux/actions/applications/applicationsAction'
import { listNotification, countNotification } from '../../../../redux/actions/header/headerAction'
import { SafetyOutlined, EnvironmentOutlined } from '@ant-design/icons'
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';
import moment from 'moment';

const { Text } = Typography;
const { TextArea } = Input;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
        page: 1,
        perpage: 10,
        phaseId: 2,
        jobStatus: 'ACTIVE'
      },
      activeKey: "2",
      visible: false,
      isReschedule: false,
      isReject: false,
      submitLoading: false,
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionMenuApplications, actionListApplications } = this.props
    
    return (actionMenuApplications(), actionListApplications(params))
  }

  handleMenu = e => {
    const { history } = this.props

    if(e.key === "0"){
      history.push('applied')
    }
    if(e.key === "1"){
      history.push('qualified')
    }
    if(e.key === "2"){
      history.push('interview')
    }
    if(e.key === "3"){
      history.push('offered')
    }
    if(e.key === "4"){
      history.push('hired')
    }
    if(e.key === "5"){
      history.push('rejected')
    }
  }

  handlePagination = (pageNumber) => {
    const { params: { perpage, phaseId, jobStatus } } = this.state
    const params = {
      page: pageNumber,
      perpage: perpage,
      phaseId: phaseId,
      jobStatus: jobStatus
    }
    this.setState({ params })
    window.scrollTo(0, 0);
    const { actionListApplications } = this.props
    return actionListApplications(params)
  }

  showModal = (interviewId) => {
    const { actionDetailInterview } = this.props

    const params = {
      interviewId
    }
    this.setState({
      visible: true
    })

    return actionDetailInterview(params)
  }

  handleCancel = () => {
    this.setState({
      visible: false
    })
  }

  handleShowReschedule = (value) => {
    this.setState({
      isReschedule: value
    })
  }

  handleShowReject = (value) => {
    this.setState({
      isReject: value
    })
  }

  handleAttend = (interviewId) => {
    const { params } = this.state
    const { actionConfirmInterview, actionListApplications, actionListNotification, actionCountNotification } = this.props
    
    const values = {
      interviewId
    }

    const paramsNotification ={
      page: 1,
      perpage: 100
    }

    return this.setState({ submitLoading: true }, () => {
      return actionConfirmInterview(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return (actionListApplications(params), actionListNotification(paramsNotification), actionCountNotification())
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onFinishReschedule = (values, interviewId) => {
    const { params } = this.state
    const { actionRescheduleInterview, actionListApplications, actionListNotification, actionCountNotification } = this.props
    
    values.id = interviewId

    const paramsNotification ={
      page: 1,
      perpage: 100
    }

    return this.setState({ submitLoading: true }, () => {
      return actionRescheduleInterview(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return (actionListApplications(params), actionListNotification(paramsNotification), actionCountNotification())
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onFinishCancel = (values, interviewId) => {
    const { params } = this.state
    const { actionCancelInterview, actionListApplications, actionListNotification, actionCountNotification } = this.props
    
    values.id = interviewId

    const paramsNotification ={
      page: 1,
      perpage: 100
    }

    return this.setState({ submitLoading: true }, () => {
      return actionCancelInterview(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return (actionListApplications(params), actionListNotification(paramsNotification), actionCountNotification())
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { activeKey, visible, isReschedule, isReject, submitLoading } = this.state
    const { getMenuApplications, getListApplications, getDetailInterview } = this.props
    
    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Applications - Interview</title>
        </Helmet>
        <Row className="pad-16">
          <Col span={20} offset={2}>
            <Row className="mb-32">
              <Col>
                <Text className="drk-color fs-20">Applications</Text>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col md={24} lg={4}>
                {
                  getMenuApplications.loading ?
                    <Skeleton active />
                  :
                    <Menu onClick={this.handleMenu} selectedKeys={activeKey} className="drk-color" mode="vertical" style={{ backgroundColor: 'transparent' }}>
                      <Menu.Item className="m-item" key="0">Applied</Menu.Item>
                      {
                        getMenuApplications?.data?.map((item, i) => 
                          <Menu.Item className="m-item" key={i+1}>{item.name}</Menu.Item>
                        )
                      }
                      {/* <Divider style={{ margin: 0 }} /> */}
                      <Menu.Item className="m-item" key="5">Rejected</Menu.Item>
                    </Menu>
                }
              </Col>
              <Col md={24} lg={20}>
                <Row className="mb-16">
                  <Col span={24}>
                    {
                      getListApplications.loading ?
                        <Card className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                          <Skeleton active avatar />
                        </Card>
                      :
                        getListApplications?.data?.length > 0 ?
                          getListApplications?.data?.map((item, i) => 
                            <Card key={i} className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                              <Row className="full-width" gutter={16}>
                                <Col className="col-img-job" xxl={3} xl={4} lg={4}>
                                  <Avatar src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo} shape="square" size={{ sm: 80, md: 80, lg: 56, xl: 80, xxl: 90 }} style={{ height: 'auto', display: "block", margin: 0 }} />
                                </Col>
                                <Col xxl={14} xl={13} lg={12}>
                                  <Space direction="vertical" size={0}>
                                    <Link to={`/candidate/job/detail/${item.jobId}`}>
                                      <Text className="a-color fs-17">{item.title}</Text>
                                    </Link>
                                    <Text className="fs-13 txt-color">{item.companyName} <SafetyOutlined className="primary-color" /></Text>
                                    <Text className="fs-13 txt-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                                    {
                                      item.interviewStatus === 'CONFIRM' ?
                                        <Text className="fs-13 primary-color fst-i">{item?.interviewStatusNote}</Text>
                                      : item.interviewStatus === 'CANCEL' ?
                                        <Text className="fs-13 dgr-color fst-i">{item?.rejectStatus}</Text>
                                      : item.interviewStatus === 'RESCHEDULE_CANDIDATE' ?
                                        <Text className="fs-13 drk-color fst-i">{item?.interviewStatusNote}</Text>
                                      : item.interviewStatus === 'RESCHEDULE_ADMIN' ?
                                        <Button className="mt-8 fs-12" type="primary" size="small" onClick={() => this.showModal(item.interviewId)}>
                                          Interview Schedule Confirmation
                                        </Button>
                                      :
                                        <Button className="mt-8 fs-12" type="primary" size="small" onClick={() => this.showModal(item.interviewId)}>
                                          Interview Schedule Confirmation
                                        </Button>
                                    }
                                    <Modal title="Interview Schedule Confirmation" width={580} visible={visible} onCancel={this.handleCancel} footer={false} destroyOnClose>
                                      {
                                        getDetailInterview.loading ?
                                          <Skeleton active />
                                        :
                                          <React.Fragment>
                                            <Space direction="vertical">
                                              <Text className="drk-color fs-14">Thank you for your interest in the position of {getDetailInterview?.data?.jobPositionName} - {getDetailInterview?.data?.companyName}.</Text>
                                              <Text className="drk-color fs-14">We would like to interview you for the above position. The interview information is as follows:</Text>
                                              <Row gutter={16}>
                                                <Col span={4}>
                                                  <Text>Date</Text>
                                                </Col>
                                                <Col span={20}>
                                                  <Text strong>: {moment(getDetailInterview?.data?.interviewDate, 'DD/MM/YYYY').format('DD MMM YYYY')}</Text>
                                                </Col>
                                                <Col span={4}>
                                                  <Text>Time</Text>
                                                </Col>
                                                <Col span={20}>
                                                  <Text strong>: {getDetailInterview?.data?.interviewTime}</Text>
                                                </Col>
                                                {
                                                  getDetailInterview?.data?.isMethod &&
                                                  <React.Fragment>
                                                    <Col span={4}>
                                                      <Text>Location</Text>
                                                    </Col>
                                                    <Col span={20}>
                                                      <Text strong>: {getDetailInterview?.data?.location}</Text>
                                                    </Col>
                                                  </React.Fragment>
                                                }
                                                {
                                                  !getDetailInterview?.data?.isMethod &&
                                                  <React.Fragment>
                                                    <Col span={4}>
                                                      <Text>Virtual Link</Text>
                                                    </Col>
                                                    <Col span={20}>
                                                      <Text strong>: {getDetailInterview?.data?.virtualLink}</Text>
                                                    </Col>
                                                  </React.Fragment>
                                                }
                                                <Col span={4}>
                                                  <Text>Notes</Text>
                                                </Col>
                                                <Col span={20}>
                                                  <Text strong>: {getDetailInterview.data.note ? getDetailInterview.data.note : '-'}</Text>
                                                </Col>
                                              </Row>
                                              {
                                                !isReschedule?
                                                  <Space className="mb-8">
                                                    <Button className="fs-12 br-3" type="primary" size="small" disabled={isReject} onClick={() => this.handleAttend(getDetailInterview?.data?.id)}>I will attend</Button>
                                                    <Button className="fs-12 br-3" type="primary" size="small" disabled={isReject} onClick={() => this.handleShowReschedule(getDetailInterview?.data?.id)} style={!isReject ? { background: '#0085ff', color: '#fffff', borderColor: '#0085ff' } : null}>Reschedule</Button>
                                                  </Space>
                                                :
                                                  null
                                              }
                                            </Space>
                                            {
                                              !isReschedule && !isReject ?
                                                <Text className="drk-color fs-14">If you may find that the position no longer seems like a good fit and want to decline the interview invitation, <Text className="a-color c-pointer" onClick={() => this.handleShowReject(true)}>click here</Text></Text>
                                              : isReschedule ? 
                                                <Form 
                                                  ref={this.formRef}
                                                  onFinish={(values) => this.onFinishReschedule(values, getDetailInterview?.data?.id)}
                                                >
                                                  <Space size={4}>
                                                    <Text className="fs-12" type="danger">*</Text>
                                                    <Text className="fs-12" type="secondary">Reschedule reason:</Text>
                                                  </Space>
                                                  <Form.Item 
                                                    name="rescheduleReason"
                                                    rules={[
                                                      { required: true, message: 'Reschedule reason is mandatory' }
                                                    ]}
                                                  >
                                                    <TextArea rows={3} />
                                                  </Form.Item>
                                                  <Form.Item>
                                                    <Space className="f-right">
                                                      <Button className="fs-12 br-3" size="small" type="default" onClick={() => this.handleShowReschedule(false)}>Cancel</Button>
                                                      <Button className="fs-12 br-3" size="small" htmlType="submit" type="primary" loading={submitLoading}>Reschedule</Button>
                                                    </Space>
                                                  </Form.Item>
                                                </Form>
                                              : isReject ? 
                                                <Form 
                                                  ref={this.formRef}
                                                  onFinish={(values) => this.onFinishCancel(values, getDetailInterview?.data?.id)}
                                                >
                                                  <Space size={4}>
                                                    <Text className="fs-12" type="danger">*</Text>
                                                    <Text className="fs-12" type="secondary">Decline reason:</Text>
                                                  </Space>
                                                  <Form.Item 
                                                    name="candidateNote"
                                                    rules={[
                                                      { required: true, message: 'Decline reason is mandatory' }
                                                    ]}
                                                  >
                                                    <TextArea rows={3} />
                                                  </Form.Item>
                                                  <Form.Item>
                                                    <Space className="f-right">
                                                      <Button className="fs-12 br-3" size="small" type="default" onClick={() => this.handleShowReject(false)}>Cancel</Button>
                                                      <Button className="fs-12 br-3" size="small" htmlType="submit" type="danger" loading={submitLoading}>Decline Invitation</Button>
                                                    </Space>
                                                  </Form.Item>
                                                </Form>
                                              :
                                                null
                                            }
                                          </React.Fragment>
                                      }
                                    </Modal>
                                  </Space>
                                </Col>
                                <Col xxl={7} xl={7} lg={8}>
                                  <Space direction="vertical" size={16} className="f-right">
                                    <Text className="f-right fs-11 txt-color">{`Applied ${item.applyDate}`}</Text>
                                  </Space>
                                </Col>
                              </Row>
                          </Card>
                      )
                        :
                          <Card className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                            <Empty />
                          </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="txt-algn-center" span={24}>
                    <Pagination onChange={this.handlePagination} current={getListApplications?.meta?.page} pageSize={getListApplications?.meta?.perpage || 10} total={getListApplications?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountMenuApplications, unmountListApplications, unmountInterview } = this.props
    return (unmountMenuApplications(), unmountListApplications(), unmountInterview())
  }
}

const mapStateToProps = (state) => ({
  getMenuApplications: state.menuApplicationsReducer,
  getListApplications: state.listApplicationsReducer,
  getDetailInterview: state.interviewReducer
})

const mapDispatchToProps = {
  actionMenuApplications: menuApplications,
  unmountMenuApplications: unmountMenuApplications,
  actionListApplications: listApplications,
  unmountListApplications: unmountListApplications,
  actionDetailInterview: detailInterview,
  unmountInterview: unmountInterview,
  actionConfirmInterview: confirmInterview,
  actionCancelInterview: cancelInterview,
  actionRescheduleInterview: rescheduleInterview,
  actionListNotification: listNotification,
  actionCountNotification: countNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(index)