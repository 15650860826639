import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Space, Image, Grid } from 'antd';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';
import MapsIcon from '../../../assets/img/MapsIcon.png';
import InstagramIcon from '../../../assets/img/InstagramIcon.png';
import FacebookIcon from '../../../assets/img/FacebookIcon.png';
import { MailOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function FooterPrivate() {
  const { pathname } = window.location
  const { lg } = GetBreakPoint()

  return (
    <React.Fragment>
      {
        pathname === '/job-preferences/industries' || pathname === '/job-preferences/position' || pathname === '/job-preferences/destination' ?
          null
        :
          <React.Fragment>
            <Row style={{ height: '8%', padding: '80px 0 100px', background: '#e6e6e6' }}>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
                <Row gutter={8}>
                  <Col className={!lg ? 'txt-algn-center' : null} xs={24} lg={9}>
                    <Space direction="vertical">
                      <Link to="/">
                        <Image
                          width={140}
                          src={JobshubLogo}
                          preview={false}
                        />
                      </Link>
                      <Space className="mb-16" direction="vertical" size={0}>
                        <Text className="drk-color">Gedung Perkantoran Binawan Lobby 3, Lantai 2, Jl. Raya Kalibata No. 25-30, Cawang, Kec. Kramat Jati, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13630</Text>
                      </Space>
                      <Space className="mb-16" size={16}>
                        <a href="https://goo.gl/maps/Gb5UThs8NV3PY6C98" target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={MapsIcon}
                            preview={false}
                          />
                        </a>
                        <a href="https://www.instagram.com/jobshub_id" target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={InstagramIcon}
                            preview={false}
                          />
                        </a>
                        <a href="https://www.facebook.com/Jobshubdotid/" target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={FacebookIcon}
                            preview={false}
                          />
                        </a>
                      </Space>
                    </Space>
                  </Col>

                  <Col xs={12} lg={5}>
                    <Space direction="vertical">
                      <Text className="fs-16 primary-color">Product & Services</Text>
                      <Link to="/job">
                        <Text className="drk-color">Find a job</Text>
                      </Link>

                    </Space>
                  </Col>

                  <Col xs={12} lg={5}>
                    <Space direction="vertical">
                      <Text className="fs-16 primary-color">Other</Text>
                      <Link className="drk-color" to="/candidate/terms-and-conditions" target="_blank">Terms and Conditions</Link>
                      <Link className="drk-color" to="/candidate/privacy-policy" target="_blank">Privacy Policy</Link>
                      {/* <Text className="drk-color">FAQ</Text> */}
                    </Space>
                  </Col>

                  <Col xs={12} lg={5}>
                    <Space direction="vertical">
                      <Text className="fs-16 primary-color">Information</Text>
                      <Space direction="vertical" size={-4}>
                        <Text className="drk-color">For technical support issues,</Text>
                        <Text className="drk-color">e-mail us at:</Text>
                      </Space>
                      <a href="mailto:support@jobshub.id?subject=What is your problem?"><MailOutlined /> support@jobshub.id</a>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ height: 40 }}>
              <Col span={6} style={{ background: '#4173b9' }}></Col>
              <Col span={12} style={{ background: '#32ab6d', textAlign: 'center', paddingTop: 10 }}>
                <Text style={{ color: '#ffffff' }}>© 2021 Jobshub. All Rights Reserved.</Text>
              </Col>
              <Col span={6} style={{ background: '#32ab6d' }}></Col>
            </Row>
          </React.Fragment>
      }
    </React.Fragment>
  )
}
