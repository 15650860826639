import React, { Component } from 'react';
import * as Containers from '../containers';
import { connect } from 'react-redux';
import { Result } from 'antd';
import { Loading } from '../components';
import { convertMenu } from '../utils/menuSelection';
import { PrivateRoute, AuthRoute } from './route'
import { checkAuth } from '../redux/actions/auth/authAction';
import { BrowserRouter as Router, Switch } from "react-router-dom";

export class AppRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _menu: null,
      isDisconnected: false
    }
  }
  componentDidMount() {
    const { actionCheckAuth } = this.props;
    
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    return actionCheckAuth()
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    const { permission, loading, userType } = nextProps
    !loading && convertMenu(permission, userType, (response) => {
      this.setState({ _menu: response })
    })
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('/google.com', {
            mode: 'no-cors',
            })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing)
            });
          }).catch(() => this.setState({ isDisconnected: true }) )
        }, 2000);
      return;
    }
    return this.setState({ isDisconnected: true });
  }

  render() {
    const { loading } = this.props;
    const { _menu, isDisconnected } = this.state;
    
    if(loading){ 
      return <Loading />
    }

    return (
      <Router>
        <Switch>
          <AuthRoute exact path='/' component={Containers.Home} passProps={this.props} private={false} />
          <AuthRoute exact path='/login' component={Containers.Login} passProps={this.props} private={false} />
          <AuthRoute exact path='/register' component={Containers.Register} passProps={this.props} private={false} />
          <AuthRoute exact path='/register/confirm-email' component={Containers.ConfirmEmail} passProps={this.props} private={false} />
          <AuthRoute exact path='/job' component={Containers.Job} passProps={this.props} private={false} />
          <AuthRoute exact path='/job/detail/:id' component={Containers.JobDetail} passProps={this.props} private={false} />
          <AuthRoute exact path='/company/:id/:companyType' component={Containers.Company} passProps={this.props} private={false} />
          <AuthRoute exact path='/forgot-password' component={Containers.ForgotPassword} passProps={this.props} private={false} />
          <AuthRoute exact path='/forgot-password/verify' component={Containers.Verify} passProps={this.props} private={false} />
          <AuthRoute exact path='/forgot-password/change-password' component={Containers.ChangePassword} passProps={this.props} private={false} />
          <AuthRoute exact path='/privacy-policy' component={Containers.PrivacyPolicy} passProps={this.props} private={false} />
          <AuthRoute exact path='/terms-and-conditions' component={Containers.TermsAndConditions} passProps={this.props} private={false} />
          <AuthRoute exact path='/login-or-register' component={Containers.Bridging} passProps={this.props} private={false} />
          { 
            isDisconnected && (
              <React.Fragment>
                <Result status="500" title="Lost Connection" subTitle="Please check your internet connection" />
              </React.Fragment>
            )
          } 
          {
            _menu?.map((response, i) => {
              return <PrivateRoute {...response} key={i} exact component={Containers[response.component]} passProps={this.props} />
            })
          }
        </Switch>
      </Router>
    )
  }
}
const mapStateToProps = (state) => ({
  loading     : state.authReducer.loading,
  authed      : state.authReducer.authed,
  permission  : state.authReducer.permission,
  userType    : state.authReducer.userType,
  isRegister  : state.authReducer.isRegister
})
const mapDispatchToProps = {
  actionCheckAuth: checkAuth
}
export default connect(mapStateToProps, mapDispatchToProps)(AppRoute)