import React from 'react';
import { Helmet } from "react-helmet";
import { Grid } from 'antd';
import './Job.css';
import DesJobList from './Desktop';
import MobJobList from './Mobile';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function JobList(initialProps) {
  const { lg } = GetBreakPoint()
  
  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - Job Opening</title>
      </Helmet>
      {
        lg ?
          <DesJobList {...initialProps} />
        :
          <MobJobList {...initialProps} />
      }
    </React.Fragment>
  )
}