import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Row, Col, Typography, Space, Form, Input, DatePicker, Select, Skeleton } from 'antd';
import { indexCountry, unmountIndexCountry } from '../../../../../redux/actions/master/country/countryAction';
import { listProvinceByCountry, unmountlistProvinceByCountry } from '../../../../../redux/actions/master/province/provinceAction';
import { listCityByProvince, unmountlistCityByProvince } from '../../../../../redux/actions/master/city/cityAction';
import { subDistrictByCity, unmountSubDistrictByCity } from '../../../../../redux/actions/master/subDistrict/subDistrictAction';
import { villageBySubDistrict, unmountVillageBySubDistrict } from '../../../../../redux/actions/master/village/villageAction';

const { Text } = Typography;
const { Option } = Select;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedCountry: null
    }
  }
  async componentDidMount() {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage, getPersonalInfo: { data } } = this.props

    await this.setState({
      selectedCountry: data?.countryId
    })

    if(data?.subDistrictId){
      return (actionGetCountry(), actionGetProvince(data?.countryId), actionGetCity(data?.provinceId), actionGetSubDistrict(data?.cityId), actionGetVillage(data?.subDistrictId))
    }else if(data?.cityId){
      return (actionGetCountry(), actionGetProvince(data?.countryId), actionGetCity(data?.provinceId), actionGetSubDistrict(data?.cityId))
    }else if(data?.provinceId){
      return (actionGetCountry(), actionGetProvince(data?.countryId), actionGetCity(data?.provinceId))
    }else if(data?.countryId){
      return (actionGetCountry(), actionGetProvince(data?.countryId))
    }

    return actionGetCountry()
  }
  render() {
    const { selectedCountry } = this.state
    const { 
      getCountry, 
      getProvince, 
      getCity, 
      getSubDistrict, 
      getVillage, 
      getPersonalInfo,
      onChangeDate,
      handleNationality,
      handleCountry,
      handleProvince,
      handleCity,
      handleSubDistrict,
      handleVillage,
      valuesPersonal
    } = this.props
    
    const _handleCountry = (value, option) => {
      const optionSelected = Number(option.key)
      this.setState({
        selectedCountry: optionSelected,
      })
      const { actionGetProvince } = this.props
      return actionGetProvince(option.key)
    }

    const _handleProvince= (value, option) => {
      const { actionGetCity } = this.props
      return actionGetCity(option.key)
    }

    const _handleCity= (value, option) => {
      const { actionGetSubDistrict } = this.props
      return actionGetSubDistrict(option.key)
    }

    const _handleSubDistrict= (value, option) => {
      const { actionGetVillage } = this.props
      return actionGetVillage(option.key)
    }

    if(getCountry.loading || getPersonalInfo.loading){
      return <Skeleton active />
    }
    
    return (
      <React.Fragment>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">Date of Birth</Text>
            </Space>
            <Form.Item 
              name="birthdate"
              rules={[
                { required: true, message: 'Date of Birth is required!' },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal?.birthdate ?
                  moment(valuesPersonal.birthdate, 'DD/MM/YYYY')
                : getPersonalInfo.data.birthdate ?
                  moment(getPersonalInfo.data.birthdate, 'DD/MM/YYYY')
                :
                  ""
              }
            >
              <DatePicker className="full-width" size="small" format="DD/MM/YYYY" onChange={onChangeDate} defaultPickerValue={getPersonalInfo.data.birthdate ? moment(getPersonalInfo.data.birthdate) : moment('2000-01-01')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">Gender</Text>
            </Space>
            <Form.Item 
              name="gender"
              rules={[
                { required: true, message: 'Gender is required!' },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.gender ?
                  valuesPersonal.gender
                : getPersonalInfo.data.gender ?
                  getPersonalInfo.data.gender
                :
                  ""
              }
            >
              <Select 
                className="full-width br-6"
                size="small" 
              >
                <Option key="1" value="MALE">Male</Option>
                <Option key="2" value="FEMALE">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Text className="fs-12" type="secondary">Place of Birth</Text>
            <Form.Item 
              name="placeOfBirth"
              validateFirst
              rules={[
                { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                { max: 50, message: '50 characters only' },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.placeOfBirth ?
                  valuesPersonal.placeOfBirth
                : getPersonalInfo.data.placeOfBirth ?
                  getPersonalInfo.data.placeOfBirth
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="fs-12" type="secondary">Nationality</Text>
            <Form.Item 
              name="nationality"
              initialValue={
                valuesPersonal && valuesPersonal.nationality ?
                  valuesPersonal.nationality
                : getPersonalInfo.data.nationality ?
                  getPersonalInfo.data.nationality
                :
                  'Indonesian'
              }
            >
              <Select 
                className="full-width" 
                size="small" 
                showSearch
                onChange={handleNationality}
              >
                {
                  getCountry?.data?.map(item => 
                    <Option key={item.id} value={item.nationality}>{item.nationality}</Option>
                  )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
          
        <Row className="mb-min-8" gutter={8}>
          <Col span={6}>
            <Text className="fs-12" type="secondary">Marital Status</Text>
            <Form.Item 
              name="marital"
              initialValue={
                valuesPersonal && valuesPersonal.marital ?
                  valuesPersonal.marital
                : getPersonalInfo.data.marital ?
                  getPersonalInfo.data.marital
                :
                  ""
              }
            >
              <Select 
                className="full-width br-6"
                size="small" 
              >
                <Option key="1" value="SINGLE">Single</Option>
                <Option key="2" value="MARRIED">Married</Option>
                <Option key="3" value="DIVORCE">Divorce</Option>
                <Option key="4" value="WIDOWED">Widowed</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Text className="fs-12" type="secondary">Blood Type</Text>
            <Form.Item 
              name="bloodType"
              initialValue={
                valuesPersonal && valuesPersonal.bloodType ?
                  valuesPersonal.bloodType
                : getPersonalInfo.data.bloodType ?
                  getPersonalInfo.data.bloodType
                :
                  ""
              }
            >
              <Select 
                className="full-width br-6"
                size="small" 
              >
                <Option key="1" value="A">A</Option>
                <Option key="2" value="AB">AB</Option>
                <Option key="3" value="B">B</Option>
                <Option key="4" value="O">O</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Text className="fs-12" type="secondary">Height (cm)</Text>
            <Form.Item 
              name="height"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: 'Numeric only' },
                { min: 2, message: 'At least 2 digits' },
                { max: 3, message: '3 digits only' },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.height ?
                  valuesPersonal.height
                : getPersonalInfo.data.height ?
                  getPersonalInfo.data.height.toString()
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Text className="fs-12" type="secondary">Weight (kg)</Text>
            <Form.Item 
              name="weight"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: 'Numeric only' },
                { min: 2, message: 'At least 2 digits' },
                { max: 3, message: '3 digits only' },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.weight ?
                  valuesPersonal.weight
                : getPersonalInfo.data.weight ?
                  getPersonalInfo.data.weight.toString()
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Text className="fs-12" type="secondary">ID Card Number (KTP)</Text>
            <Form.Item 
              name="identificationId"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: 'Numeric only' },
                { len: 16, message: 'KTP number must be 16 digits' },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.identificationId ?
                  valuesPersonal.identificationId
                : getPersonalInfo.data.identificationId ?
                  getPersonalInfo.data.identificationId
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="fs-12" type="secondary">Religion</Text>
            <Form.Item 
              name="religion"
              initialValue={
                valuesPersonal && valuesPersonal.religion ?
                  valuesPersonal.religion
                : getPersonalInfo.data.religion ?
                  getPersonalInfo.data.religion
                :
                  ""
              }
            >
              <Select 
                className="full-width br-6"
                size="small" 
              >
                <Option key="1" value="ISLAM">Islam</Option>
                <Option key="2" value="CATHOLIC">Catholic</Option>
                <Option key="3" value="PROTESTAN">Protestan</Option>
                <Option key="4" value="BUDDHA">Buddha</Option>
                <Option key="5" value="HINDU">Hindu</Option>
                <Option key="6" value="OTHERS">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="fs-12" type="secondary">ID Card  (KTP) Address</Text>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="country"
              initialValue={
                valuesPersonal && valuesPersonal.country ?
                  valuesPersonal.country
                : getPersonalInfo.data.countryName ?
                  getPersonalInfo.data.countryName
                :
                  null
              }
            >
              <Select 
                className="full-width" 
                size="small" 
                showSearch
                onChange={(value, option) => {handleCountry(value, option); _handleCountry(value, option)}}
                placeholder="Country"
              >
                {
                  getCountry?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="province"
              initialValue={
                valuesPersonal && valuesPersonal.provinve ?
                  valuesPersonal.provinve
                : getPersonalInfo.data.provinceName ?
                  getPersonalInfo.data.provinceName
                :
                  null
              }
            >
              <Select 
                className="full-width" 
                size="small" 
                showSearch
                onChange={(value, option) => {handleProvince(value, option); _handleProvince(value, option)}}
                placeholder="Province"
              >
                {
                  getProvince?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="city"
              initialValue={
                valuesPersonal && valuesPersonal.city ?
                  valuesPersonal.city
                : getPersonalInfo.data.cityName ?
                  getPersonalInfo.data.cityName
                :
                  null
              }
            >
              <Select 
                className="full-width" 
                size="small" 
                showSearch
                onChange={(value, option) => {handleCity(value, option); _handleCity(value, option)}}
                placeholder="City"
              >
                {
                  getCity?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
          {
            selectedCountry === 99 ?
              <Col span={12}>
                <Form.Item 
                  name="subDistrict"
                  initialValue={
                    valuesPersonal && valuesPersonal.subDistrict ?
                      valuesPersonal.subDistrict
                    : getPersonalInfo.data.subDistrictName ?
                      getPersonalInfo.data.subDistrictName
                    :
                      null
                  }
                >
                  <Select 
                    className="full-width" 
                    size="small" 
                    showSearch
                    onChange={(value, option) => {handleSubDistrict(value, option); _handleSubDistrict(value, option)}}
                    placeholder="Sub District (Kecamatan)"
                  >
                    {
                      getSubDistrict?.data?.map(item => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
            :
              <Col span={12}>
                <Form.Item 
                  name="zipCode"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Numeric only' },
                    { max: 10, message: '10 digits only' },
                  ]}
                  initialValue={
                    valuesPersonal && valuesPersonal.zipCode ?
                      valuesPersonal.zipCode
                    : getPersonalInfo.data.zipCode ?
                      getPersonalInfo.data.zipCode
                    :
                      null
                  }
                >
                  <Input size="small" placeholder="Postal Code" />
                </Form.Item>
              </Col>
          }
        </Row>
        {
          selectedCountry === 99 ?
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Form.Item 
                  name="village"
                  initialValue={
                    valuesPersonal && valuesPersonal.village ?
                      valuesPersonal.village
                    : getPersonalInfo.data.villageName ?
                      getPersonalInfo.data.villageName
                    :
                      null
                  }
                >
                  <Select 
                    className="full-width" 
                    size="small" 
                    showSearch
                    onChange={handleVillage}
                    placeholder="Village (Kelurahan)"
                  >
                    {
                      getVillage?.data?.map(item => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name="zipCode"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Numeric only' },
                    { max: 10, message: '10 digits only' },
                  ]}
                  initialValue={
                    valuesPersonal && valuesPersonal.zipCode ?
                      valuesPersonal.zipCode
                    : getPersonalInfo.data.zipCode ?
                      getPersonalInfo.data.zipCode
                    :
                      null
                  }
                >
                  <Input size="small" placeholder="Postal Code" />
                </Form.Item>
              </Col>
            </Row>
          :
            null
        }
        <Row className="mb-min-8">
          <Col span={24}>
            <Form.Item 
              name="address"
              validateFirst
              rules={[
                { max: 75, message: '75 characters only' },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.address ?
                  valuesPersonal.address
                : getPersonalInfo.data.address ?
                  getPersonalInfo.data.address
                :
                  null
              }
            >
              <Input size="small" placeholder="Street Address" />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  // componentWillUnmount(){
  //   const { unmountIndexCountry, unmountlistProvinceByCountry, unmountlistCityByProvince, unmountSubDistrictByCity, unmountVillageBySubDistrict } = this.props
  //   return (unmountIndexCountry(), unmountlistProvinceByCountry(), unmountlistCityByProvince(), unmountSubDistrictByCity(), unmountVillageBySubDistrict())
  // }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getProvince: state.provinceReducer,
  getCity: state.cityReducer,
  getSubDistrict: state.subDistrictReducer,
  getVillage: state.villageReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  unmountIndexCountry: unmountIndexCountry,
  actionGetProvince: listProvinceByCountry,
  unmountlistProvinceByCountry: unmountlistProvinceByCountry,
  actionGetCity: listCityByProvince,
  unmountlistCityByProvince: unmountlistCityByProvince,
  actionGetSubDistrict: subDistrictByCity,
  unmountSubDistrictByCity: unmountSubDistrictByCity,
  actionGetVillage: villageBySubDistrict,
  unmountVillageBySubDistrict: unmountVillageBySubDistrict,
}

export default connect(mapStateToProps, mapDispatchToProps)(index)