import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Loading } from '../../../components';
import { listJobPositionByJobIndustry } from '../../../redux/actions/master/jobPosition/jobPositionAction';
import { Row, Col, Card, Image, Typography, Space, Layout, message } from 'antd';
import Header from '../Header';
import Footer from '../Footer';
import '../JobPreferences.css';
import { checkToken } from '../../../redux/actions/auth/authAction'

const { Title, Text } = Typography;

export class Position extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      positionSelected: localStorage.getItem("positionSelected") ? localStorage.getItem("positionSelected").split(',').map(x=>+x) : []
    }
  }
  
  componentDidMount() {
    const { actionCheckToken, actionGetJobPosition } = this.props

    actionCheckToken((response) => {
      if(response.code === "1000"){
        actionGetJobPosition(localStorage.getItem("industriesSelected"))
      }
    })
  }

  onSelectPosition = (id) => {
    const { positionSelected } = this.state
    const filterSelected = positionSelected.filter(item => item === id)
    const indexOfSelected = positionSelected.indexOf(id)
    if(filterSelected.length === 0){
      if(positionSelected.length < 3){
        positionSelected.push(id)
      }else{
        message.error('You can only pick 3 job position!')
      }
    }else{
      positionSelected.splice(indexOfSelected,1)
    }

    this.setState({ positionSelected })
  }

  render() {
    const { getJobPosition: { data, loading } } = this.props
    const { positionSelected } = this.state
    
    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Job Preferences - Job Position</title>
        </Helmet>
        <Layout>
          <Header />
          <Row className="dis-inline m-16">
            <Col className="pb-100 mt-64" xs={24} sm={24} md={{ span: 22, offset: 1 }}>
              <Space className="full-width mb-32 txt-algn-center" direction="vertical" size="small">
                <Title className="txt-color fw-normal" level={3}>What’s your desired positions?</Title>
                <Text className="txt-color fw-normal">Maximum 3 options.</Text>
              </Space>
              {
                data?.map((item, i) =>
                  <React.Fragment key={i}>
                    <Row className="mb-16" justify="space-around" align="middle">
                      <Col span={24}>
                      <Space size="middle">
                        <Image
                          className="pt-6"
                          width={40}
                          height={40}
                          src={item.iconUrl}
                          preview={false}
                        />
                        <Text className="fs-13">{item.name}</Text>
                      </Space>
                      </Col>
                    </Row>
                    <Row className="mb-32" gutter={[16, 16]}>
                      {
                        item.listJobPosition.map((res, i) => 
                          <Col key={i} xs={24} sm={24} md={8}>
                            <Card
                              className={ 
                                positionSelected.length === 0 ? 
                                  "full-width br-6"
                                :
                                positionSelected.map((value) => 
                                    res.id === value ? "full-width br-6 card-selected" : "full-width br-6"
                                  )}
                              hoverable
                              bodyStyle={{ padding: 12 }}
                              onClick={() => this.onSelectPosition(res.id)}
                            >
                              <Row justify="space-around" align="middle">
                                <Col className="txt-algn-center" span={24}>
                                  <Text className="fs-11">{res.title}</Text>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        )
                      }
                    </Row>
                  </React.Fragment>
                )
              }
            </Col>
          </Row>
          <Footer positionSelected={positionSelected} />
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getJobPosition: state.jobPositionReducer
})

const mapDispatchToProps = {
  actionCheckToken: checkToken,
  actionGetJobPosition: listJobPositionByJobIndustry
}

export default connect(mapStateToProps, mapDispatchToProps)(Position)
