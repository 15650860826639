import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Menu, Grid, Button, Drawer, Image, Space } from 'antd';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';
import { MenuOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;

const { Header } = Layout;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const index = ({ visible, showDrawer, onClose }) => {
  const { md } = GetBreakPoint()
  const { pathname } = window.location

  return (
    <React.Fragment>
      {
        pathname === '/register/confirm-email' ?
          null
        :
          md ?
            <Header>
              <Link to="/" style={{ paddingTop: 20 }}>
                <Image
                  width={140}
                  src={JobshubLogo}
                  preview={false}
                />
              </Link>
              <Space>
              <Link to="/login">
                <Button className="br-3" type="primary" style={{ width: 112, border: 'solid 2px #32ab6d' }} ghost>Login</Button>
              </Link>
              <Link to="/register">
                <Button className="br-3" type="primary" style={{ width: 112, border: 'solid 2px #32ab6d', background: '#32ab6d' }}>Register</Button>
              </Link>
              </Space>
            </Header>
          :
            <Header breakpoint="md" className="header-mobile">
              <Link to="/">
                <img className="logo" src={JobshubLogo} alt="..." />
              </Link>
              <Button className="m-16" icon={<MenuOutlined />} onClick={showDrawer} />
              <Drawer
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ padding: 0 }}
              >
                <Menu onClick={onClose} selectedKeys={[pathname]} mode="vertical">
                  <Menu.Item key="/register">
                    <Link to="/register">
                      Register
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/login">
                    <Link to="/login">
                      Login
                    </Link>
                  </Menu.Item>
                </Menu>
              </Drawer>
            </Header>
      }

    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(index)