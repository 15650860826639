import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Menu, Grid, Drawer, Typography } from 'antd';
import { checkAuth } from '../../../redux/actions/auth/authAction';
const { useBreakpoint } = Grid;
const { Text } = Typography
const { Sider } = Layout;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}



export const index = (props) => {
  const { sidebar, visible, onClose, getData, index } = props
  const { md } = GetBreakPoint()
  const { pathname } = window.location

  const Icon = ({ type, ...rest }) => {
    const icons = require(`@ant-design/icons`);
    const Component = icons[type];
    return <Component {...rest} />
  };
  return (
    <React.Fragment>
      {
        md ?
          <Sider width={200} className={ !sidebar ? "sidebar" : null }>
            <Menu
              className="txt-color full-height width-auto"
              defaultSelectedKeys={[`${index}`]}
              mode="inline"
            >
              {
                getData.permission.map((response) => {
                  return (
                    response.show === true ?
                    <Menu.Item key={response.index} icon={<Icon type={response.icon} />}>
                      <Link to={response.path}>
                        <Text className="txt-color">{response.title}</Text>
                      </Link>
                    </Menu.Item>
                    :
                    null
                  )
                })
              }
            </Menu>
          </Sider>
        :
          <Drawer
            bodyStyle={{ padding: 0}}
            placement="left"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <Menu
              className="txt-color full-height width-auto"
              selectedKeys={[pathname]}
              mode="inline"
            >
              {
                getData.permission.map((response) => {
                  return (
                    response.show === true ?
                    <Menu.Item key={response.index} icon={<Icon type={response.icon} />}>
                      <Link to={response.path}>
                        <Text className="txt-color">{response.title}</Text>
                      </Link>
                    </Menu.Item>
                    :
                    null
                  )
                })
              }
            </Menu>
          </Drawer>
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  getData: state.authReducer
})

const mapDispatchToProps = {
  auth: checkAuth
}

export default connect(mapStateToProps, mapDispatchToProps)(index)