import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { dataLandingPage } from '../../redux/actions/landingPage/landingPageAction';
import { indexJobDestination } from '../../redux/actions/master/jobDestination/jobDestinationAction';
import Loading from '../../components/Loading';
import Carousel from './carousel';
import Companies from './companies';
import Jobbseker from './jobseeker';
import Register from './register';
import { validatePost } from '../../utils/postValidationFunction';

export class Home extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      destinationSelected: null,
      params: {
        page: 1,
        perpage: 10,
        jobIndustry: null,
        jobLocation: null,
        destination: null,
        search: null,
        field: 'dateOpened',
        sort: 'DESC',
        jobType: null,
        flagRemote: false,
        expLevel: null,
        postDate: null
       },
    }
  }
  
  componentDidMount() {
    const { actionDataLanding, actionGetJobDestination } = this.props
    if(localStorage.getItem('link')){
      localStorage.removeItem('link')
    }
    if(localStorage.getItem('visible')){
      localStorage.removeItem('visible')
    }
    if(localStorage.getItem('emailForgotPass')){
      localStorage.removeItem('emailForgotPass')
    }
    if(localStorage.getItem('industriesSelected')){
      localStorage.removeItem('industriesSelected')
    }
    if(localStorage.getItem('positionSelected')){
      localStorage.removeItem('positionSelected')
    }
    if(localStorage.getItem('destinationSelected')){
      localStorage.removeItem('destinationSelected')
    }
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(this.props.path, [], 'Landing Page');
    }
    
    return (actionDataLanding(), actionGetJobDestination())
  }

  handleDestination = (value, option) => {
    const optionSelected = Number(option.key)

    this.setState({
      destinationSelected: optionSelected
    })
  }

  onFinish = async (values) => {
    const { destinationSelected, params } = this.state
    values.location = destinationSelected

    const newParams = {...params, ...values}
    if(values.search || values.jobDestination){
      var queryString = Object.keys(validatePost(newParams)).map(key => key + '=' + newParams[key]).join('&');
      
      this.props.history.push(`/job?${queryString}`)
    }else{
      this.props.history.push('/job')
    }
  }

  initialProps ={
    formRef: this.formRef,
    onFinish: this.onFinish,
    handleDestination: this.handleDestination
  }

  render() {
    const { getDataLanding, getJobDestination } = this.props

    if(getDataLanding.loading || getJobDestination.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub</title>
        </Helmet>
        <Carousel {...this.props} />
        <Companies {...this.props} {...this.initialProps} />
        <Jobbseker />
        <Register />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getDataLanding: state.landingPageReducer,
  getJobDestination: state.jobDestinationReducer
})

const mapDispatchToProps = {
  actionDataLanding: dataLandingPage,
  actionGetJobDestination: indexJobDestination,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
