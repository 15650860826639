const LOAD_DATA_MASTER_SKILLS         = 'LOAD_DATA_MASTER_SKILLS'
const LOAD_DATA_MASTER_SKILLS_SUCCESS = 'LOAD_DATA_MASTER_SKILLS_SUCCESS'
const LOAD_DATA_MASTER_SKILLS_FAILED  = 'LOAD_DATA_MASTER_SKILLS_FAILED'
const UNMOUNT_DATA_MASTER_SKILLS      = 'UNMOUNT_DATA_MASTER_SKILLS'

const initialState = {
  loading: true,
  data: null,
  message: null,
  pagination: {
    current: 1,
    total: 0,
    pageSize: 0,
  }
}

const skillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA_MASTER_SKILLS:
      return {
        ...state,
        loading: true,
        data: null,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 0,
        }
      }
    case LOAD_DATA_MASTER_SKILLS_SUCCESS:
      return {
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
        pagination: {
          current: action.payload.pagination.current,
          total: action.payload.pagination.total,
          pageSize: action.payload.pagination.pageSize
        }
      }
    case LOAD_DATA_MASTER_SKILLS_FAILED:
      return {
        ...state, 
        loading: false,
        data: null,
        message: 'FAILED',
        pagination: {
          current: 1,
          total: 0,
          pageSize: 0,
        }
      }
    case UNMOUNT_DATA_MASTER_SKILLS:
      return { 
        ...state, 
        loading: true,
        data: null,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 0,
        }
      }
    default:
      return state
  }
}
export default skillsReducer