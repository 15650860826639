import React, { Component } from 'react'
import { Row, Col, Typography, Button, Form, Select, Checkbox, Divider, Skeleton, message } from 'antd'

const { Text } = Typography
const { Option } = Select

export default class FormJobNotification extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      isWebNotification: null,
      isEmailNotification: null,
      isDeviceNotification: null
    }
  }
  
  componentDidMount() {
    const { actionCandidateNotification } = this.props

    return actionCandidateNotification()
  }

  onFinish = (values) => {
    const { isWebNotification, isEmailNotification, isDeviceNotification } = this.state
    const { getCandidateNotification } = this.props
    values.isWebNotification = isWebNotification || getCandidateNotification?.data?.isWebNotification || false
    values.isEmailNotification = isEmailNotification || getCandidateNotification?.data?.isEmailNotification || false
    values.isDeviceNotification = isDeviceNotification || getCandidateNotification?.data?.isDeviceNotification || false
    const { actionUpdateNotification, actionCandidateTimezone } = this.props;
    this.setState({ submitLoading: true })
    return actionUpdateNotification(values, (response) => {
      this.setState({ submitLoading: false }, () => {
        if(response.code === '1000'){
          message.success(response.message)
          return actionCandidateTimezone()
        }else{
          message.error(response.message)
        }
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        message.error(err)
      })
    })
  }
  
  handleWebNotification = (e) => {
    this.setState({
      isWebNotification: e.target.checked
    })
  }

  handleEmailNotification = (e) => {
    this.setState({
      isEmailNotification: e.target.checked
    })
  }

  handleDeviceNotification = (e) => {
    this.setState({
      isDeviceNotification: e.target.checked
    })
  }

  render() {
    const { submitLoading, isWebNotification, isEmailNotification, isDeviceNotification } = this.state
    const { getCandidateNotification } = this.props

    if(getCandidateNotification.loading){
      return <Skeleton active />
    }

    return (
      <React.Fragment>
        <Form 
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onFinish}
        >
          <Row gutter={8}>
            <Col xxl={18} xl={16} lg={14} md={12} sm={24} xs={24}>
              <Text className="fs-13" type="secondary">Recommended Jobs: Be notified about job ads, based on your profile</Text>
            </Col>
            <Col xxl={6} xl={8} lg={10} md={12} sm={24} xs={24}>
              <Form.Item name="jobNotification" initialValue={getCandidateNotification?.data?.jobNotification || "MONTHLY"}>
                <Select
                  className="f-right br-6"
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  size="small"
                >
                  <Option value="WEEKLY">Weekly</Option>
                  <Option value="MONTHLY">Monthly</Option>
                  <Option value="NEVER">Never</Option>
                </Select>
              </Form.Item>
            </Col>
            <Divider />
            <Col xxl={18} xl={16} lg={14} md={12} sm={24} xs={24}>
              <Text className="fs-13" type="secondary">How you get your notifications</Text>
            </Col>
            <Col xxl={6} xl={8} lg={10} md={12} sm={24} xs={24}>
              <Form.Item className="mb-min-8" name="isWebNotification" initialValue={isWebNotification || getCandidateNotification?.data?.isWebNotification}>
                <Checkbox defaultChecked={isWebNotification || getCandidateNotification?.data?.isWebNotification} onChange={this.handleWebNotification}>Jobshub Web</Checkbox>
              </Form.Item>
              <Form.Item className="mb-min-8" name="isEmailNotification" initialValue={isEmailNotification || getCandidateNotification?.data?.isEmailNotification}>
                <Checkbox defaultChecked={isEmailNotification || getCandidateNotification?.data?.isEmailNotification} onChange={this.handleEmailNotification}>Email</Checkbox>
              </Form.Item>
              <Form.Item name="isDeviceNotification" initialValue={isDeviceNotification || getCandidateNotification?.data?.isDeviceNotification}>
                <Checkbox defaultChecked={isDeviceNotification || getCandidateNotification?.data?.isDeviceNotification} onChange={this.handleDeviceNotification}>Pops up on your device</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="txt-algn-center mt-8">
            <Button className="br-3" htmlType="submit" type="primary" loading={submitLoading} style={{ width: 160 }}>Save</Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountCandidateNotification } = this.props
    return unmountCandidateNotification()
  }
}
