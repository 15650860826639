import React, { Component } from 'react'
import { connect } from 'react-redux'
import CompanyComponent from '../../../components/Company'
import { message } from 'antd'
import { dataCompany, unmountDataCompany } from '../../../redux/actions/company/companyAction'
import { listCandidateJobCompany, unmountListJob } from '../../../redux/actions/job/jobAction'
import { addOrDeleteFavorite } from '../../../redux/actions/favorite/favoriteAction'

export class CandidateCompany extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      paramsJob: {
        page: 1,
        perpage: 5,
        search: null
      }
    }
  }
  
  componentDidMount() {
    const { paramsJob: { page, perpage, search } } = this.state
    const { actionGetCompany, actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: page,
      perpage: perpage,
      search: search
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }
    this.setState({ paramsJob })

    return (actionGetCompany(params.id, params.companyType), actionGetJob(paramsJob))
  }

  onSearch = (value) => {
    const { paramsJob: { page, perpage } } = this.state
    const { actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: page,
      perpage: perpage,
      search: value
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }

    this.setState({ paramsJob })
    return actionGetJob(paramsJob)
  }

  onChangePagination = (pageNumber) => {
    const { paramsJob: { perpage, search } } = this.state
    const { actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: pageNumber,
      perpage: perpage,
      search: search
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }

    this.setState({ paramsJob })
    return actionGetJob(paramsJob)
  }

  handleFavorite = (id, isFavorite) => {
    const { paramsJob: { page, perpage, search } } = this.state
    const { actionAddOrDeleteFavorite, actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: page,
      perpage: perpage,
      search: search
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }
    
    const values = {
      jobId: id
    }

    return this.setState({ submitLoading: true }, () => {
      return actionAddOrDeleteFavorite(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            message.success(isFavorite ? 'Job has been removed from your favorite' : 'Job has been added to favorites')
            return actionGetJob(paramsJob)
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const initialProps = {
      onSearch: this.onSearch,
      onChangePagination: this.onChangePagination,
      handleFavorite: this.handleFavorite
    }
    return (
      <CompanyComponent {...initialProps} {...this.props} />
    )
  }
  componentWillUnmount(){
    const { unmountDataCompany, unmountListJob } = this.props
    return (unmountDataCompany(), unmountListJob())
  }
}

const mapStateToProps = (state) => ({
  getCompany: state.companyReducer,
  getJob: state.jobReducer
})

const mapDispatchToProps = {
  actionGetCompany: dataCompany,
  unmountDataCompany: unmountDataCompany,
  actionGetJob: listCandidateJobCompany,
  unmountListJob: unmountListJob,
  actionAddOrDeleteFavorite: addOrDeleteFavorite
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCompany)