import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountListSkill = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_SKILLS'})
}

export const listSkill = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_SKILLS' })
  
  API.GET('/skill/list', value).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_SKILLS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_SKILLS_FAILED' }), 
    ))
  })
}