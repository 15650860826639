import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import moment from 'moment';
import { Row, Col, Typography, Space, Form, Input, DatePicker, Select, AutoComplete, Spin, Skeleton } from 'antd';
import { listInstitution } from '../../../../../redux/actions/master/institution/institutionAction';
import { indexEducationLevel, unmountIndexEducationLevel } from '../../../../../redux/actions/master/educationLevel/educationLevelAction';
import { listFieldOfStudy } from '../../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }
  
  componentDidMount() {
    const { actionGetEducationLevel } = this.props

    return actionGetEducationLevel()
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }


  render() {
    const { 
      getInstitution, 
      getEducationLevel, 
      getFieldOfStudy, 
      isYear, 
      onChangeEducationLevel,
      onChangeStartYear,
      onChangeEndYear,
      getLastEducation,
      valuesEducation,
      educationLevelName
    } = this.props
    
    if(getLastEducation.loading || getEducationLevel.loading){
      return <Skeleton active />
    }

    return (
      <React.Fragment>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">School/University</Text>
            </Space>
            <Form.Item 
              name="educationName"
              validateFirst
              rules={[
                { required: true, message: 'School/University is required!' },
                // { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
                { min: 5, message: 'School must be at least 5 characters' },
                { max: 100, message: '100 characters only' },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationName ?
                  valuesEducation.educationName
                : getLastEducation.data.educationName ?
                  getLastEducation.data.educationName
                :
                  ""
              }
            >
              <AutoComplete
                className="full-width"
                onFocus={this.onFocusInstitution}
                onSearch={this.onSearchInstitution}
                notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                filterOption={false}
                size="small"
                placeholder="e.g.: Binawan University"
              >
                {
                  getInstitution?.data?.map((res) => (
                    <Option key={res.id} value={res.name}>
                      {res.name}
                    </Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">Education Level</Text>
            </Space>
            <Form.Item 
              name="educationLevelId"
              rules={[
                { required: true, message: 'Education Level is required!' }
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationLevelId ?
                  educationLevelName
                : getLastEducation.data.educationLevelName ?
                  getLastEducation.data.educationLevelName
                :
                  ""
              }
            >
              <Select 
                className="full-width br-6"
                size="small" 
                onChange={onChangeEducationLevel}
              >
                {
                  getEducationLevel?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Text className="fs-12" type="secondary">Field of Study</Text>
            <Form.Item 
              name="educationFieldOfStudy"
              validateFirst
              rules={[
                { min: 5, message: 'Fields of Study must be at least 5 characters' },
                { max: 100, message: '100 characters only' },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationFieldOfStudy ?
                  valuesEducation.educationFieldOfStudy
                : getLastEducation.data.educationFieldOfStudy ?
                  getLastEducation.data.educationFieldOfStudy
                :
                  ""
              }
            >
              <AutoComplete
                className="full-width"
                onFocus={this.onFocusFieldOfStudy}
                onSearch={this.onSearchFieldOfStudy}
                notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                filterOption={false}
                size="small"
                placeholder="e.g.: Nursing"
              >
                {
                  getFieldOfStudy?.data?.map((res) => (
                    <Option key={res.id} value={res.name}>
                      {res.name}
                    </Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="fs-12" type="secondary">Score/Grade/GPA</Text>
            <Form.Item 
              name="educationGpa"
              rules={[
                { pattern: /^[0-9a-zA-Z.]*$/, message: 'Only dot (.) is allowed, No spaces allowed' },
                { max: 4, message: '4 characters only' },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationGpa ?
                  valuesEducation.educationGpa
                : getLastEducation.data.educationGpa ?
                  getLastEducation.data.educationGpa
                :
                  ""
              }
            >
              <Input size="small" placeholder="e.g.: 3.50" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Text className="fs-12" type="secondary">Start Year</Text>
            <Form.Item 
              name="educationStartYear"
              initialValue={
                valuesEducation && valuesEducation.educationStartYear ?
                  moment(valuesEducation.educationStartYear)
                : getLastEducation.data.educationStartYear ?
                  moment(getLastEducation.data.educationStartYear)
                :
                  ""
              }
            >
              <DatePicker className="full-width" size="small" picker="year" onChange={onChangeStartYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="fs-12" type="secondary">End Year (or expected)</Text>
            <Form.Item 
              name="educationEndYear"
              validateStatus={isYear ? "error" : null}
              help={isYear ? "Your end year can’t be earlier than your start year" : null}
              initialValue={
                valuesEducation && valuesEducation.educationEndYear ?
                  moment(valuesEducation.educationEndYear)
                : getLastEducation.data.educationEndYear ?
                  moment(getLastEducation.data.educationEndYear)
                :
                  ""
              }
            >
              <DatePicker className="full-width" size="small" picker="year" onChange={onChangeEndYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="fs-12" type="secondary">Activities and Organization Experiences</Text>
            <Form.Item 
              name="educationExperience"
              rules={[
                { max: 200, message: '200 characters only' }
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationExperience ?
                  valuesEducation.educationExperience
                : getLastEducation.data.educationExperience ?
                  getLastEducation.data.educationExperience
                :
                  ""
              }
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getInstitution: state.institutionReducer,
  getEducationLevel: state.educationLevelReducer,
  getFieldOfStudy: state.fieldOfStudyReducer
})

const mapDispatchToProps = {
  actionListInstitution: listInstitution,
  actionGetEducationLevel: indexEducationLevel,
  unmountIndexEducationLevel: unmountIndexEducationLevel,
  actionListFieldOfStudy: listFieldOfStudy,
}

export default connect(mapStateToProps, mapDispatchToProps)(index)