import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountlistJobPositionByJobIndustry = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_JOB_POSITION'})
}

export const listJobPositionByJobIndustry = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_POSITION' })
  const params = { 
    jobIndustry: value 
  }
  API.GET('/job-position/getByJobIndustry', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_POSITION_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_POSITION_FAILED' }), 
    ))
  })
}