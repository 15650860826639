import axios from 'axios';
import { env } from './index';
import Cookie from 'js-cookie';

export const GET = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': getToken ? `Bearer ${getToken}` : null
  }
  return new Promise((resolve ,reject) => {
    axios.get(`${env}${path}`, {
      headers: header,
      params: payload
    }).then((response) => {
      let data = response.data;
      if(response.status === 200){
        return resolve(data);
      }else{
        const error = { message: 'error' }
        return reject(error)
      }
    }).catch((err) => {
      return reject(err.response.data)
    })
  })
}

export const POST = (path, payload) => {
  const getToken = Cookie.get('user');

  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Authorization': getToken ? `Bearer ${getToken}` : null
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data);
    })
  })
}

export const POST_EMAIL = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    })
  })
}

export const POST_WITH_PARAMS = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Authorization': getToken ? `Bearer ${getToken}` : null
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data);
    })
  })
}

export const POST_FORM_DATA = (path, payload) => {
  const getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    const data = new FormData();
    for (const [val, name] of Object.entries(payload)) {
      if(val === "profilePicFile"){
        data.append(val, name);
      }else{
        if(typeof name === 'object'){
          for (const [key, _val] of Object.entries(name)) {
            data.append(`${val}.${key}`, _val);
          }
        }
  
        if(val === "jobId"){
          data.append(val, name);
        }
      }
    }
    
    axios.post(`${env}${path}`, data, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-Authorization': `Bearer ${getToken}`
      },
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data);
    })
  }) 
}

export const POST_NO_WITH_BEARER = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data);
    })
  })
}

export const UPLOAD = async (path, payload) => {
  var getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    var data = new FormData();
    for (const [val, name] of Object.entries(payload)) {
      data.append(val, name);
    } 
    axios.post(`${env}${path}`, data, {
      headers: {
        "Accept": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${getToken}`,
      },
    }).then((response) => {
      let data = response.data;
      if(data.status.code === 200){
        return resolve(data);
      }else{
        var _err = { message: data.status.message }
        return reject(_err);
      }
    }).catch((err) => {
      var _err = err.response.data.status
      return reject(_err);
    })
  })   
}