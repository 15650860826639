import React from 'react'
import { Carousel, Image, Grid } from 'antd';
import './Home.css';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function banner(props) {
  const { getDataLanding } = props
  const { md } = GetBreakPoint()
  
  return (
    <React.Fragment>
      <Carousel className="landing-page" autoplay>
        {
          getDataLanding.data.listSlider.map((item, i) =>
            <Image
              key={i}
              src={item.imageUrl}
              preview={false}
              height={md ? 512 : 240}
              width={'100%'}
            />
          )
        }
      </Carousel>
    </React.Fragment>
  )
}
