import { combineReducers } from 'redux';
import authReducer from './auth/authReducer'
import jobReducer from './job/jobReducer';
import jobDetailReducer from './job/jobDetailReducer';
import stepsReducer from './applyJob/stepsReducer';
import contactReducer from './applyJob/contactReducer';
import personalInfoReducer from './applyJob/personalInfoReducer';
import lastEducationReducer from './applyJob/lastEducationReducer';
import menuApplicationsReducer from './applications/menuApplicationsReducer';
import listApplicationsReducer from './applications/listApplicationsReducer';
import interviewReducer from './applications/interviewReducer';
import favoriteReducer from './favorite/favoriteReducer';
import countryReducer from './master/country/countryReducer';
import provinceReducer from './master/province/provinceReducer';
import cityReducer from './master/city/cityReducer';
import subDistrictReducer from './master/subDistrict/subDistrictReducer';
import villageReducer from './master/village/villageReducer';
import timezoneReducer from './master/timezone/timezoneReducer';
import candidateTimezoneReducer from './accountSettings/timezoneReducer';
import candidateNotificationReducer from './accountSettings/notificationReducer';
import educationLevelReducer from './master/educationLevel/educationLevelReducer';
import institutionReducer from './master/institution/institutionReducer';
import fieldOfStudyReducer from './master/fieldOfStudy/fieldOfStudyReducer';
import jobIndustriesReducer from './master/jobIndustries/jobIndustriesReducer';
import allJobIndustriesReducer from './master/jobIndustries/allJobIndustriesReducer';
import jobPositionReducer from './master/jobPosition/jobPositionReducer';
import jobDestinationReducer from './master/jobDestination/jobDestinationReducer';
import allJobDestinationReducer from './master/jobDestination/allJobDestinationReducer';
import registerReducer from './register/registerReducer';
import profileReducer from './profile/profileReducer';
import headerReducer from './header/headerReducer';
import notificationReducer from './notification/notificationReducer';
import countNotificationReducer from './notification/countNotificationReducer';
import companyReducer from './company/companyReducer';
import landingPageReducer from './landingPage/landingPageReducer';
import skillsReducer from './master/skills/skillsReducer';

export default combineReducers({
    authReducer,
    jobReducer,
    jobDetailReducer,
    stepsReducer,
    contactReducer,
    personalInfoReducer,
    lastEducationReducer,
    menuApplicationsReducer,
    listApplicationsReducer,
    interviewReducer,
    favoriteReducer,
    countryReducer,
    provinceReducer,
    cityReducer,
    subDistrictReducer,
    villageReducer,
    timezoneReducer,
    candidateTimezoneReducer,
    candidateNotificationReducer,
    educationLevelReducer,
    institutionReducer,
    fieldOfStudyReducer,
    jobIndustriesReducer,
    allJobIndustriesReducer,
    jobPositionReducer,
    jobDestinationReducer,
    allJobDestinationReducer,
    registerReducer,
    profileReducer,
    headerReducer,
    notificationReducer,
    countNotificationReducer,
    companyReducer,
    landingPageReducer,
    skillsReducer
})