import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { setActivation } from '../../../redux/actions/register/registerAction';
import { setResend } from '../../../redux/actions/check/checkAction';
import { Form, Input, Button, Row, Col, Card, Typography, Space, Statistic, message } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import './ConfirmEmail.css';

const { Countdown } = Statistic;
const { Title, Text } = Typography;

export class ConfirmEmail extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isCountdown: true,
      countdown: Date.now() + 60 * 2000
    }
  }
  
  onFinish = (values) => {
    const { actionSetActivation } = this.props

    values.email = localStorage.getItem('email')
    values.password = localStorage.getItem('password')

    return actionSetActivation(values, response => {
      if(response.code === '1000'){
        this.setState({ submitLoading: false }, () => {
          localStorage.removeItem('email')
          localStorage.removeItem('password')
          this.props.history.push('/job-preferences/industries')
        })
      }else{
        message.error(response.message)
      }
    }, (err) => {
      message.error(err)
    })
  }

  onClickResend = () => {
    const value = {
      email: localStorage.getItem('email')
    }

    const { actionSetResend } = this.props

    actionSetResend(value, response => {
      message.success(response.message)
      this.setState({
        isCountdown: true,
        countdown: Date.now() + 60 * 2000
      })
    }, (err) => {
      message.error(err)
    })
  }

  onFinishCountdown = () => {
    this.setState({
      isCountdown: false
    })
  }

  render() {
    const { isCountdown, countdown } = this.state

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Confirm Email</title>
        </Helmet>
        <Row className="dis-flex pt-32 m-16" align="middle">
          <Col md={{ span: 8, offset: 8 }}>
              <Form onFinish={this.onFinish}>
                <Space className="full-width" align="center" direction="vertical" size="small">
                  <Title className="txt-color fw-normal mb-0" level={2}>Confirm your email</Title>
                  <Text className="txt-color fw-normal">Type the code we sent to {localStorage.getItem('email')}</Text>
                </Space>
                <Form.Item name="activationCode">
                  <Input className="txt-algn-center mt-32 mb-16" placeholder="_ _ _ _ _ _" size="large" />
                </Form.Item>
                <Card className="card-confirm-email txt-color full-width txt-algn-left mb-16">
                  <Space direction="vertical">
                    <Space>
                      <SafetyOutlined className="icon-md" />
                      <Text className="fs-13">your privacy is important</Text>
                    </Space>
                    <Text className="txt-color fs-13">We may send you member updates, recruiter message, job suggestions, invitations, reminders and promotional messages from us and our partner. </Text>
                  </Space>
                </Card>
                <Button className="full-width mb-8" htmlType="submit" type="primary">Confirm and Agree</Button>
                <Space size={4}>
                    <Text className="fs-11 txt-algn-left" type="secondary">Didn’t receive the code? </Text>
                    {
                      isCountdown ?
                      <Countdown value={countdown} onFinish={this.onFinishCountdown} format="mm:ss" valueStyle={{ fontSize: 11 }} />
                      :
                      <Text className="fs-12 a-color c-pointer" onClick={this.onClickResend}>Send a new one</Text>
                    }
                  </Space>
              </Form>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
  actionSetActivation: setActivation,
  actionSetResend: setResend,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)
