import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateAboutMe } from '../../../../redux/actions/profile/aboutMeAction';
import { Card, Typography, Button, Modal, Input, Space, Form, message } from 'antd';
import { EditFilled } from '@ant-design/icons';

const { Text } = Typography;
const { TextArea } = Input;

export class AboutMe extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false
    }
  }
  
  showModal = () => {
    this.setState({
      visible: true
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  onFinish = async (values) => {
    const { actionUpdateAboutMe, actionGetProfile } = this.props
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdateAboutMe(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { visible, submitLoading } = this.state
    const { data } = this.props

    return (
      <React.Fragment>
        <Card 
          className="br-6 box-shadow" 
          title="About Me" 
          bordered={false} 
          extra={<Button type="link" icon={<EditFilled />} onClick={this.showModal}>Edit</Button>} 
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={ data.about ? {} : { textAlign: 'center', padding: 60 } }
        >
          {
            data.about ?
              <Text type="secondary">{data.about}</Text>
            :
              <Text className="txt-color">Strengthen your profile by adding your personality, career story, and inspiring action </Text>
          }
        </Card>

        <Modal 
          title="About Me" 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              about: data.about
            }}
          >
            <Form.Item 
              name="about"
              rules={[
                { max: 1000, message: '1000 characters only' }
              ]}
            >
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item>
              <Space className="f-right">
                <Button onClick={this.handleCancel}>Cancel</Button>
                <Button htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
  actionUpdateAboutMe: updateAboutMe
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutMe)