import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Result, Button } from 'antd';

export default class ComingSoon extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Coming Soon</title>
        </Helmet>
        <Result
          status="404"
          title="Coming Soon"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary" href="/">Back Dashboard</Button>}
        />
      </React.Fragment>
    )
  }
}