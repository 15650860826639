import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { Modal, Row, Col, Space, Typography, Image, message } from 'antd';
import JobDetail from '../../../../components/Job/Detail';
import { detailJob, unmountDetailJob } from '../../../../redux/actions/job/jobAction';
import { steps, unmountSteps, contact, unmountContact, personalInfo, unmountPersonalInfo, lastEducation, unmountLastEducation, applyJob } from '../../../../redux/actions/applyJob/applyJobAction';
import { addOrDeleteFavorite } from '../../../../redux/actions/favorite/favoriteAction'

import iconCheck from '../../../../assets/img/logo/check.svg';
import { validatePost } from '../../../../utils/postValidationFunction';

const { Text } = Typography;

export class CandidateJobDetail extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false,
      visibleSuccess: false,
      current: 0,
      profilePicFile: null,
      editAvatar: false,
      onPreviewAvatar: null,

      callingCodeId: 99,
      countryName: 'Indonesia',
      callingCode: 62,
      textCode: null,
      currentCityName: "",
      currentCityId: "",

      birthdate: "",
      nationalityId: 100,
      countryId: "",
      provinceId: "",
      cityId: "",
      subDistrictId: "",
      villageId: "",

      educationLevelName: "",
      educationLevelId: "",
      isYear: false,
      startYear: "",
      endYear: "",

      valuesContact: null,
      valuesPersonal: null,
      valuesEducation: null,

      editContact: false,
      editPersonal: false,
      editEducation: false
    }
  }
  componentDidMount() {
    const { actionGetJobDetail, match: { params } } = this.props
    
    actionGetJobDetail(params.id)
    if(localStorage.getItem('visible')){
      const { actionGetSteps, actionGetContact, actionGetPersonalInfo, actionGetLastEducation } = this.props
      this.setState({ visible: true })
      return (actionGetSteps(), actionGetContact(), actionGetPersonalInfo(), actionGetLastEducation())
    }
  }

  showModal = () => {
    const { actionGetSteps, actionGetContact, actionGetPersonalInfo, actionGetLastEducation } = this.props
    this.setState({
      visible: true
    })
    return (actionGetSteps(), actionGetContact(), actionGetPersonalInfo(), actionGetLastEducation())
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      current: 0,
      valuesContact: null,
      valuesPersonal: null,
      valuesEducation: null,
      editContact: false,
      editPersonal:false,
      editEducation: false
    })
    if(localStorage.getItem('visible')){
      localStorage.removeItem('visible')
    }
  }

  handleUpload = () => {
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            profilePicFile: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleCallingCode = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      textCode: value,
      callingCode: option.text,
      callingCodeId: optionSelected
    })
  }

  handleCurrentCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      currentCityName: value,
      currentCityId: optionSelected
    })
  }

  onChangeDate = (date, dateString) => {
    this.setState({
      birthdate: dateString
    })
  }

  handleNationality = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      nationalityId: optionSelected
    })
  }

  handleCountry = (value, option) => {
    const { provinceId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      countryId: optionSelected,
      selectedCountry: optionSelected,
    })

    if(provinceId !== 0){
      this.setState({
        provinceId: null,
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        province: null,
        city: null,
        subDistrict: null,
        village: null
      })
    }
  }

  handleProvince = (value, option) => {
    const { cityId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      provinceId: optionSelected
    })

    if(cityId !== 0){
      this.setState({
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        city: null,
        subDistrict: null,
        village: null
      })
    }
  }

  handleCity = (value, option) => {
    const { subDistrictId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      cityId: optionSelected
    })

    if(subDistrictId !== 0){
      this.setState({
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        subDistrict: null,
        village: null
      })
    }
  }

  handleSubDistrict = (value, option) => {
    const { villageId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      subDistrictId: optionSelected
    })

    if(villageId !== 0){
      this.setState({
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        village: null
      })
    }
  }

  handleVillage = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      villageId: optionSelected
    })
  }

  onChangeEducationLevel = (value, option) => {
    const optionSelected = Number(option.key)

    this.setState({
      educationLevelName: value,
      educationLevelId: optionSelected
    })
  }

  onChangeStartYear = (date, dateString) => {
    const { endYear } = this.state

    this.setState({
      startYear: dateString
    })
    
    if(endYear < dateString){
      this.setState({
        isYear: true
      })
    }else{
      this.setState({
        isYear: false
      })
    }
  }

  onChangeEndYear = (date, dateString) => {
    const { startYear } = this.state

    this.setState({
      endYear: dateString
    })

    if(dateString < startYear){
      this.setState({
        isYear: true
      })
    }else{
      this.setState({
        isYear: false
      })
    }
  }

  handleEditContact = () => {
    this.setState({
      editContact: true
    })
  }

  handleEditPersonal = () => {
    this.setState({
      editPersonal: true
    })
  }

  handleEditEducation = () => {
    this.setState({
      editEducation: true
    })
  }

  prev = () => {
    const { current } = this.state
    this.setState({
      current: current - 1
    })
  }

  onNext = async (values) => {
    const { current, profilePicFile, callingCodeId, callingCode, currentCityId, birthdate, nationalityId, countryId, provinceId, cityId, subDistrictId, villageId,  educationLevelId, startYear, endYear } = this.state
    const { getSteps, getContact, getPersonalInfo, getLastEducation } = this.props
    if(getSteps?.data?.listStepsApply[current].name === 'Contact Detail'){
      values.profilePicFile = profilePicFile
      values.currentCityId = currentCityId ? currentCityId : getContact.data.currentCityId
      values.callingCodeId = callingCodeId ? callingCodeId : getContact.data.callingCodeId
      values.mobilePhone = 
        values.mobilePhone.charAt(0) === '0' ? 
          `${callingCode}${values.mobilePhone.substring(1)}`
        : values.mobilePhone.substr(0, 2) === '62' ?
          `${callingCode}${values.mobilePhone.substring(2)}`
        : 
          `${callingCode}${values.mobilePhone}`
      
      if(values.profilePicFile){
        this.setState({
          current: current + 1,
          callingCode: callingCode,
          valuesContact: values
        })
      }else{
        message.error('Photo is required!')
      }
    }
    

    if(getSteps?.data?.listStepsApply[current].name === 'Personal Info'){
      values.birthdate = birthdate ? birthdate : getPersonalInfo.data.birthdate 
      values.nationalityId = nationalityId ? nationalityId : getPersonalInfo.data.nationalityId
      values.countryId = countryId ? countryId :  getPersonalInfo.data.countryId ? getPersonalInfo.data.countryId : ""
      values.provinceId = provinceId ? provinceId : getPersonalInfo.data.provinceId ? getPersonalInfo.data.provinceId : ""
      values.cityId = cityId ? cityId : getPersonalInfo.data.cityId ? getPersonalInfo.data.cityId : ""
      values.subDistrictId = subDistrictId ? subDistrictId : getPersonalInfo.data.subDistrictId ? getPersonalInfo.data.subDistrictId : ""
      values.villageId = villageId ? villageId : getPersonalInfo.data.villageId ? getPersonalInfo.data.villageId : ""
      this.setState({
        current: current + 1,
        valuesPersonal: values
      })
    }
    if(getSteps?.data?.listStepsApply[current].name === 'Education'){
      values.educationLevelId = educationLevelId ? educationLevelId : getLastEducation.data.educationLevelId
      values.educationStartYear = startYear ? startYear : getLastEducation.data.educationStartYear ? getLastEducation.data.educationStartYear : ""
      values.educationEndYear = endYear ? endYear : getLastEducation.data.educationEndYear ? getLastEducation.data.educationEndYear : ""
      this.setState({
        valuesEducation: values,
        current: current + 1,
      })
    }
  }
  
  onFinish = () => {
    const { actionApplyJob, getContact, getPersonalInfo, getLastEducation, actionGetJobDetail, match: { params }  } = this.props
    
    const { valuesContact, valuesPersonal, valuesEducation } = this.state
    const jobId = params.id
    getContact.data.mobilePhone = `${getContact.data.callingCode}${getContact.data.mobilePhone}`
    
    const _valuesContact = valuesContact ? valuesContact : getContact.data
    const _valuesPersonal = valuesPersonal ? valuesPersonal : getPersonalInfo.data
    const _valuesEducation = valuesEducation ? valuesEducation : getLastEducation.data
    _valuesEducation.candidateEducationId = getLastEducation.data.candidateEducationId

    const objCon = {
      contactDetail: _valuesContact,
      personal: _valuesPersonal,
      education: _valuesEducation,
      jobId: Number(jobId)
    }

    const validateObj = validatePost(objCon)
    
    return this.setState({ submitLoading: true }, () => {
      return actionApplyJob(validateObj, response => {
        if(response.code === '1000'){
          setTimeout(() => {
            return this.setState({ submitLoading: false, visible: false, visibleSuccess: true })
          }, actionGetJobDetail(params.id), 3000)
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onUpdate = async (values) => {
    const { editContact, editPersonal, editEducation, callingCodeId, callingCode, currentCityId, birthdate, nationalityId, countryId, provinceId, cityId, subDistrictId, villageId, educationLevelId, startYear, endYear } = this.state
    const { getContact, getPersonalInfo, getLastEducation } = this.props
    
    if(editContact){
      values.currentCityId = currentCityId || getContact.data.currentCityId
      values.callingCodeId = callingCodeId || getContact.data.callingCodeId
      values.mobilePhone = 
        values.mobilePhone.charAt(0) === '0' ? 
          values.mobilePhone.substring(1) 
        : 
          values.mobilePhone
      this.setState({
        callingCode: callingCode,
        valuesContact: values
      })
    }
    if(editPersonal){
      values.birthdate = birthdate || getPersonalInfo.data.birthdate
      values.nationalityId = nationalityId || getPersonalInfo.data.nationalityId
      values.countryId = countryId || getPersonalInfo.data.countryId
      values.provinceId = provinceId || getPersonalInfo.data.provinceId
      values.cityId = cityId || getPersonalInfo.data.cityId
      values.subDistrictId = subDistrictId || getPersonalInfo.data.cityId
      values.villageId = villageId || getPersonalInfo.data.villageId
      this.setState({
        valuesPersonal: values
      })
    }
    if(editEducation){
      values.educationLevelId = educationLevelId || getLastEducation.data.educationLevelId
      values.educationStartYear = startYear || getLastEducation.data.educationStartYear
      values.educationEndYear = endYear || getLastEducation.data.educationEndYear
      this.setState({
        valuesEducation: values,
      })
    }

    this.setState({
      editContact: false,
      editPersonal:false,
      editEducation: false
    })
  }

  handleCancelSuccess = () => {
    this.setState({ visibleSuccess: false });
  };

  handleFavorite = (id, isFavorite) => {
    const values = {
      jobId: id
    }

    const { actionAddOrDeleteFavorite, actionGetJobDetail, match: { params } } = this.props
    
    return this.setState({ submitLoading: true }, () => {
      return actionAddOrDeleteFavorite(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            message.success(isFavorite ? 'Job has been removed from your favorite' : 'Job has been added to favorites')
            return (actionGetJobDetail(params.id))
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { 
      submitLoading,
      visible, 
      visibleSuccess,
      current, 
      profilePicFile, 
      editAvatar, 
      onPreviewAvatar,
      textCode,
      callingCode,
      callingCodeId,
      countryName,
      currentCityName,
      currentCityId,
      isYear,
      educationLevelName,
      valuesContact,
      valuesPersonal,
      valuesEducation,
      editContact, 
      editPersonal,
      editEducation
    } = this.state

    const { getJobDetail } = this.props

    const initialProps = {
      submitLoading: submitLoading,
      showModal: this.showModal,
      handleCancel: this.handleCancel,
      visible: visible,
      current: current,
      prev: this.prev,
      onNext: this.onNext,
      onUpdate: this.onUpdate,
      onFinish: this.onFinish,
      handleUpload: this.handleUpload,
      formRef: this.formRef,
      profilePicFile: profilePicFile,
      editAvatar: editAvatar,
      onPreviewAvatar: onPreviewAvatar,
      textCode: textCode,
      callingCode: callingCode,
      callingCodeId: callingCodeId,
      countryName: countryName,
      currentCityName: currentCityName,
      currentCityId: currentCityId,
      isYear: isYear,
      educationLevelName: educationLevelName,
      valuesContact: valuesContact,
      valuesPersonal: valuesPersonal,
      valuesEducation: valuesEducation,
      editContact: editContact,
      editPersonal: editPersonal,
      editEducation: editEducation,
      handleCallingCode: this.handleCallingCode,
      handleCurrentCity: this.handleCurrentCity,
      onChangeDate: this.onChangeDate,
      handleNationality: this.handleNationality,
      handleCountry: this.handleCountry,
      handleProvince: this.handleProvince,
      handleCity: this.handleCity,
      handleSubDistrict: this.handleSubDistrict,
      handleVillage: this.handleVillage,
      onChangeEducationLevel: this.onChangeEducationLevel,
      onChangeStartYear: this.onChangeStartYear,
      onChangeEndYear: this.onChangeEndYear,
      handleEditContact: this.handleEditContact,
      handleEditPersonal: this.handleEditPersonal,
      handleEditEducation: this.handleEditEducation,
      handleFavorite: (id, isFavorite) => this.handleFavorite(id, isFavorite)
    }

    if(getJobDetail.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Modal
          visible={visibleSuccess}
          onCancel={this.handleCancelSuccess}
          footer={false}
          width={600}
          bodyStyle={{ padding: 0, height: 250 }}
        >
          <Row>
            <Col span={10}>
              <Image
                width={200}
                src={iconCheck}
                preview={false}
              />
            </Col>
            <Col span={14}>
              <Space className="mt-64" direction="vertical">
                <Space direction="vertical">
                  <Text className="fs-20 primary-color" strong>Success!</Text>
                  <Text className="fs-1 drk-color">{`Your application was sent to ${getJobDetail.data.companyName}`}</Text>
                </Space>
                <Text className="fs-13 txt-color">You can view your applications anytime in your
                  <Link className="ml-4 fs-13 a-color" to="/candidate/applications/applied">
                    application page
                  </Link>
                </Text>
              </Space>
            </Col>
          </Row>
        </Modal>
        <JobDetail {...initialProps} {...this.props} />
      </React.Fragment>
    )
  }
  // componentWillUnmount(){
  //   const { unmountDetailJob, unmountSteps, unmountContact, unmountPersonalInfo, unmountLastEducation } = this.props
  //   return (unmountDetailJob(), unmountSteps(), unmountContact(), unmountPersonalInfo(), unmountLastEducation())
  // }
}

const mapStateToProps = (state) => ({
  getJobDetail: state.jobDetailReducer,
  getSteps: state.stepsReducer,
  getContact: state.contactReducer,
  getPersonalInfo: state.personalInfoReducer,
  getLastEducation: state.lastEducationReducer,
})

const mapDispatchToProps = {
  actionGetJobDetail: detailJob,
  unmountDetailJob: unmountDetailJob,
  actionGetSteps: steps,
  unmountSteps: unmountSteps,
  actionGetContact: contact,
  unmountContact: unmountContact,
  actionGetPersonalInfo: personalInfo,
  unmountPersonalInfo: unmountPersonalInfo,
  actionGetLastEducation: lastEducation,
  unmountLastEducation: unmountLastEducation,
  actionApplyJob: applyJob,
  actionAddOrDeleteFavorite: addOrDeleteFavorite

}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateJobDetail)
