import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Loading } from '../../../components';
import { Row, Col, Space } from 'antd';
import { indexProfile, unmountIndexProfile } from '../../../redux/actions/profile/profileAction'
import Header from './Header';
import JobPreferences from './JobPreferences';
import AboutMe from './AboutMe';
import PersonalInfo from './PersonalInfo';
import WorkExperience from './WorkExperience';
import Education from './Education';
import Training from './Training';
import Completeness from './Completeness';
import Skill from './Skill';
import Portfolio from './Portfolio';
import Achievement from './Achievement';

export class Profile extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       nameVisible: null
    }
  }
  
  componentDidMount() {
    const { actionGetProfile } = this.props
    
    return actionGetProfile()
  }
  
  render() {
    const { nameVisible } = this.state
    const { getProfile: { data, loading }, actionGetProfile } = this.props
    
    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Profile</title>
        </Helmet>
        <Header data={data} actionGetProfile={actionGetProfile} />
        <Row className="mb-32">
          <Col span={20} offset={2}>
            <Row gutter={32}>
              <Col md={16} sm={24}>
                <Space className="full-width" direction="vertical" size={32}>
                  <JobPreferences data={data} actionGetProfile={actionGetProfile} visible={nameVisible === 'Job Preference' ? true : false} />
                  <AboutMe data={data} actionGetProfile={actionGetProfile} />
                  <PersonalInfo data={data} actionGetProfile={actionGetProfile} />
                  <WorkExperience data={data} actionGetProfile={actionGetProfile} />
                  <Education data={data} actionGetProfile={actionGetProfile} />
                  <Training data={data} actionGetProfile={actionGetProfile} />
                </Space>
              </Col>
              <Col className="full-width" md={8} sm={24}>
                <Space className="full-width" direction="vertical" size={32}>
                  <Completeness data={data} />
                  <Skill data={data} actionGetProfile={actionGetProfile} />
                  <Portfolio data={data} actionGetProfile={actionGetProfile} />
                  <Achievement data={data} actionGetProfile={actionGetProfile} />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexProfile } = this.props
    return unmountIndexProfile()
  }
}

const mapStateToProps = (state) => ({
  getProfile: state.profileReducer
})

const mapDispatchToProps = {
  actionGetProfile: indexProfile,
  unmountIndexProfile: unmountIndexProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)