import React from 'react';
import { Row, Col, Typography, Image, Space, Grid } from 'antd';
import jobseeker from '../../assets/img/jobseeker.png';
import HandIcon from '../../assets/img/HandIcon.png';
import CheckIcon from '../../assets/img/CheckIcon.png';
import FreeIcon from '../../assets/img/FreeIcon.png';
import './Home.css';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function jobIndustry() {
  const { md } = GetBreakPoint()

  return (
    <React.Fragment>
      <Row gutter={32} style={ md ? { background: '#fff', paddingTop: 100, paddingBottom: 40 } : { background: '#fff', paddingTop: 60, paddingBottom: 40 } }>
        <Col xs={24} md={12} className="full-width">
          <Image
            src={jobseeker}
            preview={false}
            width={'100%'}
          />
        </Col>
        {
          md ?
            <React.Fragment>
              <Col span={8} className="full-width">
                <Title className="primary-color" level={2} style={{ width: '70%' }}>The Fast Way to Get Your Dream Job</Title>
                <Space direction="vertical" size={16}>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-16 drk-color">Level up your career!</Text>
                    <Text className="fs-16 drk-color">Are you interested to work abroad in a developed country while also improving your skills?</Text>
                  </Space>
                  <Text className="fs-16 drk-color">Take the opportunity now. The experience awaits.</Text>
                  <Space className="full-width" direction="vertical">
                    <Space className="full-width">
                      <Image
                        src={HandIcon}
                        preview={false}
                        width={'100%'}
                      />
                      <Text className="fs-16 drk-color">All the jobs are trusted and valid</Text>
                    </Space>
                    <Space className="full-width">
                      <Image
                        src={CheckIcon}
                        preview={false}
                        width={'100%'}
                      />
                      <Text className="fs-16 drk-color">Our partner have a good reputation</Text>
                    </Space>
                    <Space className="full-width">
                      <Image
                        src={FreeIcon}
                        preview={false}
                        width={'100%'}
                      />
                      <Text className="fs-16 drk-color">Zero cost, No Fee and Register for free</Text>
                    </Space>
                  </Space>
                </Space>
              </Col>
              <Col span={4}></Col>
            </React.Fragment>
          :
            <Row className="full-width" style={{ paddingTop: 40 }}>
              <Col span={20} offset={2}>
                <Title className="primary-color" level={2}>The Fast Way to Get Your Dream Job</Title>
              </Col>
              <Col span={20} offset={2}>
                <Space direction="vertical" size={0}>
                  <Text className="fs-16 drk-color">Level up your career!</Text>
                  <Text className="fs-16 drk-color">Are you interested to work abroad in a developed country while also improving your skills?</Text>
                </Space>
              </Col>
              <Col className="mb-16" span={20} offset={2}>
                <Text className="fs-16 drk-color">Take the opportunity now. The experience awaits.</Text>
              </Col>
              <Col className="txt-algn-center mb-16" span={20} offset={2}>
                <Space className="full-width" direction="vertical">
                  <Image
                    src={HandIcon}
                    preview={false}
                    width={'20%'}
                  />
                  <Text className="fs-16 drk-color">All the jobs are trusted and valid</Text>
                </Space>
              </Col>
              <Col className="txt-algn-center mb-16" span={20} offset={2}>
                <Space className="full-width" direction="vertical">
                  <Image
                    src={CheckIcon}
                    preview={false}
                    width={'20%'}
                  />
                  <Text className="fs-16 drk-color">Our partner have a good reputation</Text>
                </Space>
              </Col>
              <Col className="txt-algn-center" span={20} offset={2}>
                <Space className="full-width" direction="vertical">
                  <Image
                    src={FreeIcon}
                    preview={false}
                    width={'20%'}
                  />
                  <Text className="fs-16 drk-color">Zero cost, No Fee and Register for free</Text>
                </Space>
              </Col>
            </Row>
        }
      </Row>
    </React.Fragment>
  )
}
