import React from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import EmptyLogo from '../../assets/img/EmptyLogo.png';
import ContactIcon from '../../assets/img/ContactIcon.png';
import { Row, Col, Typography, Card, Image, Form, Input, Button, Space, Affix, Select, Grid } from 'antd';
import './Home.css';
import { SearchOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function companies(props) {
  const { getDataLanding, getJobDestination, formRef, handleDestination, onFinish, history } = props
  const { xl } = GetBreakPoint()

  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    localStorage.setItem('visible', true);
    history.push('/login-or-register')
  }

  return (
    <React.Fragment>
      <Row style={{ marginTop: -50, background: '#fff' }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }} className="full-width">
          <Card className="br-6 box-shadow" bordered={false}>
            <Form ref={formRef} onFinish={onFinish} layout="vertical">
              <Row gutter={16}>
                <Col xs={24} md={10}>
                  <Form.Item name="search" label="Job Title or Position">
                    <Input prefix={<SearchOutlined />} placeholder="Search" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={10}>
                  <Form.Item name="location" label="Country or City">
                    <Select 
                      className="br-6"
                      allowClear 
                      showSearch
                      onChange={handleDestination}
                    >
                      {
                        getJobDestination?.data?.map((item, i) => 
                          <OptGroup key={i} label={item.name}>
                            {
                              item?.listDestination?.map((res) => 
                                <Option key={res.id} value={res.name}>{res.name}</Option>
                              )
                            }
                          </OptGroup>
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={4}>
                  <Form.Item style={{ marginTop: 30 }}>
                    <Button htmlType="submit" type="primary" style={{ width: '100%' }}>Search</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Affix offsetTop={620} style={ xl ? { marginLeft: '6%' } : { marginLeft: '80%', marginTop: 20 } }>
          <a href="https://api.whatsapp.com/send?phone=62816822122&text=" target="_blank" rel="noreferrer">
            <Image
              className="c-pointer"
              src={ContactIcon}
              preview={false}
              width={50}
              height={50}
              style={{ objectFit: 'contain' }}
            />
          </a>
        </Affix>
      </Row>
      <Row style={{ background: '#fff' }}>
        <Col span={24}>
          <Title className="title-header-companies primary-color" level={2}>Companies on Jobshub</Title>
          <Row>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
              <Row gutter={[32, 32]}>
                {
                  getDataLanding.data.listCompany.map((item, i) =>
                    <Col className="txt-algn-center" key={i} xs={24} sm={12} lg={6}>
                      <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                        <Image
                          className="c-pointer"
                          src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                          preview={false}
                          width={196}
                          height={196}
                          style={{ objectFit: 'contain' }}
                        />
                      </Link>
                    </Col>
                  )
                }
              </Row>
            </Col>  
          </Row>
          <Title className="title-content-1 primary-color" level={2} style={{ height: 160 }}>Featured Jobs</Title>
          <Row style={{ background: '#f1f1f1' }}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
              <Row gutter={[16, 16]}>
                {
                  getDataLanding.data.listJob.map((item, i) =>
                    <Col className="featured-jobs txt-algn-center" key={i} xs={24} lg={8}>
                      <Card 
                        className="br-6 box-shadow full-width" 
                        bordered={false} 
                        style={{ marginTop: -60, height: 340 }} 
                      >
                        <Space className="full-width" direction="vertical" size={4}>
                          <Link to={`/company/${item.companyId}/${item.companyType}`}>
                            <Image
                              className="c-pointer"
                              src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                              preview={false}
                              width={100}
                              height={100}
                              style={{ objectFit: 'contain' }}
                            />
                          </Link>
                          <Link to={`/job/detail/${item.jobId}`}>
                            <Text className="title-job fs-18 drk-color" strong>{item.jobTitle}</Text>
                          </Link>
                          <Space direction="vertical" size={-4}>
                            <Link to={`/company/${item.companyId}/${item.companyType}`}>
                              <Text className="company-job fs-17 drk-color" strong>{item.companyName}</Text>
                            </Link>
                            <Text className="fs-16 drk-color">{item.jobDestinationParentName ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                          </Space>
                          <Text className="fs-16 primary-color">
                            {
                              item.isDisplaySalary ?
                                item.maxSalary ? 
                                  `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                : 
                                  `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                              :
                                null
                            }
                          </Text>
                          <Text className="drk-color fs-15">{item.agencyName ? `Recruited by ${item.agencyName}` : null}</Text>
                          <Space className="full-width" direction="vertical" align="center">
                            <Button className="br-6" type="primary" style={{ width: 100 }} onClick={() => onClickApply(`/candidate/job/detail/${item.jobId}`)}>Apply</Button>
                          </Space>
                        </Space>
                      </Card>
                    </Col>
                  )
                }
              </Row>
              <Link to="/job">
                <Title className="title-content-1 fw-normal primary-color pb-16" level={4}>Explore All</Title>
              </Link>
            </Col>  
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
