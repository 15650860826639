let env = "https://stg-api-fo.jobshub.id/api"

if(process.env.REACT_APP_ENV === "development"){
  env = "https://stg-api-fo.jobshub.id/api"
}

if(process.env.REACT_APP_ENV === "production"){
  env = "https://stg-api-fo.jobshub.id/api"
}

export default env;