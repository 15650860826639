import React, { Component } from 'react'
import { connect } from 'react-redux'
import CompanyComponent from '../../components/Company'
import { dataCompany, unmountDataCompany } from '../../redux/actions/company/companyAction'
import { listJobCompany, unmountListJob } from '../../redux/actions/job/jobAction'

export class Company extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      paramsJob: {
        page: 1,
        perpage: 5,
        search: null
      }
    }
  }
  
  componentDidMount() {
    const { paramsJob: { page, perpage, search } } = this.state
    const { actionGetCompany, actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: page,
      perpage: perpage,
      search: search
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }
    
    this.setState({ paramsJob })

    return (actionGetCompany(params.id, params.companyType), actionGetJob(paramsJob))
  }

  onSearch = (value) => {
    const { paramsJob: { page, perpage } } = this.state
    const { actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: page,
      perpage: perpage,
      search: value
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }

    this.setState({ paramsJob })
    return actionGetJob(paramsJob)
  }

  onChangePagination = (pageNumber) => {
    const { paramsJob: { perpage, search } } = this.state
    const { actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: pageNumber,
      perpage: perpage,
      search: search
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }

    this.setState({ paramsJob })
    return actionGetJob(paramsJob)
  }

  render() {
    const initialProps = {
      onSearch: this.onSearch,
      onChangePagination: this.onChangePagination
    }
    return (
      <CompanyComponent {...initialProps} {...this.props} />
    )
  }
  componentWillUnmount(){
    const { unmountDataCompany, unmountListJob } = this.props
    return (unmountDataCompany(), unmountListJob())
  }
}

const mapStateToProps = (state) => ({
  getCompany: state.companyReducer,
  getJob: state.jobReducer
})

const mapDispatchToProps = {
  actionGetCompany: dataCompany,
  unmountDataCompany: unmountDataCompany,
  actionGetJob: listJobCompany,
  unmountListJob: unmountListJob
}

export default connect(mapStateToProps, mapDispatchToProps)(Company)