import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';
import { Row, Col, Avatar, Typography, Card, Button, Space, Divider, BackTop, Modal, Steps, Form, Skeleton } from 'antd';
import '../Detail.css';
import Contact from './contact'
import PersonalInfo from './personalInfo'
import Education from './education'
import Review from './review'
import { CheckOutlined, StarOutlined, StarFilled } from '@ant-design/icons';

const { Text } = Typography;
const { Step } = Steps;

function GetHistory() {
  const history = useHistory()
  return history;
}

const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`)
  const Component = icons[type]
  return <Component {...rest} />
}

export default function DesDetailJob(initialProps) {
  const {
    submitLoading,
    getJobDetail: { data },
    showModal,
    getSteps,
    handleCancel,
    visible,
    current,
    prev,
    id,
    onNext,
    onFinish,
    onUpdate,
    formRef,
    editContact,
    editPersonal,
    editEducation,
    handleFavorite
  } = initialProps
  
  const jobDestination = 
    data.jobDestinationParentId ? 
      `${data.jobDestinationName}, ${data.jobDestinationParentName}` 
    : 
      data.jobDestinationName
  const salary = 
    data.isDisplaySalary ? 
      data.isFixSalary ? 
        `${data.salary ? data.salary.toLocaleString() : null} ${data.currencyCode}`
      : 
        `${data.salary ? data.salary.toLocaleString() : null} - ${data.maxSalary ? data.maxSalary.toLocaleString() : null} ${data.currencyCode}` 
    :
      'Salary not disclosed'
  const targetDate = data.targetDate ? `Apply before ${data.targetDate}` : null
  const placementType = 
    data.placementType === 'INTERNATIONAL' ?
      'International'
    :
      'Domestic'
  const experienceLevel = 
    data.experienceLevel === 'ENTRY_LEVEL' ?
      'Entry Level'
    : data.experienceLevel === 'ASSOCIATE' ?
      'Associate'
    : data.experienceLevel === 'MID_SENIOR' ?
      'Mid-Senior Level'
    : data.experienceLevel === 'DIRECTOR' ?
      'Director'
    :
      'Executive'
  const jobType = 
    data.jobType === 'FULL_TIME' ? 
      'Full Time' 
    : data.jobType === 'PART_TIME' ?
      'Part Time'
    : data.jobType === 'FREELANCE' ?
      'Freelance'
    : data.jobType === 'INTERNSHIP' ?
      'Internship'
    :
      '-'
  const paymentType =
    data.paymentType === 'DAILY' ?
      'Daily'
    : data.paymentType === 'WEEKLY' ?
      'Weekly'
    : data.paymentType === 'MONTHLY' ?
      'Monthly'
    : data.paymentType === 'YEARLY' ?
      'Yearly'
    :
      'Per Project'
  const isRemote = data.isRemote ? '(Possible to work from home)' : null
  const isContractExtendable = data.isContractExtendable ? '(Extendable)' : '(No Extendable)'
  const isPaidAnnualLeave = data.isPaidAnnualLeave ? '(Paid)' : '(Unpaid)'
  const isWorkingOvertime = data.isWorkingOvertime ? 'Yes' : 'No'
  const gender = 
    data.gender === 'BOTH' ? 
      'Male and Female' 
    : data.gender === 'MALE' ? 
      'Male' 
    : 
      'Female'
  const skill = data.skill ? data.skill.join(', ') : data.skill

  const history = GetHistory()

  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    localStorage.setItem('visible', true);
    history.push('/login-or-register')
  }
  
  if(Cookie.get('user') || getSteps?.loading){
    <Skeleton active />
  }

  return (
    <React.Fragment>
      <BackTop />
      <Row gutter={32}>
        <Col span={18}>
          <Card 
            className="mb-16 br-6 box-shadow"
            bordered={false} 
          >
            <Row className="full-width" gutter={32}>
              <Col span={17}>
                <Space size={16}>
                  <Link to={Cookie.get('user') ? `/candidate/company/${data.companyId}/${data.companyType}` : `/company/${data.companyId}/${data.companyType}`}>
                    <Avatar className="c-pointer" src={data.companyLogoUrl ? data.companyLogoUrl : EmptyLogo} shape="square" size={{ sm: 80, md: 80, lg: 80, xl: 80, xxl: 100 }} style={{ height: 'auto' }} />
                  </Link>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-15 drk-color" strong>{data.title}</Text>
                    <Link to={Cookie.get('user') ? `/candidate/company/${data.companyId}/${data.companyType}` : `/company/${data.companyId}/${data.companyType}`}>
                      <Text className="title-job fs-13 primary-color c-pointer">{data.companyName}</Text>
                    </Link>
                    <Text className="fs-13 drk-color">{jobDestination}</Text>
                    <Text className="fs-13 drk-color">{data.jobIndustryName}</Text>
                    <Text className="fs-13 primary-color">{salary}</Text>
                    <Text className="fs-13 drk-color">{data.agencyName ? `Recruited by ${data.agencyName}` : null}</Text>
                  </Space>
                </Space>
              </Col>
              <Col span={7}>
                <Space direction="vertical" size={10} className="f-right">
                  <Text className="f-right fs-11 drk-color">{`Posted on ${data.dateOpened}`}</Text>
                  <Text className="f-right fs-11 primary-color">{targetDate}</Text>
                </Space>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Space className="f-right" size={8}>
                  {
                    Cookie.get('user') ?
                      <Button type="link" onClick={() => handleFavorite(data.id, data.isFavorite)}>{data.isFavorite ? <StarFilled className="fs-16" /> : <StarOutlined className="fs-16" />} Favorite</Button>
                    :
                      null
                  }
                  {
                    data.isApply ?
                      <Text className="f-right fs-14 primary-color mr-32"><CheckOutlined className="fs-16" /> Applied</Text>
                    :
                      <Button type="primary" className="full-width f-right br-6" onClick={Cookie.get('user') ? showModal : () => onClickApply(`/candidate/job/detail/${id}`)}>Apply Now</Button>
                  }
                </Space>
                <Modal 
                  width={1000} 
                  title={`Apply to ${data.companyName}`} 
                  visible={visible} 
                  onCancel={handleCancel}
                  footer={false}
                  maskClosable={false}
                  destroyOnClose
                >
                    {
                      getSteps?.data?.listStepsApply.length > 1 ?
                        <Steps className="mb-16" size="small" current={current}>
                          {
                            getSteps?.data?.listStepsApply.map((item, i) => 
                              <Step key={i} title={item.name} icon={<Icon type={item.icon} />} />
                            )
                          }
                        </Steps>
                      :
                        null
                    }
                    <Form 
                      layout="vertical"
                      ref={formRef}
                      onFinish={
                        editContact || editPersonal || editEducation ?
                          onUpdate
                        : getSteps?.data?.listStepsApply[current].name === 'Review' ? 
                          onFinish 
                        : onNext
                      }
                    >
                      {
                        getSteps?.data?.listStepsApply[current].name === 'Contact Detail' ?
                          <Contact {...initialProps} />
                        :
                          null
                      }
                      {
                        getSteps?.data?.listStepsApply[current].name === 'Personal Info' ?
                          <PersonalInfo {...initialProps} />
                        :
                          null
                      }
                      {
                        getSteps?.data?.listStepsApply[current].name === 'Education' ?
                          <Education {...initialProps} />
                        :
                          null
                      }
                      {
                        getSteps?.data?.listStepsApply[current].name === 'Review' ?
                          <Review {...initialProps} />
                        :
                          null
                      }
                      <Divider />
                      <Row>
                        <Col span={24}>
                          <Space className="f-right">
                            {
                              current > 0 ?
                                editContact || editPersonal || editEducation || getSteps?.data?.listStepsApply[current].name === 'Review' ?
                                  null
                                :
                                  <Button key="back" type="link" onClick={prev}>
                                    Back
                                  </Button>
                              :
                                null
                            }
                            <Button key="next" type="primary" htmlType="submit" loading={submitLoading}>
                              {
                                editContact || editPersonal || editEducation ?
                                  'Update'
                                : getSteps?.data?.listStepsApply[current].name === 'Education' ?
                                  'Review'
                                : getSteps?.data?.listStepsApply[current].name === 'Review' ? 
                                  'Submit' 
                                :
                                  'Next'
                              }
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                </Modal>
              </Col>
            </Row>
          </Card>
          
          <Card 
            className="mb-16 br-6 box-shadow" 
            bordered={false}
          >
            <Row className="mb-16">
              <Col span={12}>
                <Space direction="vertical">
                  <Space direction="vertical" size={0}>
                    <Text className="fs-12 drk-color">Position</Text>
                    <Text className="drk-color" strong>{data.jobPositionName}</Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-12 drk-color">Placement Type</Text>
                    <Text className="drk-color" strong>{placementType}</Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-12 drk-color">Experience Level</Text>
                    <Text className="drk-color" strong>{experienceLevel}</Text>
                  </Space>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical">
                  <Space direction="vertical" size={0}>
                    <Text className="fs-12 drk-color">Job Type</Text>
                    <Text className="drk-color" strong>{jobType} {isRemote}</Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-12 drk-color">Payment Type</Text>
                    <Text className="drk-color" strong>{paymentType}</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Card>
          
          {
            data.jobDescription ?
              <Card 
                className="mb-16 br-6 box-shadow" 
                bordered={false}
              >
                <Row className="mb-16">
                  <Col span={24}>
                    <Space direction="vertical" size={16}>
                      <Text className="fs-15 drk-color" strong>Job Description</Text>
                      {
                        data.jobDescription ?
                          <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.jobDescription }}></div>
                        :
                          null
                      }
                    </Space>
                  </Col>
                </Row>
              </Card>
            :
              null
          }

          {
            data.placementType === 'INTERNATIONAL' ?
              <Card 
                title="Terms and Conditions"
                className="mb-16 br-6 box-shadow" 
                bordered={false}
                headStyle={{ color: '#5f6163', fontWeight: 'bold' }}
              >
                <Row className="mb-16">
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Duration of Contract (Years)</Text>
                        <Space size={4}>
                          <Text className="drk-color" strong>{data.durationOfContract}</Text>
                          <Text className="drk-color">{isContractExtendable}</Text>
                        </Space>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Working Days (Per Week)</Text>
                        <Text className="drk-color" strong>{data.workingDay}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Probation Period (Months)</Text>
                        <Text className="drk-color" strong>{data.probationPeriod}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Anual Leave (Days)</Text>
                        <Space size={4}>
                          <Text className="drk-color" strong>{data.annualLeave}</Text>
                          <Text className="drk-color">{isPaidAnnualLeave}</Text>
                        </Space>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Working Hours (Per Day)</Text>
                        <Text className="drk-color" strong>{data.workingHour}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Working Overtime</Text>
                        <Text className="drk-color" strong>{isWorkingOvertime}</Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Card>
            :
              null
          }

          {
            data.placementType === 'INTERNATIONAL' ?
              data.listBenefit.length > 0 ?
                <Card 
                  title="Job Benefit"
                  className="mb-16 br-6 box-shadow" 
                  bordered={false}
                  headStyle={{ color: '#5f6163', fontWeight: 'bold' }}
                >
                  <Row className="mb-16">
                    <Col span={24}>
                      <Space direction="vertical">
                        {
                          data.listBenefit.map((item, i) => 
                            <Space key={i} direction="vertical" size={0}>
                              <Text className="drk-color" strong>{item.name}</Text>
                              {
                                item.status ?
                                  <Space size={4}>
                                    <Text className="fs-12 drk-color" type="secondary">Allowance,</Text>
                                    <Text className="fs-12 primary-color">{`${item.allowance.toLocaleString()} ${data.currencyCode}`}</Text>
                                  </Space>
                                :
                                  <Text className="fs-12 drk-color">Provided</Text>
                              }
                            </Space>
                          )
                        }
                      </Space>
                    </Col>
                  </Row>
                </Card>
              :
                null
            :
              data.benefit ?
                <Card 
                  className="mb-16 br-6 box-shadow" 
                  bordered={false}
                >
                  <Row className="mb-16">
                    <Col span={24}>
                      <Space direction="vertical" size={16}>
                        <Text className="fs-15 drk-color" strong>Job Benefit</Text>
                        {
                          data.jobDescription ?
                            <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.benefit }}></div>
                          :
                            null
                        }
                      </Space>
                    </Col>
                  </Row>
                </Card>
              :
                null
          }
          {
            data.placementType === 'INTERNATIONAL' ?
              <Card 
                title="Candidate Requirements"
                className="mb-16 br-6 box-shadow" 
                bordered={false}
                headStyle={{ color: '#5f6163', fontWeight: 'bold' }}
              >
                <Row className="mb-16">
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Gender</Text>
                        <Text className="drk-color" strong>{gender}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Minimum Education Level</Text>
                        <Text className="drk-color" strong>{data.educationLevelName}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Skills</Text>
                        <Text className="drk-color" strong>{skill}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Age Range</Text>
                        <Text className="drk-color" strong>{`${data.ageStart} - ${data.ageEnd}`}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12 drk-color">Fields of Study</Text>
                        <Text className="drk-color" strong>{data.fieldOfStudy}</Text>
                      </Space>
                    </Space>
                  </Col>
                  {
                    data.listOtherRequirement ?
                      data.listOtherRequirement.map((item, i) => 
                        <Col key={i} span={24}>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 drk-color">{item.children}</Text>
                            <Text className="drk-color" strong>{data[item.value]}</Text>
                          </Space>
                        </Col>
                      )
                    :
                      null
                  }
                </Row>
              </Card>
            :
              data.jobRequirement ?
                <Card 
                  className="mb-16 br-6 box-shadow" 
                  bordered={false}
                >
                  <Row className="mb-16">
                    <Col span={24}>
                      <Space direction="vertical" size={16}>
                        <Text className="fs-15 drk-color" strong>Job Requirement</Text>
                        {
                          data.jobDescription ?
                            <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.jobRequirement }}></div>
                          :
                            null
                        }
                      </Space>
                    </Col>
                  </Row>
                </Card>
              :
                null

          }

          <Card 
            className="mb-16 br-6 box-shadow" 
            bordered={false}
            headStyle={{ color: '#5f6163', fontWeight: 'bold' }}
          >
            <Link to={Cookie.get('user') ? '/candidate/job' : '/job'}>
              <Button className="br-6" type="primary" size="large" ghost>Browse all jobs</Button>
            </Link>
          </Card>
        </Col>
        <Col span={6}>
          <Space direction="vertical">
            <Space className="mb-32" direction="vertical">
              <Space direction="vertical" size={16}>
                <Text className="fs-15 drk-color" strong>Company Overview</Text>
                <Text className="fs-13 drk-color">{data.companyAbout}</Text>
              </Space>
              <Space direction="vertical" size={0}>
                <Text className="fs-13 drk-color">Location</Text>
                <Text className="drk-color" strong>{data.companyCityName ? `${data.companyCityName}, ${data.companyCountryName}` : data.companyCountryName}</Text>
              </Space>
              <Space direction="vertical" size={0}>
                <Text className="fs-13 drk-color">Industry</Text>
                <Text className="drk-color" strong>{data.companyJobIndustryTitle ? data.companyJobIndustryTitle : '-'}</Text>
              </Space>
              <Space direction="vertical" size={0}>
                <Text className="fs-13 drk-color">Employees</Text>
                <Text className="drk-color" strong>{data.companyTotalEmployee ? data.companyTotalEmployee : '-'}</Text>
              </Space>
              <Space direction="vertical" size={0}>
                <Text className="fs-13 drk-color">Website</Text>
                {
                  data.companyWebsite ?
                    <a className="a-color" href={data.companyWebsite.slice(0,8) === 'https://' || data.companyWebsite.slice(0,7) === 'http://' ? data.companyWebsite : `http://${data.companyWebsite}`} target="_blank" rel="noreferrer">
                      {data.companyWebsite}
                    </a>
                  :
                    <Text className="drk-color" strong>-</Text>
                }
              </Space>
              {/* <Divider style={{ margin: 0 }} />
              <Space className="c-pointer">
                <Text className="fs-16 a-color">More jobs from this employer</Text>
                <RightCircleOutlined className="fs-16 a-color" />
              </Space> */}
            </Space>

            {
              data.listJobSimiliar.length > 0 ?
                <Space direction="vertical">
                  <Text className="fs-15 drk-color" strong>Similar Jobs</Text>
                  <Divider style={{ margin: 0 }} />
                  {
                    data.listJobSimiliar.map((item, i) => 
                      <Space key={i} size={16}>
                        <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                          <Avatar src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo} shape="square" size={{ xs: 40, sm: 40, md: 40, lg: 40, xl: 60, xxl: 80 }} />
                        </Link>
                        <Space direction="vertical" size={-4}>
                          <a className="c-pointer" href={Cookie.get('user') ? `/candidate/job/detail/${item.id}` : `/job/detail/${item.id}`}>
                            <Text className="fs-14 drk-color" strong>{item.title}</Text>
                          </a>
                          <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                            <Text className="fs-12 primary-color">{item.companyName}</Text>
                          </Link>
                          <Text className="fs-12 primary-color">
                            {
                              item.isDisplaySalary ? 
                                item.isFixSalary ? 
                                  `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                                : 
                                  `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                              :
                                'Salary not disclosed'
                            }
                          </Text>
                          <Text className="txt-color fs-12">{item.agencyName ? `Recruited by ${item.agencyName}` : null}</Text>
                        </Space>
                      </Space>
                    )
                  }
                </Space>
              :
                null
            }
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  )
}