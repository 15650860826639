import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Card, Typography, Modal, Form, Input, Space, Button, Row, Col, Checkbox, DatePicker, Select, Timeline, Badge, Grid, Spin, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { listCity, unmountListCity } from '../../../../redux/actions/master/city/cityAction';
import { addWorkExperience, updateWorkExperience, deleteWorkExperience } from '../../../../redux/actions/profile/workExperienceAction';
import './WorkExperience.css';
import moment from 'moment';

const { useBreakpoint } = Grid;

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}
export const WorkExperienceForm = ({data, visible, submitLoading, deleteLoading, current, getCity, showModal, handleCancel, onSearchCity, onFocusCity, handleCity, onChangeStartDate, onChangeEndDate, handleChecked, formRef, onFinish, isDate, id, dataEdit, handleDelete}) => {
  const { md } = GetBreakPoint()
  
  return (
    <React.Fragment>
      <Card 
        className="br-6 box-shadow" 
        title="Work Experience" 
        bordered={false} 
        headStyle={{ color: '#32ab6d', fontSize: 17 }}
        bodyStyle={data.listWorkExperience.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
        actions={[
          <Text className="primary-color" onClick={() => showModal(null)}>Add work experience</Text>
        ]}
      >
        {
          data.listWorkExperience.length > 0 ?
            <Timeline>
                {
                  data.listWorkExperience.map((item, i) => (
                    md ?
                      <Timeline.Item key={i} color="#0076de">
                        <Row gutter={8}>
                          <Col span={8}>
                            <Text className="text-year">
                              {`${item.startDate ? moment(item.startDate).format('MMM YYYY') : 'N/A'} - ${item.current ? 'Present' : item.endDate ? moment(item.endDate).format('MMM YYYY') : 'N/A'}`}
                            </Text>
                          </Col>
                          <Col className="pr-8" span={12}>
                            <Space direction="vertical" size={0}>
                              <Space>
                                <Text className="text-title">{item.title}</Text>
                                {
                                  item.employmentType ? 
                                    <React.Fragment>
                                      <Badge className="dot" color="#848484" />
                                      <Text className="text-type">
                                        {
                                          item.employmentType === 'FULL_TIME' ? 
                                            'Full Time' 
                                          : item.employmentType === 'PART_TIME' ?
                                            'Part Time'
                                          : item.employmentType === 'FREELANCE' ?
                                            'Freelance'
                                          :
                                            'Part Time'
                                        }
                                      </Text>
                                    </React.Fragment>
                                  :
                                    null
                                }
                              </Space>
                              <Text className="text-loc">{item.cityName ? `${item.company}, ${item.cityName}` : item.company}</Text>
                              <Text className="txt-color">{item.jobDescription}</Text>
                            </Space>
                          </Col>
                          <Col span={4}>
                            <Text className="primary-color f-right c-pointer" onClick={() => (showModal(item.id))}><EditFilled /> Edit</Text>
                          </Col>
                        </Row>
                      </Timeline.Item>
                    :
                      <Timeline.Item key={i} color="#0076de">
                        <Row>
                          <Col span={20}>
                            <Text className="text-year">
                              {`${item.startDate ? moment(item.startDate).format('MMM YYYY') : 'N/A'} - ${item.current ? 'Present' : item.endDate ? moment(item.endDate).format('MMM YYYY') : 'N/A'}`}
                            </Text>
                          </Col>
                          <Col span={4}>
                            <Text className="primary-color c-pointer" onClick={() => (showModal(item.id))}><EditFilled /> Edit</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-8" span={24}>
                            <Space direction="vertical" size={0}>
                              <Space>
                                <Text className="text-title">{item.title}</Text>
                                {
                                  item.employmentType ? 
                                    <React.Fragment>
                                      <Badge className="dot" color="#848484" />
                                      <Text className="text-type">
                                        {
                                          item.employmentType === 'FULL_TIME' ? 
                                            'Full Time' 
                                          : item.employmentType === 'PART_TIME' ?
                                            'Part Time'
                                          : item.employmentType === 'FREELANCE' ?
                                            'Freelance'
                                          :
                                            'Part Time'
                                        }
                                      </Text>
                                    </React.Fragment>
                                  :
                                    null
                                }
                              </Space>
                              <Text className="text-loc">{item.cityName ? `${item.company}, ${item.cityName}` : item.company}</Text>
                              <Text className="txt-color">{item.jobDescription}</Text>
                            </Space>
                          </Col>
                        </Row>
                      </Timeline.Item>
                  ))
                }
            </Timeline>
          :
            <Text className="txt-color">Companies consider work experience to be a crucial viewpoint in job applications</Text>
        }
      </Card>

      <Modal 
        title="Work Experience" 
        visible={visible} 
        onCancel={handleCancel} 
        footer={false}
        bodyStyle={{ paddingBottom: 4 }}
        centered
        width={600}
        destroyOnClose
      >
        <Form 
          ref={formRef}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            title: id ? dataEdit?.title : null,
            company: id ? dataEdit?.company : null,
            employmentType: id ? dataEdit?.employmentType : null,
            city: id && dataEdit?.cityName  ? `${dataEdit?.cityName}, ${dataEdit?.countryName}` : null,
            startDate: id && dataEdit?.startDate ? moment(dataEdit?.startDate) : null,
            endDate:  id && dataEdit?.endDate ? moment(dataEdit?.endDate) : null,
            jobDescription: id ? dataEdit?.jobDescription : null
          }}
        >
          {
            md ?
              <React.Fragment>
                <Row className="mb-min-8" gutter={8}>
                  <Col className="full-width" span={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">Title</Text>
                    </Space>
                    <Form.Item 
                      name="title"
                      validateFirst
                      rules={[
                        { required: true,  message: 'Title is required!' },
                        { min: 5, message: 'Title must be at least 5 characters' },
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input  size="small" />
                    </Form.Item>
                  </Col>
                  <Col className="full-width" span={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">Company</Text>
                    </Space>
                    <Form.Item 
                      name="company"
                      validateFirst
                      rules={[
                        { required: true,  message: 'Company is required!' },
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8" gutter={8}>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">Employment Type</Text>
                    <Form.Item name="employmentType">
                      <Select 
                        className="full-width br-6"
                        size="small" 
                      >
                        <Option key="1" value="FULL_TIME">Full Time</Option>
                        <Option key="2" value="PART_TIME">Part Time</Option>
                        <Option key="3" value="FREELANCE">Freelance</Option>
                        <Option key="4" value="INTERNSHIP">Internship</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">Location</Text>
                    <Form.Item name="city">
                      <Select 
                        className="full-width" 
                        size="small" 
                        showSearch
                        onFocus={onFocusCity}
                        onSearch={onSearchCity}
                        onChange={handleCity}
                        placeholder="type a city (e.g.: Jakarta Selatan)"
                        notFoundContent={getCity.loading ? <Spin size="small" /> : null}
                      >
                        {
                          getCity?.data?.map(item => 
                            <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                            )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="full-width" md={6} sm={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">Start Date</Text>
                    </Space>
                  </Col>
                  <Col className="full-width" md={6} sm={12}>
                    <Space size={4}>
                      {
                        !current ?
                          <Text className="fs-12" type="danger">*</Text>
                        :
                          null
                      }
                      <Text className="fs-12" type="secondary">End Date</Text>
                    </Space>
                  </Col>
                </Row>
                <Row className="mb-min-8" gutter={8}>
                  <Col className="full-width" md={6} sm={12}>
                    <Form.Item 
                      name="startDate"
                      rules={[
                        { required: true,  message: 'Start Date is required!' }
                      ]}
                    >
                      <DatePicker className="full-width" picker="month" size="small" placeholder="YYYY-MM" onChange={onChangeStartDate} />
                    </Form.Item>
                  </Col>
                  <Col className="full-width" md={6} sm={12}>
                    <Form.Item 
                      name="endDate"
                      rules={[
                        { required: !current,  message: 'End Date is required!' }
                      ]}
                    >
                      {
                        current ?
                          <Text className="fs-12" type="secondary">Present</Text>
                        :
                          <DatePicker className="full-width" picker="month" size="small" placeholder="YYYY-MM" onChange={onChangeEndDate} />
                      }
                    </Form.Item>
                  </Col>
                  <Col className="full-width" md={12}>
                    <Form.Item name="current">
                      <Checkbox className="fs-12 txt-color" onChange={handleChecked} checked={current}>I’m currently working in this role</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            :
              <React.Fragment>
                <Row className="mb-min-8">
                  <Col className="full-width" span={24}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">Title</Text>
                    </Space>
                    <Form.Item 
                      name="title"
                      validateFirst
                      rules={[
                        { required: true,  message: 'Title is required!' },
                        { min: 5, message: 'Title must be at least 5 characters' },
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input  size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8">
                  <Col className="full-width" span={24}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">Company</Text>
                    </Space>
                    <Form.Item 
                      name="company"
                      validateFirst
                      rules={[
                        { required: true,  message: 'Company is required!' },
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8">
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">Employment Type</Text>
                    <Form.Item name="employmentType">
                      <Select 
                        className="full-width br-6"
                        size="small" 
                      >
                        <Option key="1" value="FULL_TIME">Full Time</Option>
                        <Option key="2" value="PART_TIME">Part Time</Option>
                        <Option key="3" value="FREELANCE">Freelance</Option>
                        <Option key="4" value="INTERNSHIP">Internship</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8">
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">Location</Text>
                    <Form.Item name="city">
                      <Select 
                        className="full-width" 
                        size="small" 
                        showSearch
                        onFocus={onFocusCity}
                        onSearch={onSearchCity}
                        onChange={handleCity}
                        placeholder="type a city (e.g.: Jakarta Selatan)"
                        notFoundContent={getCity.loading ? <Spin size="small" /> : null}
                      >
                        {
                          getCity?.data?.map(item => 
                            <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                            )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8" gutter={8}>
                  <Col className="full-width" span={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">Start Date</Text>
                    </Space>
                    <Form.Item 
                      name="startDate"
                      rules={[
                        { required: true,  message: 'Start Date is required!' }
                      ]}
                    >
                      <DatePicker className="full-width" picker="month" size="small" placeholder="YYYY-MM" onChange={onChangeStartDate} />
                    </Form.Item>
                  </Col>
                  <Col className="full-width" span={12}>
                    <Space size={4}>
                      {
                        !current ?
                          <Text className="fs-12" type="danger">*</Text>
                        :
                          null
                      }
                      <Text className="fs-12" type="secondary">End Date</Text>
                    </Space>
                    <Form.Item 
                      name="endDate"
                      rules={[
                        { required: !current,  message: 'End Date is required!' }
                      ]}
                    >
                      {
                        current ?
                          <Text className="fs-12" type="secondary">Present</Text>
                        :
                          <DatePicker className="full-width" picker="month" size="small" placeholder="YYYY-MM" onChange={onChangeEndDate} />
                      }
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8">
                  <Col span={24}>
                    <Form.Item name="current">
                      <Checkbox className="fs-12 txt-color" onChange={handleChecked} checked={current}>I’m currently working in this role</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
          }
          <Row>
            <Col span={24}>
              <Text className="fs-12" type="secondary">Job Description</Text>
              <Form.Item 
                name="jobDescription"
                validateFirst
                rules={[
                  { max: 200, message: '200 characters only' },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {
              id ?
                <React.Fragment>
                  <Button className="f-left" onClick={handleDelete} type="link" loading={deleteLoading} danger>Delete</Button>
                  <Button className="f-right" htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                </React.Fragment>
              :
                <Space className="f-right">
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                </Space>
            }
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export class WorkExperience extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      deleteLoading: false,
      cityId: null,
      startDate: null,
      endDate: null,
      current: false,
      isDate: false,
      id: null,
      dataEdit: null,
      metaCity: {
        page: 1,
        perpage: 10,
        search: ''
      }
    }
    this.onSearchCity = debounce(this.onSearchCity.bind(this), 800)
  }
  
  showModal = (id) => {
    const { data } = this.props
    const dataEdit = data.listWorkExperience.filter(item => item.id === id).pop()

    this.setState({
      visible: true,
      id: id,
      cityId: id ? dataEdit?.cityId : null,
      startDate: id ? dataEdit?.startDate : null,
      endDate: id ? dataEdit?.endDate : null,
      current: id ? dataEdit?.current : false,
      dataEdit: dataEdit
    })

  };

  handleCancel = () => {
    this.setState({
      visible: false,
      current: false,
      isDate: false
    })
  };

  onSearchCity = value => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;

    metaCity.page = 1
    metaCity.perpage = 10
    metaCity.search = value

    return actionListCity(metaCity)
  }

  onFocusCity = () => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;
    return actionListCity(metaCity)
  }

  handleCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      cityId: optionSelected
    })
  }

  onChangeStartDate = (date, dateString) => {
    const { endDate } = this.state

    this.setState({
      startDate: dateString
    })
    
    if(endDate){
      if(endDate < dateString){
        message.error('Your end date can’t be earlier than your start date', 5)
        this.formRef.current.setFieldsValue({
          endDate: null
        })
        this.setState({
          isDate: true
        })
      }else{
        this.setState({
          isDate: false
        })
      }
    }
  }

  onChangeEndDate = (date, dateString) => {
    const { startDate } = this.state

    this.setState({
      endDate: dateString
    })

    if(dateString < startDate){
      message.error('Your end date can’t be earlier than your start date', 5)
      this.formRef.current.setFieldsValue({
        endDate: null
      })
      this.setState({
        isDate: true
      })
    }else{
      this.setState({
        isDate: false
      })
    }
  }

  handleChecked = (e) => {
    this.setState({
      current: e.target.checked
    })
    
    if(e.target.checked){
      this.setState({
        endDate: null
      })

      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  handleDelete = () => {
    const { actionDeleteWorkExperience, actionGetProfile } = this.props
    const { id } = this.state
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteWorkExperience(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { actionAddWorkExperience, actionUpdateWorkExperience, actionGetProfile } = this.props
    const { cityId, startDate, endDate, current, id, isDate } = this.state

    values.cityId = cityId
    values.startDate = startDate
    values.endDate = endDate
    values.current = current
    values.id = id
    
    if(isDate && !current){
      message.error('Your end date can’t be earlier than your start date')
    }else{
      if(id){
        return this.setState({ submitLoading: true }, () => {
          return actionUpdateWorkExperience(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }else{
        return this.setState({ submitLoading: true }, () => {
          return actionAddWorkExperience(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }
    }
  }

  render() {
    const { visible, submitLoading, deleteLoading, current, isDate, id, dataEdit } = this.state
    const { getCity, data } = this.props
    
    return (
      <React.Fragment>
        <WorkExperienceForm 
          data={data}
          visible={visible} 
          current={current}
          getCity={getCity}
          showModal={(id) => this.showModal(id)}
          handleCancel={this.handleCancel}
          onSearchCity={this.onSearchCity}
          onFocusCity={this.onFocusCity}
          handleCity={this.handleCity}
          onChangeStartDate={this.onChangeStartDate}
          onChangeEndDate={this.onChangeEndDate}
          handleChecked={this.handleChecked}
          formRef={this.formRef}
          onFinish={this.onFinish}
          isDate={isDate}
          submitLoading={submitLoading}
          deleteLoading={deleteLoading}
          id={id}
          dataEdit={dataEdit}
          handleDelete={this.handleDelete}
        />
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListCity } = this.props
    return unmountListCity()
  }
}

const mapStateToProps = (state) => ({
  getCity: state.cityReducer
})

const mapDispatchToProps = {
  actionListCity: listCity,
  unmountListCity: unmountListCity,
  actionAddWorkExperience: addWorkExperience,
  actionUpdateWorkExperience: updateWorkExperience,
  actionDeleteWorkExperience: deleteWorkExperience
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkExperience)