import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, Modal, Form, Input, Space, Button, Row, Col, Select, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { indexCountry } from '../../../../../redux/actions/master/country/countryAction';
import { checkMobile } from '../../../../../redux/actions/check/checkAction';
import { updateMobilePhone } from '../../../../../redux/actions/profile/headerAction';

const { Option } = Select;
const { Text } = Typography;

export class MobileNumber extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false,
      idCallingCode: 99,
      callingCode: 62,
    }
  }
  
  componentDidMount() {
    const { actionGetCountry } = this.props
    
    return actionGetCountry()
  }
  
  showModal = () => {
    this.setState({
      visible: true
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  onChangeCode = (value, option) => {
    this.setState({
      callingCode: option.children[1]
    })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    })
  }

  onCheckMobile = (e) => {
    const { callingCode } = this.state
    const { actionCheckMobile, data } = this.props

    this.formRef.current.setFieldsValue({
      mobilePhone: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`
    
    if(e !== `${data.callingCode}${data.mobilePhone}`){
      return actionCheckMobile(e, response => {
        if(response){
          this.formRef.current.setFieldsValue({
            mobilePhone: null
          })
          message.error('Phone Number already exist')
        }
      })
    }
  }

  onFinish = async (values) => {
    const { callingCode } = this.state
    const { actionUpdateMobilePhone, actionGetProfile } = this.props

    values.callingCodeId = values.code
    values.mobilePhone = `${callingCode}${values.mobilePhone}`
    
    this.setState({ submitLoading: true }, () => {
      return actionUpdateMobilePhone(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, visible, idCallingCode } = this.state
    const { data, getCountry } = this.props
    

    const prefixSelector = (
      <Form.Item name="code" noStyle>
        <Select className="prefix-selector" onChange={this.onChangeCode} loading={getCountry.loading}>
          {
            getCountry.data?.map(item =>
              <Option key={item.id} value={item.id}>+{item.callingCode}</Option>
            )
          }
        </Select>
      </Form.Item>
    )

    return (
      <React.Fragment>
        <Space>
          <Text className="fs-12 drk-color">{`+${data.callingCode} ${data.mobilePhone}`}</Text>
          <EditFilled className="fs-12 drk-color c-pointer" onClick={this.showModal} />
        </Space>

        <Modal 
          title="Mobile Number" 
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{ 
              code: +idCallingCode
            }}
          >
            <Row className="mb-8">
              <Col span={24}>
                <Space size={0} direction="vertical">
                  <Text className="fs-12" type="secondary">Current Mobile Number</Text>
                  <Text className="fs-13 drk-color" type="secondary">{`+${data.callingCode} ${data.mobilePhone}`}</Text>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">New Mobile Number</Text>
                </Space>
                <Form.Item 
                  className="mb-16"
                  name="mobilePhone"
                  validateFirst
                  rules={[
                    { required: true, message: 'New Mobile Number is required!' },
                    { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                    { min: 7, max: 13, message: 'Your mobile number is invalid' },
                    // { validator(rule, value, callback){
                    //     if(idCallingCode === 99)
                    //       if(value.charAt(0) === '0' || value.charAt(0) === '8' || value.charAt(0) === ''){
                    //         callback()
                    //       }else{
                    //         callback('Invalid phone number')
                    //       }
                    //   }
                    // }
                  ]}
                >
                  <Input onBlur={(e) => this.onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Type your primary mobile number" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="f-right">
                <Button onClick={this.handleCancel}>Cancel</Button>
                <Button htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  actionCheckMobile: checkMobile,
  actionUpdateMobilePhone: updateMobilePhone
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileNumber)