import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import ReactGA from "react-ga";
import { Layout } from 'antd';
import Header from './Header';
import Sider from './Sider';
import Footer from './Footer';
import { indexHeader, listNotification, countNotification, readNotification } from '../../redux/actions/header/headerAction'
import { checkAuth, checkToken } from '../../redux/actions/auth/authAction'

const { Content } = Layout;

if(process.env.REACT_APP_ENV === "production"){
  ReactGA.initialize("G-H4HEF8W92E");
}

export class MainPrivate extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      sidebar: true,
      params: {
        page: 1,
        perpage: 100
      },
      visibleNotification: false
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionCheckToken, actionGetHeader, actionListNotification, actionCountNotification } = this.props
    const getToken = Cookie.get('user')
    
    if(!getToken){
      window.location.reload()
    }
    
    return actionCheckToken((response) => {
      if(response.code === "1000"){
        actionGetHeader()
        actionListNotification(params)
        actionCountNotification()
      }
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }

  onClickSider = () => {
    const { sidebar } = this.state

    this.setState({
      sidebar: sidebar === true ? false : true
    })
  }

  showNotification = () => {
    this.setState({
      visibleNotification: true
    })
  }

  onCloseNotification = () => {
    this.setState({
      visibleNotification: false
    })
  }

  render() {
    const { children } = this.props
    const { visible, sidebar, visibleNotification } = this.state
    const { pathname } = window.location

    const initialProps = {
      visible: visible,
      sidebar: sidebar,
      visibleNotification: visibleNotification,
      showDrawer: this.showDrawer,
      onClose: this.onClose,
      onClickSider: this.onClickSider,
      showNotification: this.showNotification,
      onCloseNotification: this.onCloseNotification
    }
    return (
      <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
        <Header {...initialProps} {...this.props} style={{ borderBottom: '2px solid #dfdfdf' }} />
        <Layout>
          {
            pathname === '/job-preferences/industries' || pathname === '/job-preferences/position' || pathname === '/job-preferences/destination' ?
              null
            :
              <Sider {...initialProps} {...this.props} />
          }
          <Content>
            { children }
          </Content>
        </Layout>
        <Footer />
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  getHeader: state.headerReducer,
  listNotification: state.notificationReducer,
  getCountNotification: state.countNotificationReducer
})

const mapDispatchToProps = {
  actionCheckAuth: checkAuth,
  actionCheckToken: checkToken,
  actionGetHeader: indexHeader,
  actionListNotification: listNotification,
  actionCountNotification: countNotification,
  actionReadNotification: readNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPrivate)