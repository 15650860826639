import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexFieldOfStudy = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_FIELD_OF_STUDY'})
}

export const indexFieldOfStudy = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY' })
  return API.GET('/field-of-study/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY_FAILED' }), 
    ))
  })
}

export const listFieldOfStudy = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY' })
  return API.GET('/field-of-study/list', value).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_FIELD_OF_STUDY_FAILED' }), 
    ))
  })
}