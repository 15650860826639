import React, { Component } from 'react'
import { Row, Col, Button, Form, Select, Skeleton, message } from 'antd'

const { Option } = Select

export default class FormTimezone extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      timezoneId: 1
    }
  }

  componentDidMount() {
    const { actionIndexTimezone, actionCandidateTimezone } = this.props

    return (actionIndexTimezone(), actionCandidateTimezone())
  }

  // handleTimezone = (value) => {
  //   this.setState({
  //     timezoneId: value
  //   })
  // }

  onFinish = (values) => {
    const { actionUpdateTimezone, actionCandidateTimezone } = this.props;
    this.setState({ submitLoading: true })
    return actionUpdateTimezone(values, (response) => {
      this.setState({ submitLoading: false }, () => {
        if(response.code === '1000'){
          message.success(response.message)
          return actionCandidateTimezone()
        }else{
          message.error(response.message)
        }
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        message.error(err)
      })
    })
  }

  render() {
    const { submitLoading, timezoneId } = this.state
    const { getIndexTimezone, getCandidateTimezone } = this.props

    if(getCandidateTimezone.loading || getIndexTimezone.loading){
      return <Skeleton active />
    }
    
    return (
      <React.Fragment>
        <Form 
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onFinish}
        >
          <Row className="mb-min-8">
            <Col span={24}>
              <Form.Item name="timeZoneId" initialValue={getCandidateTimezone?.data?.id || timezoneId}>
                <Select
                  showSearch
                  size="small"
                  optionFilterProp="children"
                  // onChange={this.handleTimezone}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={onSearch}
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                >
                  {
                    getIndexTimezone?.data?.map((item) => 
                      <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="txt-algn-center mt-8">
            <Button className="br-3" htmlType="submit" type="primary" loading={submitLoading} style={{ width: 160 }}>Save</Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexTimezone, unmountCandidateTimezone } = this.props
    return (unmountIndexTimezone(), unmountCandidateTimezone())
  }
}
