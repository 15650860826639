import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography , Card, Space, Image, Button, Grid } from 'antd';
import ListIcon from '../../assets/img/ListIcon.png';
import HeadsetIcon from '../../assets/img/HeadsetIcon.png';
import GlobeIcon from '../../assets/img/GlobeIcon.png';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function topPicks() {
  const { md, sm } = GetBreakPoint()

  return (
    <React.Fragment>
      <Row style={{ background: '#fff', paddingBottom: 100 }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
          <Title className="title-content-2 drk-color fw-normal" level={3} style={{ marginBottom: 80 }}>What you will get by register in Jobshub?</Title>
          <Row>
            <Col span={24}>
              <Row gutter={64}>
                <Col lg={{ span:8 }} md={{ span:8 }} sm={{ span:24 }} xs={{ span:24 }}>
                  <Space className="full-width" direction="vertical" align="center" size={0}>
                    <Image
                      src={ListIcon}
                      preview={false}
                      width={'100%'}
                    />
                    <Text className="fs-18 drk-color" strong>Easy Apply</Text>
                    <Text className="fs-16 drk-color txt-algn-center">This can save you tremendous time in your job search. You won’t need to fill in your personal details a couple of times.</Text>
                  </Space>
                </Col>
                <Col lg={{ span:8 }} md={{ span:8 }} sm={{ span:24 }} xs={{ span:24 }}>
                  <Space className="full-width" direction="vertical" align="center" size={0}>
                    <Image
                      src={HeadsetIcon}
                      preview={false}
                      width={'100%'}
                    />
                    <Text className="fs-18 drk-color" strong>Job Assistant</Text>
                    <Text className="fs-16 drk-color txt-algn-center">Our system actively helps you find a job, by providing job offers and reminds you of information related to the next process.</Text>
                  </Space>
                </Col>
                <Col lg={{ span:8 }} md={{ span:8 }} sm={{ span:24 }} xs={{ span:24 }}>
                  <Space className="full-width" direction="vertical" align="center" size={0}>
                    <Image
                      src={GlobeIcon}
                      preview={false}
                      width={'100%'}
                    />
                    <Text className="fs-18 drk-color" strong>Global opportunities</Text>
                    <Text className="fs-16 drk-color txt-algn-center">Improve your skills with international standards and Join a global community</Text>
                  </Space>
                </Col>
              </Row>
            </Col>  
          </Row>
          <Row style={{ paddingTop: 100, background: '#fff' }}>
            {
              md ?
                <Col className="full-width">
                  <Card className="br-3 box-shadow" style={{ marginBottom: -120, height: 140, background: '#32ab6d', paddingTop: 10 }} bordered={false}>
                    <Row>
                      <Col span={1}></Col>
                      <Col span={18}>
                        <Space direction="vertical" size={-16}>
                          <Title level={2} style={{ color: '#ffffff' }}>Take This Opportunity</Title>
                          <Title level={4} style={{ color: '#ffffff' }}>Find your dream career now</Title>
                        </Space>
                      </Col>
                      <Col span={4} style={{ paddingTop: 20 }}>
                        <Link to="/register">
                          <Button className="br-3 full-width" type="default" style={{ color: '#32ab6d', fontWeight: 'bold' }}>Register</Button>
                        </Link>
                      </Col>
                      <Col span={1}></Col>
                    </Row>
                  </Card>
                </Col>
              :
                <Col className="full-width">
                  <Card className="br-3 box-shadow" style={ sm ? { marginBottom: -120, height: 140, background: '#32ab6d', paddingTop: 10 } : { marginBottom: -120, height: 180, background: '#32ab6d', paddingTop: 10 }} bordered={false}>
                    <Row gutter={8}>
                      <Col xs={24} sm={18} style={{ paddingTop: 10 }}>
                        <Space direction="vertical" size={-16}>
                          <Title level={4} style={{ color: '#ffffff' }}>Take This Opportunity</Title>
                          <Title level={5} style={{ color: '#ffffff' }}>Find your dream career now</Title>
                        </Space>
                      </Col>
                      <Col xs={24} sm={6} style={{ paddingTop: 20 }}>
                        <Link to="/register">
                          <Button className="br-3 full-width" type="default" style={{ color: '#32ab6d', fontWeight: 'bold' }}>Register</Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card>
                </Col>
            } 
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
