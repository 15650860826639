import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import InfiniteScroll from 'react-infinite-scroller';
import EmptyLogo from '../../../assets/img/EmptyLogo.png';
import { Row, Col, Form, List, Input, Image, Typography, Space, Button, Collapse, Checkbox, Drawer, Divider, Switch, Select, Spin, Radio, Modal, Card, Skeleton, Tooltip, Grid } from 'antd';
import { SafetyOutlined, EnvironmentOutlined, FilterOutlined, PlusOutlined, CheckOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
// import { Row, Col, List, Input, Image, Typography, Space, Button, Collapse, Checkbox, Tooltip, Drawer, Divider, Switch } from 'antd';
// import { SafetyOutlined, EnvironmentOutlined, StarOutlined, FilterOutlined } from '@ant-design/icons';
import '../Job.css';

const { Text } = Typography;
const { Search } = Input;
const { Panel } = Collapse;
const { Option } = Select;
const { useBreakpoint } = Grid;

function GetHistory() {
  const history = useHistory()
  return history;
}

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function MobJobList(props) {
  const {
    showFilter,
    onCloseFilter,
    visibleFilter,
    onSearch, 
    callback, 
    getJob,
    handleSort,
    onChangePagination, 
    dataIndustry, 
    handleIndustry, 
    handleDestination,
    dataLocation, 
    handleLocation,
    handleType,
    handleRemote,
    handleLevel,
    handleDate,
    handleClearFilter,
    formRef,
    flagRemote,
    showModalIndustry,
    allJobIndustry,
    onSearchIndustry,
    handleCheckedIndustry,
    visibleIndustry,
    valueIndustry,
    handleOkIndustry,
    handleClearIndustry,
    showModalLocation,
    visibleLocation,
    allJobDestination,
    onSearchLocation,
    handleCheckedLocation,
    handleOkLocation,
    handleClearLocation,
    valueLocation,
    valueSearch,
    handleCancel,
    loadingList,
    hasMore,
    handleInfiniteOnLoad,
    handleFavorite
  } = props
  
  const history = GetHistory()

  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    localStorage.setItem('visible', true);
    history.push('/login-or-register')
  }

  const { sm } = GetBreakPoint()

  const handleClearForm = () => {
    window.scrollTo(0, 0);
    formRef.current.setFieldsValue({
      search: null,
      industry: null,
      destination: null,
      location: null,
      jobType: null,
      level: null,
      postDate: null
    });
  }
  
  return (
    <React.Fragment>
      <Form ref={formRef}>
      <Row className="pad-16">
        <Col span={24}>
          <Row className="mb-8 full-width">
            <Col className="pr-4" span={8}>
              <Button className="full-width br-6" onClick={showFilter} type="primary" icon={<FilterOutlined />} ghost>
                Filter
              </Button>
              <Drawer
                className="drk-color"
                title="Filter"
                placement="bottom"
                height={'70%'}
                closable={false}
                onClose={onCloseFilter}
                visible={visibleFilter}
              >
                <Collapse className="br-6 drk-color box-shadow" style={{ backgroundColor: '#fff', border: '1px solid #d9d9d9' }} defaultActiveKey={['1','2','3','4','5','6']} onChange={callback} expandIconPosition="right" ghost>
                  <Panel header="Industry" key="1">
                    <Space className="mb-16" direction="vertical" size={-16}>
                      <Form.Item name="industry" initialValue={valueIndustry}>
                        <Checkbox.Group onChange={(checkedValues) => handleIndustry(checkedValues)}>
                          {
                            dataIndustry?.data?.map((item, i) => 
                              <Checkbox key={i} className="fs-12 txt-color full-width" value={item.id}>{item.title}</Checkbox>
                            )
                          }
                        </Checkbox.Group>
                      </Form.Item>
                      <Text className="add-filter fs-13 c-pointer" onClick={showModalIndustry}><PlusOutlined /> Add an Industry</Text>
                      <Modal
                        visible={visibleIndustry}
                        title="Filter by Industry"
                        onOk={handleOkIndustry}
                        onCancel={handleCancel}
                        footer={[
                          <Button key="back" onClick={handleClearIndustry}>
                            Reset
                          </Button>,
                          <Button key="submit" type="primary" loading={getJob?.loading} onClick={handleOkIndustry}>
                            Submit
                          </Button>,
                        ]}
                      >
                        <Search className="mb-8" enterButton="Search" placeholder="Search Job Industry" allowClear onSearch={onSearchIndustry} style={{ width: '100%' }} />
                        <div className="demo-infinite-container">
                          <Form.Item name="modalIndustry" initialValue={valueIndustry}>
                              {
                                allJobIndustry.loading ?
                                  <Spin size="small" />
                                :
                                  <Checkbox.Group className="full-width" onChange={(checkedValues) => handleCheckedIndustry(checkedValues)}>
                                    { 
                                      allJobIndustry?.data?.map((item, i) => 
                                        <Checkbox key={i} className="fs-12 txt-color full-width mb-8" value={item.id}>{item.title}</Checkbox>
                                      )
                                    }
                                  </Checkbox.Group>
                              }
                          </Form.Item>
                        </div>
                      </Modal>
                    </Space>
                  </Panel>
                  {/* <Divider className="drk-color" /> */}
                  <Panel header="Destination" key="2">
                    <Space direction="vertical" size={-16}>
                      <Form.Item className="mb-min-8" name="destination">
                        <Checkbox.Group onChange={(checkedValues) => handleDestination(checkedValues)}>
                          <Checkbox key="INTERNATIONAL" className="fs-12 txt-color full-width" value="INTERNATIONAL">International</Checkbox>
                          <Checkbox key="DOMESTIC" className="fs-12 txt-color full-width" value="DOMESTIC">Domestic</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Space>
                  </Panel>
                  {/* <Divider className="drk-color" /> */}
                  <Panel header="Location" key="3">
                    <Space className="mb-16" direction="vertical" size={-16}>
                      <Form.Item name="location" initialValue={valueLocation}>
                        {
                          dataLocation.loading?
                            <Spin size="small" />
                          :
                              <Checkbox.Group onChange={(checkedValues) => handleLocation(checkedValues)}>
                                {
                                  dataLocation?.data?.map((item, i) => 
                                    <Checkbox key={i} className="fs-12 txt-color" value={item.id} style={{ width: '100%' }}>{item.name}</Checkbox>
                                  )
                                }
                              </Checkbox.Group>
                        }
                      </Form.Item>
                      <Text className="add-filter fs-13 c-pointer" onClick={showModalLocation}><PlusOutlined /> Add an Location</Text>
                      <Modal
                        visible={visibleLocation}
                        title="Filter by Location"
                        onOk={handleOkLocation}
                        onCancel={handleCancel}
                        footer={[
                          <Button key="back" onClick={handleClearLocation}>
                            Reset
                          </Button>,
                          <Button key="submit" type="primary" loading={getJob?.loading} onClick={handleOkLocation}>
                            Submit
                          </Button>,
                        ]}
                      >
                        <Search className="mb-8" placeholder="Search Location" enterButton="Search" allowClear onSearch={onSearchLocation} style={{ width: '100%' }} />
                          <div className="demo-infinite-container">
                            <InfiniteScroll
                              initialLoad={false}
                              pageStart={0}
                              loadMore={() => handleInfiniteOnLoad()}
                              hasMore={!loadingList && hasMore}
                              useWindow={false}
                            >
                              <List
                                dataSource={allJobDestination?.data ? allJobDestination?.data : []}
                                renderItem={(item, i) => (
                                  <List.Item key={i}>
                                    <Checkbox checked={valueLocation.includes(item.id)} onChange={() => handleCheckedLocation(item.id)} className="fs-12 txt-color full-width" value={item.id}>{item.name}</Checkbox>
                                  </List.Item>
                                )}
                                loading={allJobDestination.loading}
                              >
                              </List>
                            </InfiniteScroll>
                          </div>
                      </Modal>
                    </Space>
                  </Panel>
                  {/* <Divider className="drk-color" /> */}
                  <Panel header="Job Type" key="4">
                    <Space direction="vertical" size={0}>
                      <Form.Item className="mb-min-8" name="jobType">
                        <Checkbox.Group onChange={(checkedValues) => handleType(checkedValues)}>
                          <Checkbox key="FULL_TIME" className="fs-12 txt-color full-width" value="FULL_TIME">Full Time</Checkbox>
                          <Checkbox key="PART_TIME" className="fs-12 txt-color full-width" value="PART_TIME">Part Time</Checkbox>
                          <Checkbox key="FREELANCE" className="fs-12 txt-color full-width" value="FREELANCE">Freelance</Checkbox>
                          <Checkbox key="INTERNSHIP" className="fs-12 txt-color full-width" value="INTERNSHIP">Internship</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                      <Divider style={{ marginBottom: 8, marginTop: 16 }} />
                      <Space className="fs-12 txt-color full-width">
                        Possible to work from home <Switch size="small" checked={flagRemote} onChange={(checked) => handleRemote(checked)} />
                      </Space>
                    </Space>
                  </Panel>
                  {/* <Divider className="drk-color" /> */}
                  <Panel header="Experience Level" key="5">
                    <Space direction="vertical" size={0}>
                      <Form.Item className="mb-min-8" name="level">
                        <Checkbox.Group onChange={(checkedValues) => handleLevel(checkedValues)}>
                          <Checkbox key="ENTRY_LEVEL" className="fs-12 txt-color full-width" value="ENTRY_LEVEL">Entry Level</Checkbox>
                          <Checkbox key="ASSOCIATE" className="fs-12 txt-color full-width" value="ASSOCIATE">Associate</Checkbox>
                          <Checkbox key="MID_SENIOR" className="fs-12 txt-color full-width" value="MID_SENIOR">Mid-Senior Level</Checkbox>
                          <Checkbox key="DIRECTOR" className="fs-12 txt-color full-width" value="DIRECTOR">Director</Checkbox>
                          <Checkbox key="EXECUTIVE" className="fs-12 txt-color full-width" value="EXECUTIVE">Executive</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Space>
                  </Panel>
                  {/* <Divider className="drk-color" /> */}
                  <Panel header="Date Posted" key="6">
                    <Space className="mb-16" direction="vertical" size={0}>
                      <Form.Item className="mb-min-8" name="postDate">
                        <Radio.Group optionType="button" onChange={(e) => handleDate(e)}>
                          <Space className="full-width" direction="vertical">
                            <Radio.Button key="anytime" className="full-width txt-algn-center br-6 drk-color" value="anytime">Any Time</Radio.Button>
                            <Radio.Button key="month" className="full-width txt-algn-center br-6 drk-color" value="month">Past Month</Radio.Button>
                            <Radio.Button key="week" className="full-width txt-algn-center br-6 drk-color" value="week">Past Week</Radio.Button>
                            <Radio.Button key="hours" className="full-width txt-algn-center br-6 drk-color" value="hours">Past 24 hours</Radio.Button>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  </Panel>
                </Collapse>
                <Card className="br-6 box-shadow" bordered={false} style={{ marginTop: 8 }}>
                  <Button className="full-width br-6" type="primary" onClick={() => {handleClearFilter(); handleClearForm()}}>Clear Filter</Button>
                </Card>
              </Drawer>
            </Col>
            <Col className="pl-4" span={16}>
              <Form.Item name="search" initialValue={valueSearch}>
                <Search 
                  className="full-width br-6" 
                  placeholder="Search for a job title, job position" 
                  allowClear 
                  onSearch={onSearch} 
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="mb-8" sm={12} xs={24}>
              {
                getJob.loading ?
                  <Skeleton.Input style={{ width: '100%' }} active />
                :
                  <Text className="txt-color fs-13">{`${(getJob?.data?.meta?.page*getJob?.data?.meta?.perpage)-(getJob?.data?.meta?.perpage-1)}-${getJob?.data?.meta?.page === getJob?.data?.meta?.pages ? getJob?.data?.meta?.total : getJob?.data?.meta?.page*getJob?.data?.meta?.perpage} of ${getJob?.data?.meta?.total} job matches`}</Text>
              }
            </Col>
            <Col className="full-width" sm={12} xs={24} style={ sm ? { paddingLeft: 40 } : {}}>
              <Space className="full-width" size={4}>
                <Text className="fs-13 txt-color">Sort by: </Text>
                <Select defaultValue="postedDate" style={{ width: 180 }} size="small" onChange={handleSort}>
                  {/* <Option value="relevance">Relevance</Option> */}
                  <Option key="1" value="expiryDate">Expiry Date</Option>
                  <Option key="2" value="postedDate">Posted Date</Option>
                </Select>
              </Space>
            </Col>
          </Row>
          <Row className="full-width pt-8 pb-8">
            <Col className="full-width">
              {
                getJob?.loading ?
                  <Card className="br-6 box-shadow mb-8" style={{ width: '100%' }}>
                    <Skeleton active avatar />
                  </Card>
                :
                  <List
                    className="br-6 box-shadow"
                    size="large"
                    pagination={{
                      style: { textAlign: 'center' },
                      pageSize: getJob?.data?.meta?.perpage,
                      total: getJob?.data?.meta?.total,
                      current: getJob?.data?.meta?.page,
                      onChange: (pageNumber) => onChangePagination(pageNumber)
                    }}
                    bordered
                    dataSource={getJob?.data?.data}
                    renderItem={item => (
                      <List.Item key={item.id}>
                        <Row className="full-width" gutter={16}>
                          <Col className="col-img-job" xs={24} sm={4} md={4}>
                            <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                              <Image
                                src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                                preview={false}
                                width={'100%'}
                                height={100}
                                style={{ objectFit: 'contain' }}
                              />
                            </Link>
                          </Col>
                          <Col xs={24} sm={13} md={12}>
                            <Space direction="vertical" size={0}>
                              <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.id}` : `/job/detail/${item.id}`}>
                                <Text className="title-job drk-color fs-17">{item.title}</Text>
                              </Link>
                              <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                                <Text className="company-job fs-13 txt-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                              </Link>
                              <Text className="fs-13 txt-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                              <Text className="fs-13 primary-color">
                                {
                                  item.isDisplaySalary ?
                                    item.maxSalary ? 
                                      `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                    : 
                                      `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                                  :
                                    null
                                }
                              </Text>
                              <Text className="txt-color fs-13">{item.agencyName ? `Recruited by ${item.agencyName}` : null}</Text>
                            </Space>
                          </Col>
                          <Col xs={24} sm={7} md={8}>
                            <Space direction="vertical" size={16} className={ sm ? "f-right" : ''}>
                              <Text className="fs-11 txt-color">{`Posted on ${item.postDate}`}</Text>
                              {
                                Cookie.get('user') ?
                                  <Space size={8}>
                                    <Tooltip title={item.isFavorite ? 'Already added to favorites' : 'Add to favorites' }>
                                      <Button type="primary" className="br-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                    </Tooltip>
                                    {
                                      item.isApply ?
                                        <Text className="f-right fs-14 primary-color"><CheckOutlined /> Applied</Text>
                                      :
                                        <Link to={`/candidate/job/detail/${item.id}`}>
                                          <Button type="primary" className="f-right br-6">Apply</Button>
                                        </Link>
                                    }         
                                  </Space>
                                :
                                  <Button type="primary" className="f-right br-6" onClick={() => onClickApply(`/candidate/job/detail/${item.id}`)}>Apply</Button>
                              }
                            </Space>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
              }
            </Col>
          </Row>
        </Col>
      </Row>
      </Form>
    </React.Fragment>
  )
}