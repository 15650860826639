import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Card, Skeleton, Image, Space, Pagination, Empty, Button, message } from 'antd'
import { listFavorite, unmountFavorite, addOrDeleteFavorite } from '../../../redux/actions/favorite/favoriteAction'
import { SafetyOutlined, EnvironmentOutlined } from '@ant-design/icons'
import EmptyLogo from '../../../assets/img/EmptyLogo.png';

const { Text } = Typography

export class Favorite extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
        page: 1,
        perpage: 10
      },
      submitLoading: false
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionListFavorite } = this.props
    
    return actionListFavorite(params)
  }
  
  handlePagination = (pageNumber) => {
    const { params: { perpage } } = this.state
    const params = {
      page: pageNumber,
      perpage: perpage,
    }
    this.setState({ params })
    window.scrollTo(0, 0);
    const { actionListFavorite } = this.props
    return actionListFavorite(params)
  }

  handleRemove = (jobId) => {
    const { params } = this.state
    const { actionAddOrDeleteFavorite, actionListFavorite } = this.props
    
    const values = {
      jobId
    }

    return this.setState({ submitLoading: true }, () => {
      return actionAddOrDeleteFavorite(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            message.success('Job has been removed from your favorite')
            return actionListFavorite(params)
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { getListFavorite } = this.props
    
    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Favorite Jobs</title>
        </Helmet>
        <Row className="pad-16">
          <Col span={20} offset={2}>
            <Row className="mb-32">
              <Col>
                <Text className="drk-color fs-20">Favorite Jobs</Text>
              </Col>
            </Row>
            <Row className="mb-8">
              <Col>
                {
                  getListFavorite.loading ?
                    <Skeleton.Input style={{ width: '100%' }} active />
                  :
                    <Text className="drk-color fs-14">{`Showing ${(getListFavorite?.meta?.page*getListFavorite?.meta?.perpage)-(getListFavorite?.meta?.perpage-1) }-${getListFavorite?.meta?.page === getListFavorite?.meta?.pages ? getListFavorite?.meta?.total : getListFavorite?.meta?.page*getListFavorite?.meta?.perpage} of ${getListFavorite?.meta?.total} favorite jobs`}</Text>
                }
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Row className="mb-16">
                  <Col span={24}>
                    {
                      getListFavorite.loading ?
                        <Card className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                          <Skeleton active avatar />
                        </Card>
                      :
                        getListFavorite?.data?.length > 0 ?
                          getListFavorite?.data?.map((item, i) => 
                            <Card key={i} className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                              <Row className="full-width" gutter={16}>
                                <Col className="col-img-job" xxl={3} xl={4} lg={4}>
                                  <Link to={`/candidate/company/${item.companyId}/${item.companyType}`}>
                                    <Image
                                      src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                                      preview={false}
                                      width={'100%'}
                                      height={100}
                                      style={{ objectFit: 'contain' }}
                                    />
                                  </Link>
                                </Col>
                                <Col xxl={14} xl={13} lg={12}>
                                  <Space direction="vertical" size={0}>
                                    <Link to={`/candidate/job/detail/${item.jobId}`}>
                                      <Text className="a-color fs-17">{item.title}</Text>
                                    </Link>
                                    <Link to={`/candidate/company/${item.companyId}/${item.companyType}`}>
                                      <Text className="company-job fs-13 txt-color">{item.companyName} <SafetyOutlined className="primary-color" /></Text>
                                    </Link>
                                    <Text className="fs-13 txt-color"><EnvironmentOutlined /> {item.jobDestinationParentName ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                                    <Button className="fs-12 br-3 mt-8" type="primary" size="small" onClick={() => this.handleRemove(item.jobId)} danger>
                                      Remove
                                    </Button>
                                  </Space>
                                </Col>
                                <Col xxl={7} xl={7} lg={8}>
                                  <Space direction="vertical" size={16} className="f-right">
                                    <Text className="f-right fs-11 txt-color">{`Posted on ${item.postDate}`}</Text>
                                  </Space>
                                </Col>
                              </Row>
                          </Card>
                      )
                        :
                          <Card className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                            <Empty />
                          </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="txt-algn-center" span={24}>
                    <Pagination onChange={(pageNumber,total, range) => this.handlePagination(pageNumber, total, range)} current={getListFavorite?.meta?.page} pageSize={getListFavorite?.meta?.perpage || 10} total={getListFavorite?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountFavorite } = this.props
    return unmountFavorite()
  }
}

const mapStateToProps = (state) => ({
  getListFavorite: state.favoriteReducer
})

const mapDispatchToProps = {
  actionListFavorite: listFavorite,
  unmountFavorite: unmountFavorite,
  actionAddOrDeleteFavorite: addOrDeleteFavorite
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorite)