import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce'
import moment from 'moment';
import { Card, Typography, Modal, Button, Input, Space, Form, Row, Col, DatePicker, List, Select, AutoComplete, Spin, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { listInstitution, unmountIndexInstitution } from '../../../../redux/actions/master/institution/institutionAction';
import { indexEducationLevel, unmountIndexEducationLevel } from '../../../../redux/actions/master/educationLevel/educationLevelAction';
import { listFieldOfStudy, unmountIndexFieldOfStudy } from '../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { addEducation, updateEducation, deleteEducation } from '../../../../redux/actions/profile/educationAction';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export class Education extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading:false,
      deleteLoading: false,
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      educationLevelId: null,
      isYear: false,
      startYear: null,
      endYear: null,
      id: null,
      dataEdit: null
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }

  componentDidMount() {
    const { actionGetEducationLevel } = this.props

    return actionGetEducationLevel()
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  showModal = (id) => {
    const { data } = this.props
    const dataEdit = data.listEducation.filter(item => item.id === id).pop()

    this.setState({
      visible: true,
      id: id,
      educationLevelId: id ? dataEdit?.educationLevelId : null,
      startYear: id ? dataEdit?.startYear : null,
      endYear: id ? dataEdit?.endYear : null,
      dataEdit: dataEdit
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      isYear: false
    })
  };

  onChangeEducationLevel = (value) => {
    this.setState({
      educationLevelId: value
    })
  }

  onChangeStartYear = (date, dateString) => {
    const { endYear } = this.state

    this.setState({
      startYear: dateString
    })
    
    if(endYear){
      if(endYear < dateString){
        this.setState({
          isYear: true
        })
      }else{
        this.setState({
          isYear: false
        })
      }
    }
  }

  onChangeEndYear = (date, dateString) => {
    const { startYear } = this.state

    this.setState({
      endYear: dateString
    })

    if(dateString < startYear){
      this.setState({
        isYear: true
      })
    }else{
      this.setState({
        isYear: false
      })
    }
  }

  handleDelete = () => {
    const { actionDeleteEducation, actionGetProfile } = this.props
    const { id } = this.state
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteEducation(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { actionAddEducation, actionUpdateEducation, actionGetProfile } = this.props
    const { educationLevelId, startYear, endYear, id, isYear } = this.state

    values.educationLevelId = educationLevelId
    values.startYear = startYear
    values.endYear = endYear
    values.id = id
    
    if(isYear){
      message.error('Your end year can’t be earlier than your start year')
    }else{
      if(id){
        return this.setState({ submitLoading: true }, () => {
          return actionUpdateEducation(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }else{
        return this.setState({ submitLoading: true }, () => {
          return actionAddEducation(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }
    }
  }
  
  render() {
    const { visible, submitLoading, deleteLoading, isYear, id, dataEdit } = this.state
    const { getInstitution, getEducationLevel, getFieldOfStudy, data: { listEducation } } = this.props

    return (
      <React.Fragment>
        <Card 
          className="br-6 box-shadow" 
          title="Education" 
          bordered={false}
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={listEducation.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
          actions={[
            <Text className="primary-color" onClick={() => this.showModal(null)}>Add education</Text>
          ]}
        >
          {
            listEducation.length > 0 ?
              <List
                dataSource={listEducation}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <Row className="full-width">
                      <Col span={20}>
                        <Space direction="vertical" size={0}>
                          <Text className="primary-color">{item.name}</Text>
                          {
                            item.educationLevelName && item.fieldOfStudy ?
                              <Text className="txt-color">{`${item.educationLevelName}, ${item.fieldOfStudy}`}</Text>
                            : item.educationLevelName ?
                              <Text className="txt-color">{item.educationLevelName}</Text>
                            : item.fieldOfStudy ?
                              <Text className="txt-color">{item.fieldOfStudy}</Text>
                            :
                              null
                          }
                          {
                            item.gpa ?
                              <Text className="txt-color">{`GPA ${item.gpa}`}</Text>
                            :
                              null
                          }
                          {
                            item.startYear || item.endYear ?
                              <Text className="txt-color">{`${item.startYear ? item.startYear : 'N/A'} - ${item.endYear ? item.endYear : 'N/A'}`}</Text>
                            :
                              null
                          }
                          {
                            item.experience ?
                              <Text className="txt-color">{item.experience}</Text>
                            :
                              null
                          }
                        </Space>
                      </Col>
                      <Col span={4}>
                        <Text className="primary-color f-right c-pointer" onClick={() => this.showModal(item.id)}><EditFilled /> Edit</Text>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            :
              <Text className="txt-color">Companies want to know all about you. Share where you studied to attract them</Text>
          }
        </Card>

        <Modal 
          title="Education" 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={800}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              name: id ? dataEdit?.name : null,
              educationLevelId: id ? dataEdit?.educationLevelName : null,
              fieldOfStudy: id ? dataEdit?.fieldOfStudy : null,
              gpa: id ? dataEdit?.gpa : null,
              startYear: id && dataEdit?.startYear ? moment(dataEdit?.startYear) : null,
              endYear: id && dataEdit?.endYear ? moment(dataEdit?.endYear) : null,
              experience: id ? dataEdit?.experience : null,
            }}
          >
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">School/University</Text>
                </Space>
                <Form.Item 
                  name="name"
                  validateFirst
                  rules={[
                    { required: true, message: 'School/University is required!' },
                    // { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
                    { min: 5, message: 'School must be at least 5 characters' },
                    { max: 100, message: '100 characters only' },
                  ]}
                >
                  <AutoComplete
                    className="full-width"
                    onFocus={this.onFocusInstitution}
                    onSearch={this.onSearchInstitution}
                    notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    size="small"
                    placeholder="e.g.: Binawan University"
                  >
                    {
                      getInstitution?.data?.map((item, i) => (
                        <AutoComplete.Option key={i} value={item.name}>
                          {item.name}
                        </AutoComplete.Option>
                      ))
                    }
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Education Level</Text>
                <Form.Item name="educationLevelId">
                  <Select 
                    className="full-width br-6"
                    size="small" 
                    onChange={this.onChangeEducationLevel}
                  >
                    {
                      getEducationLevel?.data?.map(item => 
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Field of Study</Text>
                <Form.Item 
                  name="fieldOfStudy"
                  validateFirst
                  rules={[
                    { min: 5, message: 'Fields of Study must be at least 5 characters' },
                    { max: 100, message: '100 characters only' },
                  ]}
                >
                  <AutoComplete
                    className="full-width"
                    onFocus={this.onFocusFieldOfStudy}
                    onSearch={this.onSearchFieldOfStudy}
                    notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    size="small"
                    placeholder="e.g.: Nursing"
                  >
                    {
                      getFieldOfStudy?.data?.map((item, i) => (
                        <AutoComplete.Option key={i} value={item.name}>
                          {item.name}
                        </AutoComplete.Option>
                      ))
                    }
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Score/Grade/GPA</Text>
                <Form.Item 
                  name="gpa"
                  rules={[
                    { pattern: /^[0-9a-zA-Z.]*$/, message: 'Only dot (.) is allowed, No spaces allowed' },
                    { max: 4, message: '4 characters only' },
                  ]}
                >
                  <Input size="small" placeholder="e.g.: 3.50" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Start Year</Text>
                <Form.Item name="startYear">
                  <DatePicker className="full-width" size="small" picker="year" onChange={this.onChangeStartYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">End Year (or expected)</Text>
                <Form.Item 
                  name="endYear"
                  validateStatus={isYear ? "error" : null}
                  help={isYear ? "Your end year can’t be earlier than your start year" : null}
                >
                  <DatePicker className="full-width" size="small" picker="year" onChange={this.onChangeEndYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text className="fs-12" type="secondary">Activities and Organization Experiences</Text>
                <Form.Item 
                  name="experience"
                  rules={[
                    { max: 200, message: '200 characters only' }
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {
                id ?
                  <React.Fragment>
                    <Button className="f-left" onClick={this.handleDelete} type="link" loading={deleteLoading} danger>Delete</Button>
                    <Button className="f-right" htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                  </React.Fragment>
                :
                  <Space className="f-right">
                    <Button onClick={this.handleCancel}>Cancel</Button>
                    <Button htmlType="submit" type="primary" loading={submitLoading}>Save</Button>
                  </Space>
              }
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexEducationLevel, unmountIndexInstitution, unmountIndexFieldOfStudy } = this.props
    return (unmountIndexEducationLevel(), unmountIndexInstitution(), unmountIndexFieldOfStudy())
  }
}

const mapStateToProps = (state) => ({
  getInstitution: state.institutionReducer,
  getEducationLevel: state.educationLevelReducer,
  getFieldOfStudy: state.fieldOfStudyReducer
})

const mapDispatchToProps = {
  actionListInstitution: listInstitution,
  unmountIndexInstitution: unmountIndexInstitution,
  actionGetEducationLevel: indexEducationLevel,
  unmountIndexEducationLevel: unmountIndexEducationLevel,
  actionListFieldOfStudy: listFieldOfStudy,
  unmountIndexFieldOfStudy: unmountIndexFieldOfStudy,
  actionAddEducation: addEducation,
  actionUpdateEducation: updateEducation,
  actionDeleteEducation: deleteEducation
}

export default connect(mapStateToProps, mapDispatchToProps)(Education)