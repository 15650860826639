import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Layout, Row, Col, Space, Typography, Button, Grid, message } from 'antd';
import { setJobPreferences } from '../../../redux/actions/jobPreferences/jobPreferencesAction';
import '../JobPreferences.css';

const { useBreakpoint } = Grid;
const { Footer } = Layout;
const { Text } = Typography;

function GetHistory() {
  const history = useHistory()
  return history;
}

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const FooterPreference = (props) => {
  const { industriesSelected, positionSelected, destinationSelected } = props
  const history = GetHistory()
  const { sm } = GetBreakPoint()
  const { pathname } = window.location
  const pathIndustries = '/job-preferences/industries'
  const pathPosition = '/job-preferences/position'
  const pathDestination = '/job-preferences/destination'
  const noStep = pathname === pathIndustries ? '1' : pathname === pathPosition ? '2' : '3'

  const onNextStep = () => {
    if(pathname === pathIndustries){
      if(industriesSelected.length > 0){
        localStorage.setItem("industriesSelected", industriesSelected);
        history.push(pathPosition)
      }else{
        message.error('Pick at least 1 job industry')
      }
    }
    
    if(pathname === pathPosition){
      if(positionSelected.length > 0){
        localStorage.setItem("positionSelected", positionSelected);
        history.push(pathDestination)
      }else{
        message.error('Pick at least 1 job position')
      }
    }

    if(pathname === pathDestination){
      const { actionSetJobPreferences } = props

      if(destinationSelected.length > 0){
        const payload = {
          listJobIndustry: localStorage.getItem("industriesSelected").split(',').map(x=>+x),
          listJobPosition: localStorage.getItem("positionSelected").split(',').map(x=>+x),
          listJobDestination: destinationSelected
        }
        localStorage.removeItem('industriesSelected')
        localStorage.removeItem('positionSelected')
        localStorage.removeItem('destinationSelected')
        localStorage.removeItem('email')
        localStorage.removeItem('password')
        
        return actionSetJobPreferences(payload, response => {
          window.location.href = '/candidate/job'
          return () => message.success(response.message)
        }, (err) => {
          return () => message.error(err.message)
        })
        
      }else{
        message.error('Pick at least 1 job destination')
      }
    }
  }

  const onBack = () => {
    if(pathname === pathDestination){
      localStorage.removeItem('destinationSelected')
      history.push(pathPosition)
    }else if(pathname === pathPosition){
      localStorage.removeItem('positionSelected')
      history.push(pathIndustries)
    }
  }

  const onSkip = () => {
    localStorage.removeItem('industriesSelected')
    localStorage.removeItem('positionSelected')
    localStorage.removeItem('destinationSelected')
    localStorage.removeItem('email')
    localStorage.removeItem('password')
    
    window.location.href = '/candidate/job'
  }

  const buttonFooter = (
    sm ?
      <>
        <Space className="footer-button">
          <Button className="txt-color btn-skip" onClick={onSkip}>SKIP</Button>
          {
            pathname !== pathIndustries ?
              <Button className="txt-color btn-skip" onClick={onBack}>BACK</Button>
            :
              null
          }
          <Button className="btn-next" type="primary" onClick={onNextStep}>{pathname === pathDestination ? 'SUBMIT' : 'NEXT'}</Button>
        </Space>
      </>
    :
      <>
        <Row gutter={8}>
          <Col span={pathname === pathIndustries? 12 : 8}>
            <Button className="txt-color btn-skip" onClick={onSkip}>SKIP</Button>
          </Col>
          <Col span={pathname === pathIndustries? 0 : 8}>
            {
              pathname !== pathIndustries ?
                <Button className="btn-back txt-color" onClick={onBack}>BACK</Button>
              :
                null
            }
          </Col>
          <Col span={pathname === pathIndustries? 12 : 8}>
            <Button className="btn-next" type="primary" onClick={onNextStep}>{pathname === pathDestination ? 'SUBMIT' : 'NEXT'}</Button>
          </Col>
        </Row>
      </>
  )

  return (
    <React.Fragment>
      <Footer className="footer-job">
        <Row className="footer-style full-height" justify="space-around" align="middle">
          <Col xs={24} sm={24} md={12}>
            <Text className="txt-color">Step { noStep } of 3</Text>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {buttonFooter}
          </Col>
        </Row>
      </Footer>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionSetJobPreferences: setJobPreferences
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterPreference)