import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountSubDistrictByCity = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_SUB_DISTRICT'})
}

export const subDistrictByCity = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_SUB_DISTRICT' })
  const params = { 
    id: value 
  }
  API.GET('/sub-district/getByCityId', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_SUB_DISTRICT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_SUB_DISTRICT_FAILED' }), 
    ))
  })
}