import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Loading } from '../../../components'
import { indexJobDestination } from '../../../redux/actions/master/jobDestination/jobDestinationAction';
import { Row, Col, Card, Image, Typography, Space, Layout, message } from 'antd';
import Header from '../Header';
import Footer from '../Footer';
import '../JobPreferences.css';
import { checkToken } from '../../../redux/actions/auth/authAction'

const { Title, Text } = Typography;

export class Destination extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      destinationSelected: localStorage.getItem("destinationSelected") ? localStorage.getItem("destinationSelected").split(',').map(x=>+x) : []
    }
  }
  
  componentDidMount() {
    const { actionCheckToken, actionGetJobDestination } = this.props

    actionCheckToken((response) => {
      if(response.code === "1000"){
        actionGetJobDestination(localStorage.getItem("industriesSelected"))
      }
    })
  }

  onSelectDestination = (id) => {
    const { destinationSelected } = this.state
    
    const filterSelected = destinationSelected.filter(item => item === id)
    const indexOfSelected = destinationSelected.indexOf(id)
    if(filterSelected.length === 0){
      if(destinationSelected.length < 5){
        destinationSelected.push(id)
      }else{
        message.error('You can only pick 5 job destination!')
      }
    }else{
      destinationSelected.splice(indexOfSelected,1)
    }

    this.setState({ destinationSelected })
  }

  render() {
    const { getJobDestination: { loading, data } } = this.props
    const { destinationSelected } = this.state
    
    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Job Preferences - Job Destination</title>
        </Helmet>
        <Layout>
          <Header />
          <Row className="dis-inline m-16">
            <Col className="pb-100 mt-64" xs={24} sm={24} md={{ span: 22, offset: 1 }}>
              <Space className="full-width mb-32 txt-algn-center" direction="vertical" size="small">
                <Title className="txt-color fw-normal" level={3}>Find your desired destinations</Title>
                <Text className="txt-color fw-normal">Maximum 5 options.</Text>
              </Space>
                {
                  data?.map((item, i) =>
                    <React.Fragment key={i}>
                      <Row className="mb-16" justify="space-around" align="middle">
                        <Col span={24}>
                          <Text className="fs-13">{item.name}</Text>
                        </Col>
                      </Row>
                      <Row className="mb-32" gutter={[16, 16]}>
                        {
                          item.listDestination.map((res, i) => 
                            <Col key={i} xs={24} sm={24} md={8}>
                              <Card
                                className={ 
                                  destinationSelected.length === 0 ? 
                                    "full-width br-6"
                                  :
                                  destinationSelected.map((value) => 
                                      res.id === value ? "full-width br-6 card-selected" : "full-width br-6"
                                    )}
                                hoverable
                                bodyStyle={{ padding: 12 }}
                                onClick={() => this.onSelectDestination(res.id)}
                              >
                                <Row align="middle">
                                  <Col className="txt-algn-center" span={24}>
                                    <Space direction="vertical" size={0}>
                                      <Image
                                        className={!res.isDomestic ? "box-shadow" : null}
                                        width={80}
                                        height={40}
                                        src={res.flag}
                                        preview={false}
                                        style={res.isDomestic ? { objectFit: 'contain' } : null}
                                      />
                                      <Text className="fs-11">{res.name}</Text>
                                    </Space>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          )
                        }
                      </Row>
                    </React.Fragment>
                  )
                }
            </Col>
          </Row>
          <Footer destinationSelected={destinationSelected} />
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getJobDestination: state.jobDestinationReducer
})

const mapDispatchToProps = {
  actionCheckToken: checkToken,
  actionGetJobDestination: indexJobDestination
}

export default connect(mapStateToProps, mapDispatchToProps)(Destination)
