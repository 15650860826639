import React from 'react';
import Cookie from 'js-cookie';
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import Loading from '../../components/Loading';
import EmptyCompany from '../../assets/img/EmptyCompany.png';
import EmptyLogo from '../../assets/img/EmptyLogo.png';
import { Image, Avatar, Card, Typography, Row, Col, Space, Carousel, Input, Skeleton, List, Tooltip, Button, Breadcrumb } from 'antd'
import { SafetyOutlined, EnvironmentOutlined, CheckOutlined, StarOutlined, StarFilled } from '@ant-design/icons';

const { Title, Text } = Typography
const { Search } = Input;

export default function JobList(props) {
  const { getCompany, getJob, history, onSearch, onChangePagination, handleFavorite } = props

  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    history.push('/login')
  }

  if(getCompany.loading){
    return <Loading />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - {getCompany?.data?.name}</title>
      </Helmet>
      <Row gutter={32} className="body-row">
        <Col sm={{ span: 24 }} md={{ span: 20, offset: 2 }} >
          <Breadcrumb className="mb-16">
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? '/candidate/job' : '/'}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Companies</Breadcrumb.Item>
            <Breadcrumb.Item>{getCompany?.data?.name}</Breadcrumb.Item>
          </Breadcrumb>
          {
            getCompany?.data?.listCompanyImage?.length > 0 ?
              <Carousel autoplay>
                {
                  getCompany.data.listCompanyImage.map(item => 
                    <Image
                      key={item.id}
                      src={item.fileUrl}
                      preview={false}
                      height={240}
                      width={'100%'}
                    />
                  )
                }
              </Carousel>
            :
              <Image
                src={EmptyCompany}
                preview={false}
                height={240}
                width={'100%'}
              />
          }

          <Card className="mb-16 mt-16" bordered={false}>
            <Row>
              <Col className="full-width" xs={10} sm={6} style={{ textAlign: '-webkit-center' }}>
                <Card style={{ width: 140, height: 140, marginTop: -110, backgroundColor: '#fff', boxShadow: '1px 1px 8px #cccccc' }} bodyStyle={{ height: '100%', display: 'grid' }}>
                  <Image
                    src={getCompany.data.logoUrl ? getCompany.data.logoUrl : EmptyLogo}
                    preview={false}
                    width={'100%'}
                    height={90}
                    style={{ objectFit: 'contain' }}
                  />
                </Card>
                <Title className="drk-color" style={{ marginTop: 10 }} level={5}>{getCompany.data.name} {getCompany.data.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Title>
              </Col>
              <Col span={2}></Col>
              <Col xs={12} sm={16}>
                <Space direction="vertical">
                  <Text className="drk-color">Address</Text>
                  <Space direction="vertical" size={0}>
                    <Text className="drk-color">{getCompany.data.address ? getCompany.data.address : '-'}, {getCompany.data.cityName ? getCompany.data.cityName : '-'}</Text>
                    <Text className="drk-color">{getCompany.data.provinceName ? getCompany.data.provinceName : '-'}, {getCompany.data.countryName ? getCompany.data.countryName : '-'}</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Card>

            <Card className="mb-16" bordered={false}>
              <Space className="full-width" direction="vertical">
                <Text className="drk-color" strong>Overview</Text>
                <Text className="drk-color">{getCompany.data.about ? getCompany.data.about : '-'}</Text>
                <Row>
                  <Col xs={8} sm={6} xl={4}>
                    <Text className="txt-color">Website</Text>
                  </Col>
                  <Col xs={16} sm={18} xl={20}>
                    {
                      getCompany.data.website ?
                        <a className="web-company drk-color" href={getCompany.data.website.slice(0,8) === 'https://' || getCompany.data.website.slice(0,7) === 'http://' ? getCompany.data.website : `http://${getCompany.data.website}`} target="_blank" rel="noreferrer">{getCompany.data.website}</a>
                      :
                        <Text className="drk-color">-</Text>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={6} xl={4}>
                    <Text className="txt-color">Industry</Text>
                  </Col>
                  <Col xs={16} sm={18} xl={20}>
                    <Text className="drk-color">{getCompany.data.jobIndustryTitle ? getCompany.data.jobIndustryTitle : '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={6} xl={4}>
                    <Text className="txt-color">Specialties</Text>
                  </Col>
                  <Col xs={16} sm={18} xl={20}>
                    <Text className="drk-color">{getCompany.data.specialties ? getCompany.data.specialties : '-'}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={6} xl={4}>
                    <Text className="txt-color">Company Size</Text>
                  </Col>
                  <Col xs={16} sm={18} xl={20}>
                    <Text className="drk-color">{getCompany.data.totalEmployee ? getCompany.data.totalEmployee : '-'}</Text>
                  </Col>
                </Row>
              </Space>
            </Card>

            <Card className="mb-16" bordered={false}>
              <Space className="full-width" direction="vertical">
                <Row>
                  <Col className="full-width txt-algn-center" span={24}>
                    <Text className="primary-color" strong>Job Openings at {getCompany.data.name}</Text>
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col span={20} offset={2}>
                    <Search
                      placeholder="Search for a job title, job position"
                      allowClear
                      enterButton="Search"
                      onSearch={onSearch}
                    />
                  </Col>
                </Row>
              </Space>
            </Card>

            {
              getJob?.loading ?
                <Skeleton.Input style={{ width: '100%' }} active />
              :
                <Text className="txt-color fs-13 mb-16">{`${getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : (getJob?.data?.meta?.page*getJob?.data?.meta?.perpage)-(getJob?.data?.meta?.perpage-1)}-${getJob?.data?.meta?.page === getJob?.data?.meta?.pages || getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : getJob?.data?.meta?.page*getJob?.data?.meta?.perpage} of ${getJob?.data?.meta?.total} job openings`}</Text>
            }
            {
              getJob?.loading ?
                <Card className="mb-8" style={{ width: '100%' }}>
                  <Skeleton active avatar />
                </Card>
              :
                <List
                  className="mt-8"
                  size="large"
                  pagination={{
                    style: { textAlign: 'center', paddingBottom: 24 },
                    pageSize: getJob?.data?.meta?.perpage,
                    total: getJob?.data?.meta?.total,
                    current: getJob?.data?.meta?.page,
                    onChange: (pageNumber) => onChangePagination(pageNumber)
                  }}
                  dataSource={getJob?.data?.data}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <Row className="full-width" gutter={16}>
                        <Col className="col-img-job" xxl={3} xl={4} lg={4}>
                          <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                            <Avatar src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo} shape="square" size={{ sm: 80, md: 80, lg: 56, xl: 80, xxl: 90 }} style={{ height: 'auto', display: "block", margin: 0 }} />
                          </Link>
                        </Col>
                        <Col xxl={14} xl={13} lg={12}>
                          <Space direction="vertical" size={0}>
                            <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.id}` : `/job/detail/${item.id}`}>
                              <Text className="title-job drk-color fs-17">{item.title}</Text>
                            </Link>
                            <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                              <Text className="company-job fs-13 txt-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                            </Link>
                            <Text className="fs-13 txt-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                            <Text className="fs-13 primary-color">
                              {
                                item.isDisplaySalary ?
                                  item.maxSalary ? 
                                    `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                  : 
                                    `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                                :
                                  'Salary not disclosed'
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col xxl={7} xl={7} lg={8}>
                          <Space className="full-width f-right" direction="vertical" size={16}>
                            <Text className="f-right fs-11 txt-color">{`Posted on ${item.postDate}`}</Text>
                            {
                              Cookie.get('user') ?
                                <Space className="f-right" size={8}>
                                  <Tooltip title={item.isFavorite ? 'Already added to favorites' : 'Add to favorites' }>
                                    <Button type="primary" className="br-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                  </Tooltip>
                                  {
                                    item.isApply ?
                                      <Text className="f-right fs-14 primary-color"><CheckOutlined /> Applied</Text>
                                    :
                                      <Link to={`/candidate/job/detail/${item.id}`}>
                                        <Button type="primary" className="f-right br-6">Apply</Button>
                                      </Link>
                                  }         
                                </Space>
                              :
                                <Button type="primary" className="f-right br-6" onClick={() => onClickApply(`/candidate/job/detail/${item.id}`)}>Apply</Button>
                            }
                          </Space>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
            }
        </Col>
      </Row>
    </React.Fragment>
  )
}