import React from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Helmet } from "react-helmet";
import {  Row, Col, Breadcrumb, Grid } from 'antd';
import './Detail.css';
import DesDetailJob from './Desktop';
import MobDetailJob from './Mobile';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function JobDetail(initialProps) {
  const { getJobDetail } = initialProps
  const { xs } = GetBreakPoint()
  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - {getJobDetail.data.title}</title>
      </Helmet>
      <Row gutter={32} className="body-row">
        <Col sm={{ span: 24 }} md={{ span: 20, offset: 2 }} >
          <Breadcrumb className="mb-16">
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? '/candidate/job' : '/'}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? '/candidate/job' : '/job'}>Job Opening</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{getJobDetail.data.title}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {
          xs ?
            <MobDetailJob {...initialProps} />
          :
            <Row gutter={32} className="body-row full-width">
              <Col span={20} offset={2}>
                <DesDetailJob {...initialProps} />
              </Col>
            </Row>
        }
      </Row>
    </React.Fragment>
  )
}