import { API } from '../../../config'
import Cookie from "js-cookie"
import { CANDIDATE } from '../../../permissions'

export const setRegister = (value, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_REGISTER' })
  API.POST('/register', value).then((response) => {
    dispatch({ type: 'LOAD_REGISTER_SUCCESS', payload: { 
      userData: response
    }
  })
    return successCB && successCB(response)
  })
  .catch((err) => {
    dispatch({ type: 'LOAD_REGISTER_FAILED' })
    return failedCB && failedCB(err)
  })
}

export const setActivation = (value, successCB, failedCB) => async dispatch => {
  // await dispatch({ type: 'LOAD_AUTH' })
  API.POST('/activation', value).then((response) => {
    if(response.code === '1000'){
      const { token, refreshToken, firstName, lastName, role } = response.data
      const permission = getPermission(role)
      Cookie.set('user', token)
      Cookie.set('userRefreshTkn', refreshToken)
      Cookie.set('firstName', firstName)
      Cookie.set('lastName', lastName)
      dispatch({ type: 'LOAD_AUTH_SUCCESS', payload: { 
          userData: response.data,
          role: role,
          permission: permission,
          isRegister: true
        } 
      })
      return successCB && successCB(response)
    }else{
      dispatch({ type: 'LOAD_AUTH_FAILED' })
      return failedCB && failedCB(response.message)
    }
  })
  .catch((err) => {
    dispatch({ type: 'LOAD_AUTH_FAILED' })
    return failedCB && failedCB(err.error || err.message)
  })
}

export const getPermission = (type) => {
  switch (type) {
    case 'ROLE_CANDIDATE':
      return CANDIDATE
    default:
      return null
  }
}

export const removedata = (cb) => async (dispatch) => {
  await Cookie.remove('user')
  await Cookie.remove('userRefreshTkn')
  return cb()
}