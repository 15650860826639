import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountMenuApplications = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_MENU_APPLICATIONS'})
}

export const unmountListApplications = () => (dispatch) => {
	return dispatch({type: 'UNMOUNT_LIST_APPLICATIONS'})
}

export const unmountInterview = () => (dispatch) => {
	return dispatch({type: 'UNMOUNT_INTERVIEW'})
}

export const menuApplications = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_LIST_APPLICATIONS' })
  return API.GET('/application/getListApplication').then((response) => {
    dispatch({
      type: 'LOAD_MENU_APPLICATIONS_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_MENU_APPLICATIONS_FAILED' }), 
    ))
  })
}

export const listApplications = (params) => async (dispatch) => {
	await dispatch({ type: 'LOAD_LIST_APPLICATIONS' })
	return API.GET('/application/list', params).then((response) => {
		dispatch({
			type: 'LOAD_LIST_APPLICATIONS_SUCCESS', 
			payload: {
				data: response.data,
				meta: response.meta
		}})
	}).catch((err) => {
		return dispatch(errorHandler(
			err.error || err.message, 
			dispatch({ type: 'LOAD_LIST_APPLICATIONS_FAILED' }), 
		))
	})
}

export const detailInterview = (params) => async (dispatch) => {
	await dispatch({ type: 'LOAD_INTERVIEW' })
	return API.GET('/application/interview/getById', params).then((response) => {
		dispatch({
			type: 'LOAD_INTERVIEW_SUCCESS', 
			payload: {
				data: response.data
		}})
	}).catch((err) => {
		return dispatch(errorHandler(
			err.error || err.message, 
			dispatch({ type: 'LOAD_INTERVIEW_FAILED' }), 
		))
	})
}

export const confirmInterview = (value, successCB, failedCB) => async dispatch => {
  API.POST_WITH_PARAMS('/application/confirmInterview', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const cancelInterview = (value, successCB, failedCB) => async dispatch => {
  API.POST('/application/cancelInterview', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const rescheduleInterview = (value, successCB, failedCB) => async dispatch => {
  API.POST('/application/rescheduleInterview', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}