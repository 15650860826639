import React, { Component } from 'react'
import { Row, Col, Typography, Space, Button, Form, Input, message } from 'antd'

const { Text } = Typography
export default class FormChangePassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  onFinish = (values) => {
    const { actionUpdatePassword } = this.props;
    this.setState({ submitLoading: true })
    return actionUpdatePassword(values, (response) => {
      this.setState({ submitLoading: false }, () => {
        if(response.code === '1000'){
          message.success("Password updated")
          this.formRef.current.setFieldsValue({
            currentPassword: null,
            newPassword: null,
            retypeNewPassword: null,
          });
        }else{
          message.error(response.message)
        }
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        message.error(err)
      })
    })
  }

  render() {
    const { submitLoading } = this.state

    return (
      <React.Fragment>
        <Form 
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onFinish}
        >
          <Row className="mb-min-8">
            <Col span={24}>
              <Space size={4}>
                <Text className="fs-12" type="secondary">Old Password</Text>
                <Text className="fs-12" type="danger">*</Text>
              </Space>
              <Form.Item 
                name="currentPassword"
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Input.Password size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-min-8">
            <Col span={24}>
              <Space size={4}>
                <Text className="fs-12" type="secondary">New Password</Text>
                <Text className="fs-12" type="danger">*</Text>
              </Space>
              <Form.Item
                name="newPassword"
                validateFirst
                rules={[
                  { required: true, message: 'This is a required field' },
                  { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Your password isn\'t strong enough' },
                  { min: 8, message: 'Password must be at least 8 characters long.' },
                ]}
              >
                <Input.Password size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-min-8" gutter={8}>
            <Col span={24}>
              <Space size={4}>
                <Text className="fs-12" type="secondary">Retype New Password</Text>
                <Text className="fs-12" type="danger">*</Text>
              </Space>
              <Form.Item
                name="retypeNewPassword"
                validateFirst
                dependencies={['newPassword']}
                rules={[
                  { required: true, message: 'This is a required field'},
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Passwords do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="txt-algn-center mt-8">
            <Button className="br-3" htmlType="submit" type="primary" loading={submitLoading} style={{ width: 160 }}>Save</Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    )
  }
}
