const LOAD_DATA_MASTER_TIMEZONE         = 'LOAD_DATA_MASTER_TIMEZONE'
const LOAD_DATA_MASTER_TIMEZONE_SUCCESS = 'LOAD_DATA_MASTER_TIMEZONE_SUCCESS'
const LOAD_DATA_MASTER_TIMEZONE_FAILED  = 'LOAD_DATA_MASTER_TIMEZONE_FAILED'
const UNMOUNT_DATA_MASTER_TIMEZONE      = 'UNMOUNT_DATA_MASTER_TIMEZONE'

const initialState = {
  loading: true,
  data: null,
  message: null,
  pagination: {
    current: 1,
    total: 0,
    pageSize: 0,
  }
}

const timezoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA_MASTER_TIMEZONE:
      return {
        ...state,
        loading: true,
        data: null,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 0,
        }
      }
    case LOAD_DATA_MASTER_TIMEZONE_SUCCESS:
      return {
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
        pagination: {
          current: action.payload.pagination.current,
          total: action.payload.pagination.total,
          pageSize: action.payload.pagination.pageSize
        }
      }
    case LOAD_DATA_MASTER_TIMEZONE_FAILED:
      return {
        ...state, 
        loading: false,
        data: null,
        message: 'FAILED',
        pagination: {
          current: 1,
          total: 0,
          pageSize: 0,
        }
      }
    case UNMOUNT_DATA_MASTER_TIMEZONE:
      return { 
        ...state, 
        loading: true,
        data: null,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 0,
        }
      }
    default:
      return state
  }
}
export default timezoneReducer