import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import ReactGA from "react-ga";
import { Layout } from 'antd';
import Header from './Header';
import Footer from './Footer';

const { Content } = Layout;

if(process.env.REACT_APP_ENV === "production"){
  ReactGA.initialize("G-H4HEF8W92E");
}

export class MainAuth extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      pathname: null,
      visible: false
    }
  }

  componentDidUpdate(prevProps) {
    const getToken = Cookie.get('user')
    
    if(getToken){
      window.location.reload()
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ pathname: this.props.location.pathname })
    }
  }
  
  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }

  render() {
    const { children, path } = this.props
    const { pathname, visible } = this.state
    
    return (
      <React.Fragment>
        <Layout style={ path === '/login-or-register' ? { minHeight: '100vh', overflowX: 'hidden', background: '#fff' } : { minHeight: '100vh', overflowX: 'hidden' } }>
          <Header 
            pathname={pathname}
            visible={visible}
            showDrawer={this.showDrawer}
            onClose={this.onClose}
          />
          <Content>
            { children }
          </Content>
          <Footer />
        </Layout>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(MainAuth)
