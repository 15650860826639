import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../../components';
import JobDetail from '../../../components/Job/Detail';
import { detailJob, unmountDetailJob } from '../../../redux/actions/job/jobAction';

export class JobDetail_ extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false
    }
  }
  
  componentDidMount() {
    const { actionGetJobDetail, match: { params } } = this.props
    
    window.scrollTo(0, 0)

    return actionGetJobDetail(params.id)
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  }
   
  handleCancel = () => {
    this.setState({
      visible: false
    })
  }
  
  render() {
    const { visible } = this.state
    const { getJobDetail } = this.props
    if(getJobDetail.loading){
      return <Loading />
    }
    
    const initialProps = {
      showModal: this.showModal,
      handleCancel: this.handleCancel,
      visible: visible,
      id: this.props.match.params.id
    }

    return (
      <JobDetail {...initialProps} {...this.props} />
    )
  }
  componentWillUnmount(){
    const { unmountDetailJob } = this.props
    return unmountDetailJob()
  }
}

const mapStateToProps = (state) => ({
  getJobDetail: state.jobDetailReducer
})

const mapDispatchToProps = {
  actionGetJobDetail: detailJob,
  unmountDetailJob: unmountDetailJob,
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetail_)
