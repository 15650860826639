import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Menu, Card, Skeleton, Avatar, Space, Tag, Pagination, Empty } from 'antd'
import { menuApplications, unmountMenuApplications, listApplications, unmountListApplications, detailInterview, unmountInterview, confirmInterview, cancelInterview, rescheduleInterview } from '../../../../redux/actions/applications/applicationsAction'
import { SafetyOutlined, EnvironmentOutlined } from '@ant-design/icons'
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';

const { Text } = Typography;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
        page: 1,
        perpage: 10,
        phaseId: '',
        jobStatus: 'ACTIVE'
      },
      activeKey: "0",
      visible: false,
      isReschedule: false,
      submitLoading: false,
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionMenuApplications, actionListApplications } = this.props
    
    return (actionMenuApplications(), actionListApplications(params))
  }

  handleMenu = e => {
    const { history } = this.props

    if(e.key === "0"){
      history.push('applied')
    }
    if(e.key === "1"){
      history.push('qualified')
    }
    if(e.key === "2"){
      history.push('interview')
    }
    if(e.key === "3"){
      history.push('offered')
    }
    if(e.key === "4"){
      history.push('hired')
    }
    if(e.key === "5"){
      history.push('rejected')
    }
  }

  handlePagination = (pageNumber) => {
    const { params: { perpage, phaseId, jobStatus } } = this.state
    const params = {
      page: pageNumber,
      perpage: perpage,
      phaseId: phaseId,
      jobStatus: jobStatus
    }
    this.setState({ params })
    window.scrollTo(0, 0);
    const { actionListApplications } = this.props
    return actionListApplications(params)
  }

  render() {
    const { activeKey } = this.state
    const { getMenuApplications, getListApplications } = this.props
    
    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Applications - Applied</title>
        </Helmet>
        <Row className="pad-16">
          <Col span={20} offset={2}>
            <Row className="mb-32">
              <Col>
                <Text className="drk-color fs-20">Applications</Text>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col md={24} lg={4}>
                {
                  getMenuApplications.loading ?
                    <Skeleton active />
                  :
                    <Menu onClick={this.handleMenu} selectedKeys={activeKey} className="drk-color" mode="vertical" style={{ backgroundColor: 'transparent' }}>
                      <Menu.Item className="m-item" key="0">Applied</Menu.Item>
                      {
                        getMenuApplications?.data?.map((item, i) => 
                          <Menu.Item className="m-item" key={i+1}>{item.name}</Menu.Item>
                        )
                      }
                      {/* <Divider style={{ margin: 0 }} /> */}
                      <Menu.Item className="m-item" key="5">Rejected</Menu.Item>
                    </Menu>
                }
              </Col>
              <Col md={24} lg={20}>
                <Row className="mb-16">
                  <Col span={24}>
                    {
                      getListApplications.loading ?
                        <Card className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                          <Skeleton active avatar />
                        </Card>
                      :
                        getListApplications?.data?.length > 0 ?
                          getListApplications?.data?.map(item => 
                            <Card className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                              <Row className="full-width" gutter={16}>
                                <Col className="col-img-job" xxl={3} xl={4} lg={4}>
                                  <Avatar src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo} shape="square" size={{ sm: 80, md: 80, lg: 56, xl: 80, xxl: 90 }} style={{ height: 'auto', display: "block", margin: 0 }} />
                                </Col>
                                <Col xxl={14} xl={13} lg={12}>
                                  <Space direction="vertical" size={0}>
                                    <Link to={`/candidate/job/detail/${item.jobId}`}>
                                      <Text className="a-color fs-17">{item.title}</Text>
                                    </Link>
                                    <Text className="fs-13 txt-color">{item.companyName} <SafetyOutlined className="primary-color" /></Text>
                                    <Text className="fs-13 txt-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                                    <Tag className="br-3 fs-12" color={item.isOffered === 'Hiring' ? 'success' : 'default'}>{item.isOffered}</Tag>
                                  </Space>
                                </Col>
                                <Col xxl={7} xl={7} lg={8}>
                                  <Space direction="vertical" size={16} className="f-right">
                                    <Text className="f-right fs-11 txt-color">{`Applied ${item.applyDate}`}</Text>
                                  </Space>
                                </Col>
                              </Row>
                          </Card>
                      )
                        :
                          <Card className="br-3 box-shadow mb-6" style={{ width: '100%' }}>
                            <Empty />
                          </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="txt-algn-center" span={24}>
                    <Pagination onChange={this.handlePagination} current={getListApplications?.meta?.page} pageSize={getListApplications?.meta?.perpage || 10} total={getListApplications?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountMenuApplications, unmountListApplications, unmountInterview } = this.props
    return (unmountMenuApplications(), unmountListApplications(), unmountInterview())
  }
}

const mapStateToProps = (state) => ({
  getMenuApplications: state.menuApplicationsReducer,
  getListApplications: state.listApplicationsReducer,
  getDetailInterview: state.interviewReducer
})

const mapDispatchToProps = {
  actionMenuApplications: menuApplications,
  unmountMenuApplications: unmountMenuApplications,
  actionListApplications: listApplications,
  unmountListApplications: unmountListApplications,
  actionDetailInterview: detailInterview,
  unmountInterview: unmountInterview,
  actionConfirmInterview: confirmInterview,
  actionCancelInterview: cancelInterview,
  actionRescheduleInterview: rescheduleInterview,
}

export default connect(mapStateToProps, mapDispatchToProps)(index)